import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';

import { Api } from './models';
import { api } from './values';

export class Config {
  readonly api: Api = api;
}

export const CONFIG = new InjectionToken<Config>('config');

@NgModule({})
export class ConfigModule {
  static forRoot(): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        {
          provide: CONFIG,
          useClass: Config
        }
      ]
    };
  }
}
