import { Component, ViewChild, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentsFacade } from '@app/domains/agents/store/facade/agents.facade';
import { EditAdminFormComponent } from '../edit-admin-form/edit-admin-form.component';

@Component({
  selector: 'vov-add-admin-popup',
  templateUrl: './add-admin-popup.component.html',
  styleUrls: ['./add-admin-popup.component.scss']
})
export class AddAdminPopupComponent implements OnInit {
  @ViewChild(EditAdminFormComponent) public form: EditAdminFormComponent;
  public loading$: Observable<boolean>;
  public success$: Observable<boolean>;
  public error$: Observable<any>;
  public error: string;
  public partnerId: string;

  constructor(
    private activeModal: NgbActiveModal,
    private agentsFacade: AgentsFacade) { }

  ngOnInit() {
    this.loading$ = this.agentsFacade.loadingAgent$;
    this.success$ = this.agentsFacade.successAgent$;
    this.error$ = this.agentsFacade.errorAgent$;
  }

  addAdmin() {
    this.form.saveAdmin(this.partnerId);

    this.success$.pipe(
      filter(s => s === true),
      take(1)
    ).subscribe(
      () => { this.close('added'); }
    );
    this.error$.pipe(
      filter(s => s !== null),
      take(1)
    ).subscribe(
      () => { this.error = 'agents.add-admin-popup.error'; }
    );
  }

  close(result?: any) {
    this.activeModal.close(result);
  }
  dismiss() {
    this.activeModal.dismiss();
  }

}
