<div class="modal-content add-edit-link-form">
  <div class="modal-header">
    <h5 class="modal-title" translate>partners.drive-link-popup.title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
      <i class="icon-small-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="content">
      <vov-add-edit-link-form (onClose)="close()" linkType="private" [data]="partner" #form></vov-add-edit-link-form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="vox-btn vox-btn-secondary-grey" (click)="close()" translate>
      partners.drive-link-popup.cancel
    </button>
    <button type="button" class="vox-btn vox-btn-secondary" (click)="saveLink()">
      <span [ladda]="loading$ | async" data-spinner-color="#fff">{{'partners.drive-link-popup.save' | translate}}</span>
    </button>
  </div>
</div>
