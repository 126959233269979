// Core
import { NgModule, Optional, SkipSelf } from "@angular/core";

// Libs
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from '@ngrx/effects';

// Store
import { userReducer } from "./store";
import { UserEffects } from './store/effects/user.effects';
// Services
import { UserService } from "./services/user.service";

@NgModule({
  imports: [
    StoreModule.forFeature("user", userReducer),
    EffectsModule.forFeature([UserEffects])
  ],
  providers: [UserService],

})
export class DomainUserModule {
  constructor(@Optional() @SkipSelf() parentModule: DomainUserModule) {
    if (parentModule) {
      throw new Error(
        "DomainUserModule is already loaded. Import only in AppModule"
      );
    }
  }
}
