// Core
import { Injectable } from "@angular/core";

// Store
import { selectLoading, selectError } from "src/app/domains/auth/store";
import {
  selectPartnerData,
  selectCompanyData,
  selectCompanyOtherData,
  buildFullObject
} from "../selectors/onboarding.selector";
import { Store, select } from "@ngrx/store";
import { OnboardingState } from "../reducers";
import { SavePartnerInformations } from "../actions/onboarding.action";

// Models
import { Partner } from "../../models/onboarding.model";

@Injectable()
export class OnboardingFacade {
  partner_informations$ = this.store.pipe(select(buildFullObject));
  partner_data$ = this.store.pipe(select(selectPartnerData));
  company_data$ = this.store.pipe(select(selectCompanyData));
  company_other_data$ = this.store.pipe(select(selectCompanyOtherData));
  loading$ = this.store.pipe(select(selectLoading));
  error$ = this.store.pipe(select(selectError));

  constructor(private store: Store<OnboardingState>) {}

  savePartnerInformations(partner: Partner) {
    this.store.dispatch(new SavePartnerInformations(partner));
  }
}
