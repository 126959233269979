import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";

import { UserFacade } from "@app/domains/user/store/facade";
import { I18nService } from "@app/core/i18n";
import { environment } from "@src/environments/environment";
import { ContactPhone, CONTACT_PHONES } from '@app/shared/contact-phones.constant';

@Component({
  selector: "vov-activate-user",
  templateUrl: "./activate-user.component.html",
  styleUrls: ["./activate-user.component.scss"],
})
export class AuthActivateUserComponent implements OnInit {
  public langs: any[];
  public currentLang: string;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public errorMessage: string;
  public successMessage: string;
  public token: string;
  currentWidth: string;

  public phoneNumbers: ContactPhone[] = CONTACT_PHONES;
  public selectedPhoneNumber: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private userFacade: UserFacade,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loading$ = this.userFacade.loadingUser$;
    this.error$ = this.userFacade.errorUser$;

    this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    window.onresize = () => {
      this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    };

    this.currentLang = this.i18nService.language;
    this.langs = environment.supportedLanguages;
    this.route.queryParams.subscribe((params) => {
      this.token = params.token ? params.token : null;
    });

    this.selectedPhoneNumber = this.phoneNumbers[0];
  }

  goToWebsite() {
    let url = this.translate.instant(`auth.right.url`);
    window.open(url, "_blank");
  }

  onChangeLanguage(event: string) {
    this.i18nService.language = event;
  }

  activateUser(e: any) {
    this.userFacade.activateUser({
      ...e,
      activation_token: this.token,
    });

    this.userFacade.successUser$
      .pipe(
        filter((v) => v === true),
        take(1)
      )
      .subscribe(() => {
        this.router.navigate(["auth/login"], {
          state: { message: "activate-user.success" },
        });
      });

    this.userFacade.errorUser$
      .pipe(
        filter((v) => v !== null),
        take(1)
      )
      .subscribe(() => {
        this.errorMessage = "activate-user.error";
      });
  }

  onSelectPhoneNumber(phoneNumber: string) {
    this.selectedPhoneNumber = phoneNumber;
  }
}
