import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { onboardingReducer } from "./store/reducers";
import { OnboardingFacade } from "./store/facade/onboarding.facade";

@NgModule({
  imports: [StoreModule.forFeature("onboarding-data", onboardingReducer)],
  providers: [OnboardingFacade]
})
export class OnboardingDomainModule {}
