import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { User } from '@app/domains/user/models/user.model';


@Component({
  selector: 'vov-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ContactListComponent),
    multi: true
  }]
})

export class ContactListComponent implements OnInit, ControlValueAccessor{
  @Input() value: User[];
  @Input() contacts: User[];
  @Input() maxElements: number;
  public filterContactInput: UntypedFormControl;
  public ELEMENT_HEIGHT = 35;
  public disabled: boolean;
  private DEFAULT_MAX_ELEMENTS = 10;

  constructor() { }

  ngOnInit() {
    this.filterContactInput = new UntypedFormControl('');
    if (!this.maxElements){
      this.maxElements = this.DEFAULT_MAX_ELEMENTS;
    }
  }

  onChange(_: any) {}
  onTouched(_:any) {}

  registerOnChange(fn) { this.onChange = fn; }
  registerOnTouched(fn) { this.onTouched = fn; }

  writeValue(value: any) {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  selectContact(user: User) {
    if (this.disabled) {
      return;
    }
    if (!this.isSelected(user)) {
      this.value.push(user);
    } else {
      const index = this.value.findIndex(c => c.id === user.id)
      this.value.splice(index,1);
    }
    this.onChange(this.value);
  }

  isSelected(user: User): boolean {
    return (this.value.find(c => c.id === user.id) !== undefined);
  }

  get maxHeight() {
    return Math.ceil(this.maxElements * this.ELEMENT_HEIGHT) + 'px';
  }

  get filteredContacts() {
    if (this.filterContactInput.value) {
      return this.contacts
        .filter(
          (c) => {
            const fullName = `${c.first_name} ${c.last_name}`.toLowerCase();
            const query = this.filterContactInput.value.toLowerCase();
            return fullName.includes(query);
          }
        );
    } else {
      return this.contacts;
    }
  }
}
