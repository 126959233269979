// Libs
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Models
import { Agent } from '../../models/agents.model';

// Store
import { AgentsState } from '../reducers';

export const selectAgentsState = createFeatureSelector<AgentsState>('agents');

export const selectAgentsStatus = createSelector(
  selectAgentsState,
  (state: AgentsState) => state.status
);
export const selectAgents = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    if (state.status && state.status.agents) {
      return state.status.agents;
    }
  }
);
export const selectLoading = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    return state.status.loading;
  }
);
export const selectLoadingAgents = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    return state.status.loading.agents;
  }
);
export const selectLoadingAgent = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    return state.status.loading.agent;
  }
);
export const selectError = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    return state.status.error;
  }
);
export const selectErrorAgents = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    return state.status.error.agents;
  }
);
export const selectErrorAgent = createSelector(
    selectAgentsState,
    (state: AgentsState) => {
      return state.status.error.agent;
  }
);
export const selectSuccessAgents = createSelector(
  selectAgentsState,
  (state: AgentsState) => {
    return state.status.success.agents;
  }
);
export const selectSuccessAgent = createSelector(
    selectAgentsState,
    (state: AgentsState) => {
      return state.status.success.agent;
  }
);
