<div ngbDropdown class="d-inline-block select-agent__dropdown">
  <button type="button" class="vox-btn vox-btn-secondary select-agent__dropdown_btn" id="dropdownBasic3"
    data-toggle="dropdown" ngbDropdownToggle>{{selectedAgent.first_name | translate}}
    {{selectedAgent.last_name}}</button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
    <button ngbDropdownItem class="select-agent__dropdown_item" *ngIf="agents.length === 0"
      disabled>{{'customers.filters.no-agents' | translate}}</button>
    <button type="button" ngbDropdownItem class="select-agent__dropdown_item" *ngFor="let agent of agents"
      (click)="selectReferenceAgent(agent)">{{agent.first_name}} {{agent.last_name}}</button>
  </div>
</div>