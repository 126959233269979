import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomerStatus } from '@app/domains/customers';

@Component({
  selector: 'vov-select-status',
  templateUrl: './select-status.component.html',
  styleUrls: ['./select-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectStatusComponent),
      multi: true
    }
  ]
})
export class SelectStatusComponent implements ControlValueAccessor {

  constructor() { }

  public selectedStatus: CustomerStatus;
  public statuses: CustomerStatus[];

  @Input() set statusesList(val: CustomerStatus[]) {
    this.statuses = val;
  }

  onChange: any = () => { };
  onTouch: any = () => { };


  writeValue(obj: CustomerStatus): void {
    this.selectedStatus = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public onSelectStatus(status) {
    this.selectedStatus = status;
    this.onChange(this.selectedStatus);
  }
}
