import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthErrorInterceptor } from './auth-error-interceptor';
import { AuthInterceptor } from './auth-interceptor';

export const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];
