<div class="customers-filter__container-outer">
  <form [formGroup]="form" class="customers-filter__container-inner" (ngSubmit)="emitFilters()">
    <div class="customers-filter__filter" *ngFor="let used of usedFilters; let uIdx = index">
      <button type="button" class="customers-filter__filter-remove  vox-btn vox-btn-secondary-grey"
        (click)="removeFilter(uIdx)" [ngClass]="{'hidden': usedFilters.length === 1}">
        <i class="customers-filter__filter-remove_symbol icon-small-remove">
        </i>
      </button>


      <div ngbDropdown class="d-inline-block customers-filter__filter-dropdown">
        <button type="button" class="vox-btn vox-btn-secondary customers-filter__filter-dropdown_btn"
          id="dropdownBasic1" ngbDropdownToggle
          [disabled]="availableFilters.length < 1">{{'customers.filters.' + used | translate}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button type="button" ngbDropdownItem class="customers-filter__filter-dropdown_item"
            *ngFor="let filter of availableFilters; let idx = index" (click)="updateFilters(idx, uIdx)">
            {{'customers.filters.' + filter | translate}}
          </button>
        </div>
        <div class="customers-filter__filter-input" [ngSwitch]="used">

          <div class="customers-filter__filter-input_name vox-input-group vox-input-group_dense">
            <input class="vox-text-field" type="text" formControlName="name" *ngSwitchCase="'name'"
              (keydown.enter)="$event.preventDefault();emitFilters();">
          </div>
          <vov-select-status *ngSwitchCase="'status'" formControlName="status" [statusesList]="statuses">
          </vov-select-status>
          <vov-select-dates formControlName="dates" *ngSwitchCase="'creation'"></vov-select-dates>
          <vov-select-agent formControlName="reference" [agentsList]="agents" *ngSwitchCase="'reference'">
          </vov-select-agent>
          <div class="customers-filter__filter-limit">
            <vov-select-partner formControlName="partner" *ngSwitchCase="'partner'">
            </vov-select-partner>
          </div>
        </div>
      </div>
    </div>
    <div class="customers-filter__filter-add_container">
      <button type="button" class="vox-btn vox-btn-secondary customers-filter__filter-add" (click)="addFilter(0)"
        [disabled]="availableFilters.length < 1">
        <i class="customers-filter__filter-add_symbol icon-small-add">
        </i>
      </button>
      <button type="submit" class="vox-btn vox-btn-secondary customers-filter__filter-apply">
        {{'customers.actions.apply-filters' | translate}}
      </button>
      <button type="button" class="ml-2 btn btn-link customers-filter__filter-clear" (click)="resetFilters()">
        {{'customers.actions.clear-filters' | translate}}
      </button>
    </div>
  </form>
</div>