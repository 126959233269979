// Core
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Guards
import { AuthLoginGuard } from "src/app/domains/auth";

// Components
import { AuthLayoutMainComponent } from "./layout/main/main.component";
import { AuthLoginPageComponent } from "./containers";
import { AuthForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { AuthActivateUserComponent } from './containers/activate-user/activate-user.component';

export const routes: Routes = [
  {
    path: "",
    canActivate: [AuthLoginGuard],
    component: AuthLayoutMainComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "login" },
      {
        path: "login",
        component: AuthLoginPageComponent
      },{
        path: "forgot-password",
        component: AuthForgotPasswordComponent
      },{
        path: "activate-user",
        component: AuthActivateUserComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
