<vov-toasts></vov-toasts>
<div class="main-container">
  <div class="first-container">
    <vov-layout-header (closingMenu)="onCloseMenu($event)"></vov-layout-header>
  </div>
  <div class="second-container">
    <div class="menu-bar" [ngClass]="{ 'bar-hidden': onClose }">
      <vov-layout-menu (closingMenu)="onCloseMenu($event)"></vov-layout-menu>
    </div>
    <div class="main-box">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
