import { Component, Input, OnInit } from '@angular/core';
import { IntercomChatService } from '@app/core/intercom/services/intercom-chat.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'vov-drive-folder',
  templateUrl: './drive-folder.component.html',
  styleUrls: ['./drive-folder.component.scss']
})
export class DriveFolderComponent implements OnInit {
  @Input() folderLink$: Observable<string>;
  public loading: boolean;
  public iframeError: boolean = false;

  constructor(
    private chatService: IntercomChatService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
  }

  uploadDone() {
    this.loading = false;
  }

  onIframeError() {
    this.iframeError = true;
  }

  openChat() {
    this.chatService.show();
  }
}
