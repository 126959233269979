import { ActionReducerMap } from "@ngrx/store";
import { reducer, State } from "./user.reducer";

export interface UserState {
  status: State;
}

export const userReducer: ActionReducerMap<UserState> = {
  status: reducer
};

export * from "./user.reducer";
