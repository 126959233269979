// Core
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";

// Router
import { Router } from "@angular/router";

// Store
import { CompanyDataFacade } from "src/app/domains/company-data/store/facade/company-data.facade";

// Models
import { CompanyData } from "src/app/domains/company-data/models/company-data.model";

@Component({
  selector: "onboarding-company-data-form",
  templateUrl: "./company-data-form.component.html",
  styleUrls: ["./company-data-form.component.scss"]
})
export class OnboardingCompanyDataFormComponent implements OnInit {
  frm: UntypedFormGroup;
  mainTypes: any[] = [
    { locale: "type1", name: "Freelance" },
    { locale: "type2", name: "Azienda di telecomunicazioni" },
    { locale: "type3", name: "Installatore" },
    { locale: "type4", name: "Ingegnere" },
    { locale: "type5", name: "Web agenzy" },
    { locale: "type6", name: "Agenzia plurimandatario" },
    { locale: "type7", name: "Agenzia" },
    { locale: "type8", name: "Consulente informatico" },
    { locale: "type9", name: "Altro" }
  ];

  companyData: CompanyData = {
    company_name: "",
    country: "",
    city: "",
    company_website: "",
    company_type: ""
  };

  public loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private cdFacade: CompanyDataFacade
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.frm = this.fb.group({
      companyName: [null, Validators.required],
      country: [null, Validators.required],
      city: [null, Validators.required],
      companyWebsite: [null, Validators.required],
      companyType: [this.mainTypes[0], Validators.required]
    });
  }

  isInvalid(name: string) {
    return this.frm.get(name).invalid && this.frm.get(name).dirty;
  }

  setType(typeName: any) {
    this.frm.controls.companyType.setValue(typeName);
  }

  saveFormData() {
    const payload = {
      company_name: this.frm.get("companyName").value,
      country: this.frm.get("country").value,
      city: this.frm.get("city").value,
      company_website: this.frm.get("companyWebsite").value,
      company_type: this.frm.get("companyType").value
    };
    this.cdFacade.saveCompanyData(payload);
  }

  onSubmit() {
    if (this.frm.valid) {
      this.saveFormData();
      this.router.navigate(["/partner-contact/3"]);
    }
  }
}
