import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersFacade } from 'src/app/domains/customers/store/facade/customers.facade';
import { Customer } from '@app/domains/customers/models/customers.model';
import { CustomerControlpanelPopupComponent } from '../../components/customer-controlpanel-popup/customer-controlpanel-popup.component';

@Component({
  selector: 'vov-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})

export class CustomersPageComponent implements OnInit {
  error$: Observable<string>;

  constructor(
    private customersFacade: CustomersFacade,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.error$ = this.customersFacade.errorCustomer$;
  }


  loginCustomer(c: Customer) {
    const ref = this.modal.open(
      CustomerControlpanelPopupComponent,
      { windowClass: 'customer-controlpanel-popup' }
    );
    ref.componentInstance.customer = c;
  }

}
