// Libs
import { createFeatureSelector, createSelector } from "@ngrx/store";

// Models
import { User } from "../../models/user.model";

// Store
import { UserState } from "../reducers";

export const selectUserState = createFeatureSelector<UserState>("user");

export const selectUserStatus = createSelector(
  selectUserState,
  (state: UserState) => state.status
);

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.user) {
      return state.status.user;
    }
  }
);
export const selectUserError = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.error) {
      return state.status.error.user;
    }
  }
);
export const selectUserLoading = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.loading) {
      return state.status.loading.user;
    }
  }
);
export const selectUserSuccess = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.loading) {
      return state.status.success.user;
    }
  }
);

export const selectError = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.error) {
      return state.status.error;
    }
  }
);
export const selectLoading = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.loading) {
      return state.status.loading;
    }
  }
);
export const selectUsers = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.users) {
      return state.status.users;
    }
  }
);
export const selectUsersError = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.error) {
      return state.status.error.users;
    }
  }
);
export const selectUsersLoading = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.loading) {
      return state.status.loading.users;
    }
  }
);
export const selectUsersSuccess = createSelector(
  selectUserState,
  (state: UserState) => {
    if (state.status && state.status.loading) {
      return state.status.success.users;
    }
  }
);

export const getLanguage = createSelector(selectUser, (state: User) => {
  if (state && state.language) {
    return state.language;
  }
});

export const getEmail = createSelector(selectUser, (state: User) => {
  if (state && state.email) {
    return state.email;
  }
});

export const getFirstName = createSelector(selectUser, (state: User) => {
  if (state && state.first_name) {
    return state.first_name;
  }
});

export const showPrivateLink = createSelector(selectUser, (state: User) => {
  if(state && state.partner) {
    return state.partner.show_private_link_to_agents
  }
})

export const getGlobalDriveLink = createSelector(selectUser, (state: User) => {
  if(state) {
    return state.global_google_drive_link;
  }
})

export const getPrivateDriveLink = createSelector(selectUser, (state: User) => {
  if(state && state.partner) {
    return state.partner.google_drive_link
  }
})