// Core
import { Injectable } from '@angular/core';

// Libs
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


import { Logger } from '@app/core/logger/logger.service';
const log = new Logger('confirmation-popup.service.ts');

// Components
import { ConfirmationPopupComponent } from './confirmation-popup.component';

class ConfirmationOptions {
  labels = {
    title: 'app.confirmation.title',
    ok: 'app.confirmation.ok',
    cancel: 'app.confirmation.cancel'
  };
  classes = {
    cancel: '',
    confirm: ''
  };
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationPopupService {
  options: ConfirmationOptions;
  constructor(private modalService: NgbModal) {}

  show(
    message: string,
    options?: ConfirmationOptions,
    ngbConfigOverride?: NgbModalOptions
  ) {
    const ngbConfigDefault = {
      backdrop: true,
      centered: true,
      ariaLabelledBy: 'confirmation-popup'
    };

    if (!options) {
      options = new ConfirmationOptions();
    }
    const ngbConfig = ngbConfigOverride || ngbConfigDefault;
    const ref = this.modalService.open(ConfirmationPopupComponent, ngbConfig);

    ref.componentInstance.labels = options.labels;
    ref.componentInstance.classes = options.classes;
    ref.componentInstance.message = message;

    return ref.result.then(
      (result) => {
        log.debug('modal closed success');
        return result;
      },
      (err) => {
        log.debug('modal closed error');
        return err;
      }
    );
  }
}
