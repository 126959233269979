// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Libs
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgVoxloudModule } from "ng-voxloud";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LaddaModule } from "angular2-ladda";

import { SharedDirectivesModule } from '../directives/directives.module';

// Components
import { ConfirmationPopupComponent } from "./confirmation-popup/confirmation-popup.component";
import { ContactListComponent } from './contact-list/contact-list.component';
import { EditPartnerFormComponent } from './edit-partner-form/edit-partner-form.component';
import { EditAgentFormComponent } from './edit-agent-form/edit-agent-form.component';
import { AvatarComponent } from './avatar/avatar.component';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { QuantitySelectorComponent } from './quantity-selector/quantity-selector.component';
import { ToastsComponent } from "./toasts/toasts.component";
import { SelectPartnerComponent } from "./select-partner/select-partner.component";
import { DriveFolderComponent } from './drive-folder/drive-folder.component';
import { AddEditLinkFormComponent } from './add-edit-link-form/add-edit-link-form.component';
import { SharedPipesModule } from "../pipes/pipes.module";
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        ConfirmationPopupComponent,
        ContactListComponent,
        EditPartnerFormComponent,
        EditAgentFormComponent,
        AvatarComponent,
        TablePaginationComponent,
        QuantitySelectorComponent,
        ToastsComponent,
        SelectPartnerComponent,
        DriveFolderComponent,
        AddEditLinkFormComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgVoxloudModule,
        NgxSkeletonLoaderModule,
        LaddaModule,
        SharedDirectivesModule,
        SharedPipesModule,
        NgbCollapseModule
    ],
    exports: [
        ConfirmationPopupComponent,
        ContactListComponent,
        EditPartnerFormComponent,
        EditAgentFormComponent,
        AvatarComponent,
        TablePaginationComponent,
        QuantitySelectorComponent,
        ToastsComponent,
        SelectPartnerComponent,
        DriveFolderComponent,
        AddEditLinkFormComponent
    ]
})
export class SharedComponentsModule {}
