import { ActionReducerMap } from "@ngrx/store";

import { State, reducer } from "./company-other-data.reducer";

export * from "./company-other-data.reducer";

export interface CompanyOtherDataState {
  status: State;
}

export const companyOtherDataReducer: ActionReducerMap<CompanyOtherDataState> = {
  status: reducer
};
