<div class="partners-table__main">
  <div class="partners-table__actions">
    <!-- TODO: UNCOMMENT TO HAVE FILTERS LOGIC -->
    <!-- <vov-filter-partners (applyFilters)="onApplyFilters($event)" [startingFilters]="queryFilters">
    </vov-filter-partners> -->
    <div class="partner-search">
      <i class="icon-small-search"></i>
      <input [formControl]="searchControl" class="partner-search-input"
        [placeholder]="'partners.actions.search-placeholder' | translate" />
    </div>
  </div>
  <div class="partners-table">
    <table>
      <thead>
        <tr>
          <td class="sortable" (click)="toggleSort('id')" translate>partners.table.header.id<i *ngIf="sort.id"
            class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.id === 'asc' }"></i>
        </td>
          <td class="sortable" (click)="toggleSort('name')" translate>partners.table.header.name<i *ngIf="sort.name"
              class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.name === 'asc' }"></i>
          </td>
          <td class="sortable" (click)="toggleSort('email')" translate>partners.table.header.email<i *ngIf="sort.email"
              class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.email === 'asc' }"></i>
          </td>
          <td class="sortable" (click)="toggleSort('vat')" translate>partners.table.header.vat<i *ngIf="sort.vat"
              class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.vat === 'asc' }"></i>
          </td>
          <td translate>partners.table.header.actions</td>
        </tr>
      </thead>
      <tbody class="loading" *ngIf="loading$ | async">
        <tr *ngFor="let i of [1,2,3,4,5]">
          <td colspan="5">
            <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ |async) && pagination.empty">
        <tr>
          <td colspan="5" translate>partners.table.empty</td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ | async) && !pagination.empty">
        <tr *ngFor="let partner of (partnersList$ | async)">
          <td>{{partner.id}}</td>
          <td>{{partner.name}}</td>
          <td>{{partner.email}}</td>
          <td>{{partner.vat}}</td>
          <td class="partner-actions">
            <i class="icon-small-folder" [ngbTooltip]="'partners.table.info.folder-link' | translate" (click)="addLink(partner)" *ifUserRole="[USER_ROLES.SUPERVISOR]"></i>
            <i (click)="inviteAdmin(partner.id)" [ngbTooltip]="'partners.table.info.invite-agent' | translate"
              class="icon-big-user"></i>
            <!-- TODO: implement it <i (click)="impersonatePartner(partner.id)" title="impersonate" class="icon-small-alert"></i> -->
            <i (click)="editPartner(partner)" [ngbTooltip]="'partners.table.info.edit' | translate"
              class="icon-small-edit"></i>
            <i (click)="deletePartner(partner.id)" [ngbTooltip]="'partners.table.info.delete' | translate"
              class="icon-small-delete"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <vov-table-pagination (onSelectPageSize)="selectPageSize($event)" (onChangeCurrentPage)="changeCurrentPage($event)" [totalPages]="pagination.totalPages" [pagination]="pagination"></vov-table-pagination>
</div>
