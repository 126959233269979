import { ActionReducerMap } from "@ngrx/store";
import { State, reducer } from "./partner-data.reducer";

export * from "./partner-data.reducer";

export interface PartnerDataState {
  status: State;
}

export const partnerDataReducer: ActionReducerMap<PartnerDataState> = {
  status: reducer
};
