import { Injectable } from '@angular/core';

// we need to provide this service globally
// because it's used by other shared entities
@Injectable({providedIn: 'root'})
export class NotificationService {
  toasts: any[] = [];

  constructor() {}

  show(message: string, options: any = {}) {
    this.toasts.push({ message, ...options });
  }

  error(message: string, overrideOptions?) {
    const options = {
      classname: 'bg-danger text-light',
      iconclass: 'icon-small-alert text-light',
      ...overrideOptions
    }
    this.show(message,options);
  }

  success(message: string, overrideOptions?) {
    const options = {
      classname: 'bg-success text-light',
      iconclass: 'icon-small-check-circle text-light',
      ...overrideOptions
    };
    this.show(message,options);
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

}
