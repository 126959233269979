// Core
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// Environments
import { environment } from 'src/environments/environment';

// Models
import { Customer, CustomersResponse, CustomersSearchRequest } from '../models/customers.model';


@Injectable()
export class CustomersService {
  private apiUrl = `${environment.apiEndpoint}/api/v1/customers`;
  constructor(private http: HttpClient) { }

  getCustomers(options: CustomersSearchRequest): Observable<CustomersResponse> {
    return this.http.get<CustomersResponse>(`${this.apiUrl}/search`, { params: options as any });
  }

  getCustomer(id: string) {
    return this.http.get(`${this.apiUrl}/${id}`);
  }

  getControlPanelLink(id: string): Observable<{ link: string }> {
    return this.http.get<{ link: string }>(`${this.apiUrl}/${id}/cp-link`);
  }

  addCustomer(c: Customer) {
    return this.http.post(`${this.apiUrl}`, c);
  }

  updateCustomer(c: Customer) {
    return this.http.put(`${this.apiUrl}/${c.company_id}`, c);
  }

}
