import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { User, UserActivateRequest, USER_ROLE, UsersResponse, GlobalPropertyResponse, GlobalProperty } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import {
  UserActionTypes,
  GetUsers,
  GetUsersSuccess,
  GetUsersError,
  UpdateUserAvatar,
  UpdateUserAvatarSuccess,
  UpdateUserAvatarError,
  ActivateUser,
  ActivateUserSuccess,
  ActivateUserError,
  UpdateGlogalDriveLink,
  UpdateGlogalDriveLinkError,
  UpdateGlogalDriveLinkSuccess
} from '../actions';

@Injectable()
export class UserEffects {

  @Effect()
  getUsers$ = this.actions$.pipe(
    ofType(UserActionTypes.GetUsers),
    map((action: GetUsers) => action.role),
    switchMap((role: USER_ROLE) => {
      return this.service.getUsers(role).pipe(
        map((response: UsersResponse) => new GetUsersSuccess(response.content)),
        catchError(error => of(new GetUsersError(error)))
      );
    })
  );

  @Effect()
  updateUserAvatar$ = this.actions$.pipe(
    ofType(UserActionTypes.UpdateUserAvatar),
    map((action: UpdateUserAvatar) => action.payload),
    switchMap((d: {data: any, email: string}) => {
      return this.service.updateUserAvatar(d.data,d.email).pipe(
        map((response: User) => new UpdateUserAvatarSuccess(response)),
        catchError(error => of(new UpdateUserAvatarError(error)))
      );
    })
  );

  @Effect()
  activatUser$ = this.actions$.pipe(
    ofType(UserActionTypes.ActivateUser),
    map((action: ActivateUser) => action.payload),
    switchMap((u: UserActivateRequest) => {
      return this.service.activateUser(u).pipe(
        map((response: User) => new ActivateUserSuccess(response)),
        catchError(error => of(new ActivateUserError(error)))
      );
    })
  );

  @Effect()
  updateGlogalDriveLink$ = this.actions$.pipe(
    ofType(UserActionTypes.UpdateGlogalDriveLink),
    map((action: UpdateGlogalDriveLink) => action.payload),
    switchMap((data: GlobalProperty) => {
      return this.service.updateGlobalDriveLink(data).pipe(
        map((response: GlobalPropertyResponse) => new UpdateGlogalDriveLinkSuccess(response)),
        catchError(error => of(new UpdateGlogalDriveLinkError(error)))
      )
    })
  )


  constructor(private actions$: Actions, private service: UserService) {}
}
