import { Component, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Partner } from '@app/domains/partners/models/partners.model';
import { EditPartnerFormComponent } from '@app/shared/components/edit-partner-form/edit-partner-form.component';

@Component({
  selector: 'vov-edit-partner-popup',
  templateUrl: './edit-partner-popup.component.html',
  styleUrls: ['./edit-partner-popup.component.scss']
})
export class EditPartnerPopupComponent implements OnInit {
  @ViewChild(EditPartnerFormComponent) public form: EditPartnerFormComponent;
  public partner: Partner;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  savePartner() {
    this.form.savePartner();
  }

  close() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
