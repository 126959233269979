import { ActionReducerMap } from '@ngrx/store';
import { reducer, State } from './auth.reducer';

export interface AuthState {
  status: State;
}

export const authReducer: ActionReducerMap<AuthState> = {
  status: reducer
};

export * from './auth.reducer';
