<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="confirmationModalLabel" translate>{{labels?.title}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">{{message | translate}}</div>
  <div class="modal-footer">
    <button type="button" class="vox-btn vox-btn-secondary-dark-grey  {{classes?.cancel}}" data-dismiss="modal" (click)="activeModal.close(false)" translate>{{labels?.cancel}}</button>
    <button type="button" class="vox-btn vox-btn-secondary {{classes?.confirm}}" data-dismiss="modal" (click)="activeModal.close(true)" translate>{{labels?.ok}}</button>
  </div>
</div>
