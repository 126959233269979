// Core
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";

// Libs
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { IntercomModule } from "ng-intercom";

// i18n
import { I18nService, HttpLoaderFactory } from "./core/i18n";

// Environments
import { environment } from "src/environments/environment";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./features/auth/auth.module";
import { CoreModule } from "./core/core.module";
import { DomainsModule } from "./domains/domains.module";
import { MainModule } from "./features/main/main.module";
import { SharedModule } from "./shared/shared.module";
import { OnboardingModule } from "./features/onboarding/onboarding.module";
import { PartnersModule } from "./features/partners/partners.module";
import { AgentsModule } from "./features/agents/agents.module";
import { CustomersModule } from "./features/customers/customers.module";
// Services
import { ConfirmationPopupService } from "./shared/components/confirmation-popup/confirmation-popup.service";

// Components
import { AppComponent } from "./app.component";
import { ConfirmationPopupComponent } from "./shared/components/confirmation-popup/confirmation-popup.component";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        DomainsModule,
        SharedModule,
        OnboardingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IntercomModule.forRoot({
            appId: environment.intercom_app_id,
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),
        AuthModule,
        MainModule,
        PartnersModule,
        AgentsModule,
        CustomersModule
    ],
    providers: [
        ConfirmationPopupService,
        {
            provide: APP_INITIALIZER,
            useFactory: (i18n: I18nService) => {
                return () => {
                    i18n.init(environment.defaultLanguage, environment.supportedLanguages);
                };
            },
            deps: [I18nService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
