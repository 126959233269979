// Core
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// Libs
import { TranslateService } from "@ngx-translate/core";

// i18n
import { I18nService } from "src/app/core/i18n";

// Environments
import { environment } from "src/environments/environment";

// Store
import { AuthFacade } from "src/app/domains/auth";

// Models
import { Languages } from "src/app/shared/languages.model";
import { Credentials } from "src/app/domains/auth/models";
import { ContactPhone, CONTACT_PHONES } from '@src/app/shared/contact-phones.constant';

@Component({
  selector: "vov-auth-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class AuthLoginPageComponent implements OnInit {
  error$ = this.facade.error$;
  loading$ = this.facade.loading$;
  public langs: Languages[];
  public currentLang: string;
  public routerMessage: string;
  currentWidth: string;

  criteria = {
    len: null,
    upperCase: null,
    lowerCase: null,
    digit: null,
    special: null,
  };

  public phoneNumbers: ContactPhone[] = CONTACT_PHONES;
  public selectedPhoneNumber: any;

  constructor(
    private facade: AuthFacade,
    private i18n: I18nService,
    private translate: TranslateService,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.routerMessage = navigation.extras.state.message;
    }
  }

  ngOnInit() {
    this.currentLang = this.i18n.language;
    this.langs = environment.supportedLanguages;

    this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    window.onresize = () => {
      this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    };

    this.selectedPhoneNumber = this.phoneNumbers[0];
  }

  onChangeLanguage(evt) {
    this.i18n.language = evt;
  }

  goToWebsite() {
    let url = this.translate.instant(`auth.right.url`);
    window.open(url, "_blank");
  }

  onSubmit($event: Credentials) {
    this.facade.login($event);
  }

  onSelectPhoneNumber(phoneNumber: string) {
    this.selectedPhoneNumber = phoneNumber;
  }
}
