import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vov-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuantitySelectorComponent),
      multi: true,
    }
  ]
})
export class QuantitySelectorComponent implements OnInit, ControlValueAccessor {

  private allowedKeys = ['ArrowLeft', 'ArrowRight'];
  private specials = ['ArrowUp', 'ArrowDown', 'Backspace', 'Delete'];

  public _min: number;
  public _max: number;
  private _step: number = 1;

  public value: String = '0';


  @Input() set min(v: number){
    if(v && v !== this._min){
      this._min = this._max && v > this._max ? this._max : v;
    }
  }

  @Input() set max(v: number){
    if(v && v !== this._max){
      this._max = this._min && v < this._min ? this._min : v;
    }
  }

  @Input() set step(v: number){
    if(v && v !== this._step){
      this._step = v;
    }
  }

  @Input() set disable(v: boolean){
    if(typeof v === 'boolean'){
      this.isDisabled = v || this._formDisable;
      this._inputDisable = v;
    }
  }

  private onChange = (_) => {};
  private onTouch = (_) => {};

  private _inputDisable: boolean = false;
  private _formDisable: boolean = false;
  public isDisabled: boolean = this._inputDisable && this._formDisable;

  constructor() { }


  ngOnInit() {
    this.value = this._min ? this._min.toString() : '0';
    this.onChange(+this.value);
  }

  public onModelChange(evt: number){
    // The reason for string -> https://stackoverflow.com/a/53437062
    let value = +this.value;
    if(this._min && value < this._min){
      value = this._min;
    }
    if(this._max && value > this._max){
      value = this._max;
    }
    this.value = new String(value);
    this.onChange(+this.value);
  }

  public onKeydown(evt: KeyboardEvent){
    if(this.allowedKeys.includes(evt.key)) return;
    if(this.specials.includes(evt.key)) {
      this.handleSpecialKeys(evt);
      return;
    }
    if(!evt.key.match(/[0-9]/)) evt.preventDefault();
  }

  public addStep(){
    let value = +this.value;
    if(value < this._max){
      value += this._step;
      this.value = new String(value);
      this.onChange(+this.value);
    }
  }

  public subtractStep(){
    let value = +this.value;
    if(value > this._min){
      value -= this._step;
      this.value = new String(value);
      this.onChange(+this.value);
    }
  }

  private handleSpecialKeys(evt: KeyboardEvent){
    switch(evt.key){
      case 'ArrowUp':
        this.addStep();
        evt.preventDefault();
        break;
      case 'ArrowDown':
        this.subtractStep();
        evt.preventDefault();
        break;
      case 'Backspace':
      case 'Delete':
        let value = +this.value;
        if(value < this._step * 10 && value > this._step * -10){
          value = this._min ? this._min : 0;
          this.value = new String(value);
          this.onChange(+this.value);
        }
        break;
    }
  }

  writeValue(obj: number | string): void {
    if(obj){
      if(typeof obj === 'number'){
        this.value = new String(obj);
      }
      if(typeof obj === 'string'){
        this.value = obj;
      }
      this.onChange(+this.value);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.onChange(this.value ? +this.value : this.value);
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled || this._inputDisable;
    this._formDisable = isDisabled;
  }

}
