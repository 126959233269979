// Core
import { Injectable } from "@angular/core";

// Store
import { select, Store } from "@ngrx/store";

import {
  selectCompanyName,
  selectCountry,
  selectCity,
  selectCompanyWebsite,
  selectCompanyType
} from "../selectors/company-data.selector";
import { CompanyDataState, State } from "../reducers";
import { SaveCompanyData } from "../actions/company-data.action";

@Injectable()
export class CompanyDataFacade {
  company_name$ = this.store.pipe(select(selectCompanyName));
  country$ = this.store.pipe(select(selectCountry));
  city$ = this.store.pipe(select(selectCity));
  company_website$ = this.store.pipe(select(selectCompanyWebsite));
  company_type$ = this.store.pipe(select(selectCompanyType));

  constructor(private store: Store<CompanyDataState>) {}

  saveCompanyData(companyData: State) {
    this.store.dispatch(new SaveCompanyData(companyData));
  }
}
