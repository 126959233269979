import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import { Actions, Effect, ofType } from "@ngrx/effects";

import { Credentials, AuthToken } from "../../models";
import { AuthService } from "../../services";
import { AuthActionTypes, Login, LoginFailure, LoginSuccess, Logout } from "../actions";
import { Go } from "src/app/domains/router";

@Injectable()
export class AuthEffects {
  @Effect()
  login$ = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    map((action: Login) => action.payload.credentials),
    switchMap((credentials: Credentials) => {
      return this.service.login(credentials).pipe(
        map((token: AuthToken) => new LoginSuccess({ token })),
        catchError(error => of(new LoginFailure({ error })))
      );
    })
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.LoginJustLogged),
    map(() => new Go({ path: ["/main"] }))
  );

  @Effect()
  loginRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    map((action: Logout) => {
      if (action.payload && action.payload.message) {
        return new Go({ path: ["/auth"], extras: { replaceUrl: true, state: { message: action.payload.message } } })
      }
      return new Go({ path: ["/auth"], extras: { replaceUrl: true } })
    })
  );

  constructor(private actions$: Actions, private service: AuthService) { }
}
