import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { UserFacade } from '@app/domains/user/store/facade/user.facade';
import { User } from '@app/domains/user/models/user.model';
import { State } from '../reducers/customers.reducer';
import { PaginationOptions } from '@app/core/config/models/api.model';
import { Customer, CustomersResponse, CustomersSearchRequest } from '../../models/customers.model';
import { CustomersService } from '../../services/customers.service';
import { selectCustomersStatus } from '../selectors/customers.selectors';
import {
  CustomersActionTypes,
  GetCustomers,
  GetCustomersSuccess,
  GetCustomersError,
  AddCustomer,
  AddCustomerSuccess,
  AddCustomerError,
  UpdateCustomer,
  UpdateCustomerSuccess,
  UpdateCustomerError
} from '../actions';

@Injectable()
export class CustomersEffects {

  @Effect()
  getCustomers$ = this.actions$.pipe(
    ofType(CustomersActionTypes.GetCustomers),
    map((action: GetCustomers) => action.payload),
    switchMap((payload:CustomersSearchRequest) => {
      return this.service.getCustomers(payload).pipe(
        map((response: CustomersResponse ) => new GetCustomersSuccess({ customers: response })),
        catchError(error => of(new GetCustomersError({ error })))
      );
    })
  );

  @Effect()
  addCustomer$ = this.actions$.pipe(
    ofType(CustomersActionTypes.AddCustomer),
    map((action: AddCustomer) => action.payload),
    switchMap((payload: {customer: Customer, partnerId: string}) => {
      return this.service.addCustomer(payload.customer).pipe(
        map((response: Customer) => new AddCustomerSuccess({ customer: response})),
        catchError(error => of(new AddCustomerError({ error })))
      );
    })
  );

  @Effect()
  addCustomerSuccess$ = this.actions$.pipe(
    ofType(CustomersActionTypes.AddCustomerSuccess),
    withLatestFrom(this.userFacade.user$),
    map((args: [AddCustomerSuccess, User]) => {
      return new GetCustomers({ partner_id: args[1].partner.id });
    })
  );

  @Effect()
  updateCustomer$ = this.actions$.pipe(
    ofType(CustomersActionTypes.UpdateCustomer),
    map((action: AddCustomer) => action.payload),
    switchMap((payload:{customer: Customer, partnerId: string} ) => {
      return this.service.updateCustomer(payload.customer).pipe(
        map((response: Customer) => new UpdateCustomerSuccess({ customer: response})),
        catchError(error => of(new UpdateCustomerError({ error })))
      );
    })
  );

  @Effect()
  updateCustomerSuccess$ = this.actions$.pipe(
    ofType(CustomersActionTypes.UpdateCustomerSuccess),
    withLatestFrom(this.userFacade.user$),
    map((args: [UpdateCustomerSuccess, User]) => {
      return new GetCustomers({ partner_id: args[1].partner.id });
    })
  );



  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private userFacade: UserFacade,
    private service: CustomersService
  ) {}
}
