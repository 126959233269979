// Core
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// Rxjs
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { CONFIG, Config } from "src/app/core/config";
import { AuthToken, Credentials } from "src/app/domains/auth/models";
import { httpErrorHandler } from "src/app/shared/http";

@Injectable()
export class AuthService {
  apiLogin: string;
  constructor(
    private http: HttpClient,
    @Inject(CONFIG) private config: Config
  ) {
    this.apiLogin = config.api.auth.login;
  }

  login(data: Credentials): Observable<AuthToken> {
    return this.http
      .post<AuthToken>(this.apiLogin, data)
      .pipe(catchError(httpErrorHandler));
  }

  static jwtDecode(jwt: string) {
    const tokens = jwt.split(".");
    return JSON.parse(atob(tokens[1]));
  }
}
