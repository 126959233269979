// Libs
import { Action } from '@ngrx/store';

// Models
import { Customer,CustomersResponse, CustomersSearchRequest } from '../../models/customers.model';
import { PaginationOptions } from '@app/core/config/models/api.model';

export enum CustomersActionTypes {
  GetCustomers = '[Customers] Get Customers list',
  GetCustomersSuccess = '[Customers] Get Customers list success',
  GetCustomersError = '[Customers] Get Customers list error',
  AddCustomer = '[Customers] Add Customer',
  AddCustomerSuccess = '[Customers] Add Customer success',
  AddCustomerError = '[Customers] Add Customer error',
  UpdateCustomer = '[Customers] Update Customer',
  UpdateCustomerSuccess = '[Customers] Update Customer success',
  UpdateCustomerError = '[Customers] Update Customer error'
}

export class GetCustomers implements Action {
  readonly type = CustomersActionTypes.GetCustomers;
  constructor(readonly payload: CustomersSearchRequest ) {}
}

export class GetCustomersSuccess implements Action {
  readonly type = CustomersActionTypes.GetCustomersSuccess;
  constructor(readonly payload: { customers: CustomersResponse }) {}
}

export class GetCustomersError implements Action {
  readonly type = CustomersActionTypes.GetCustomersError;
  constructor(readonly payload: { error: string }) {}
}

export class AddCustomer implements Action {
  readonly type = CustomersActionTypes.AddCustomer;
  constructor(readonly payload: { customer: Customer, partnerId: string }) {}
}

export class AddCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.AddCustomerSuccess;
  constructor(readonly payload: { customer: Customer }) {}
}

export class AddCustomerError implements Action {
  readonly type = CustomersActionTypes.AddCustomerError;
  constructor(readonly payload: { error: string }) {}
}
export class UpdateCustomer implements Action {
  readonly type = CustomersActionTypes.UpdateCustomer;
  constructor(readonly payload: { customer: Customer, partnerId: string }) {}
}

export class UpdateCustomerSuccess implements Action {
  readonly type = CustomersActionTypes.UpdateCustomerSuccess;
  constructor(readonly payload: { customer: Customer }) {}
}

export class UpdateCustomerError implements Action {
  readonly type = CustomersActionTypes.UpdateCustomerError;
  constructor(readonly payload: { error: string }) {}
}



export type CustomersActions =
  GetCustomers |
  GetCustomersSuccess |
  GetCustomersError |
  AddCustomer |
  AddCustomerSuccess |
  AddCustomerError |
  UpdateCustomer |
  UpdateCustomerSuccess |
  UpdateCustomerError;
