<div class="header header--color-white">
  <a href="/auth/login" class="header__logo">
    <picture>
      <source media="(max-width: 992px)" srcset="/assets/img/logo/voxloud-logo-only.svg">
      <img src="/assets/img/logo/voxloud-wordmark.svg" alt="voxloud logo" width="150" height="30">
    </picture>
    <span class="vox-heading-5" translate>auth.header.partners</span>
  </a>
  <div class="header__actions">
    <i class="icon-small-call"></i>
    <span translate>auth.header.help</span>
    <div ngbDropdown class="vox-dropdown">
      <button ngbDropdownToggle id="dropdownPhoneNumbers">
        <span class="flag flag-select flag-icon {{selectedPhoneNumber.flagClass}}"></span> <b>{{selectedPhoneNumber.number}}</b>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownPhoneNumbers">
        <button ngbDropdownItem
        *ngFor="let phoneNumber of phoneNumbers"
        (click)="onSelectPhoneNumber(phoneNumber)">
          <span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}
        </button>
      </div>
    </div>
    <vox-language-picker class="language-picker" [language]="currentLang" [availableLanguages]="langs"
      [side]='currentWidth' (selected)="onChangeLanguage($event)">
    </vox-language-picker>
  </div>
  <div ngbDropdown class="vox-dropdown header__mobile-phone">
    <button ngbDropdownToggle id="dropdownMobilePhoneNumbers">
      <i class="icon-small-call"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMobilePhoneNumbers">
      <a href="tel:{{phoneNumber.number}}" ngbDropdownItem
      *ngFor="let phoneNumber of phoneNumbers"><span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}</a>
    </div>
  </div>
</div>
<div class="main-container">
  <section class="main-container__left" *ngIf="!resetToken">
    <h2 class="vox-heading-2 header-box" translate>forgot-password.title</h2>
    <div *ngIf="!isLoading">
      <div *ngIf="restoreResponse" class="vox-inline-message vox-inline-message_royal-blue">
        <div>
          <span translate>{{restoreResponse}}</span>
        </div>
        <i (click)="restoreResponse = ''" class="icon-small-close"></i>
      </div>
      <auth-forgot-password-form [loading]="isLoading" (submitted)="onRestoreAccount($event)">
      </auth-forgot-password-form>
    </div>
    <div *ngIf="isLoading">
      <div class="form-row mb-3">
        <div class="col-12 vox-input-group">
          <ngx-skeleton-loader [theme]="{ margin:'0', width:'100px', height: '21px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ margin:'0', height: '56px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ margin:'0', width: '305px', height: '85px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ 'margin-top':'10px', width: '130px', height: '56px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </section>
  <section class="main-container__left" *ngIf="resetToken">
    <h2 class="vox-heading-2 header-box" translate>reset-password.title</h2>
    <div *ngIf="!isLoading">
      <div *ngIf="resetResponse" class="vox-inline-message vox-inline-message_royal-blue">
        <div>
          <span translate>{{resetResponse}}</span>
        </div>
        <i (click)="resetResponse = ''" class="icon-small-close"></i>
      </div>
      <auth-reset-password-form *ngIf="resetToken" [loading]="isLoading" (submitted)="onResetPassword($event)">
      </auth-reset-password-form>
    </div>
    <div *ngIf="isLoading">
      <div class="form-row mb-3">
        <div class="col-12 vox-input-group">
          <ngx-skeleton-loader [theme]="{ margin:'0', width:'150px', height: '21px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ margin:'0', height: '56px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ 'margin-top':'10px', width: '205px', height: '56px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </section>
  <section class="main-container__right">
    <div class="product-container">
      <h2 class="vox-heading-2" translate>auth.right.title</h2>
      <p class="text" translate>auth.right.description</p>
      <button (click)="goToWebsite()" class="vox-btn" translate>auth.right.button</button>
    </div>
  </section>
</div>