import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnersFacade } from 'src/app/domains/partners/store/facade/partners.facade';
import { Partner } from '@app/domains/partners/models/partners.model';
import { ConfirmationPopupService } from '@app/shared/components/confirmation-popup/confirmation-popup.service';
import { AddPartnerPopupComponent } from '../../components/add-partner-popup/add-partner-popup.component';
import { EditPartnerPopupComponent } from '../../components/edit-partner-popup/edit-partner-popup.component';
import { AddEditLinkPopupComponent } from '../../components/add-edit-link-popup/add-edit-link-popup.component';

@Component({
  selector: 'vov-partners-page',
  templateUrl: './partners-page.component.html',
  styleUrls: ['./partners-page.component.scss']
})

export class PartnersPageComponent implements OnInit {
  error$: Observable<string>;

  constructor(
    private partnersFacade: PartnersFacade,
    private confirm: ConfirmationPopupService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.error$ = this.partnersFacade.errorPartners$;
  }
  addPartner() {
    this.modal.open(AddPartnerPopupComponent);
  }

  editPartner(partner: Partner) {
    const ref = this.modal.open(EditPartnerPopupComponent);
    ref.componentInstance.partner = partner;
  }

  addLink(partner: Partner) {
    const ref = this.modal.open(AddEditLinkPopupComponent);
    ref.componentInstance.partner = partner;
  }

  deletePartner(id: string) {
    this.confirm
      .show('partners.confirm-delete')
      .then((result) => {
        if (result) {
          this.partnersFacade.deletePartner(id);
        }
      });
  }
}
