import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AuthFacade } from "src/app/domains/auth";
import { UserService } from "src/app/domains/user/services/user.service";
import { User, USER_ROLE } from "src/app/domains/user/models/user.model";
import { UserFacade } from "src/app/domains/user";
import { IntercomChatService } from "@app/core/intercom/services/intercom-chat.service";

@Component({
  selector: "vov-layout-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class LayoutHeaderComponent implements OnInit {
  @Output() closingMenu = new EventEmitter<boolean>();
  public closed: boolean = true;
  public toggle: boolean = true;
  public user$: Observable<User>;
  public ROLES = USER_ROLE;

  constructor(
    private auth: AuthFacade,
    private userFacade: UserFacade,
    private chatService: IntercomChatService
  ) {}

  get roleName() {
    let roleString;
    this.user$
      .pipe(map(u => (u.roles && u.roles[0].split("ROLE_")[1]) || ""))
      .subscribe(string => {
        roleString = string.toLowerCase();
      });
    return `app.roles.${roleString}`;
  }

  ngOnInit() {
    this.user$ = this.userFacade.user$;
  }

  onCloseToggle() {
    this.toggle = !this.toggle;
  }

  closeMenu() {
    this.closed = !this.closed;
    this.closingMenu.emit(this.closed);
  }

  onLogout() {
    this.auth.logout();
    this.chatService.loginUser("", "");
  }
}
