// Core
import { Component, OnInit } from "@angular/core";

// i18n
import { I18nService } from "src/app/core/i18n";

// Libs
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

// Environment
import { environment } from "src/environments/environment";

// Models
import { Languages } from "src/app/shared/languages.model";
import { Router } from "@angular/router";

@Component({
  selector: "onboarding-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class OnboardingMainComponent implements OnInit {
  public langs: Languages[];
  public currentLang: string;
  public userLanguage: Subscription;
  currentWidth: string;

  criteria = {
    len: null,
    upperCase: null,
    lowerCase: null,
    digit: null,
    special: null,
  };

  constructor(
    private i18n: I18nService,
    private translate: TranslateService,
    public router: Router
  ) {}

  ngOnInit() {
    this.currentLang = this.i18n.language;
    this.langs = environment.supportedLanguages;
    this.translate.onLangChange.subscribe((evt: LangChangeEvent) => {
      this.getCriteriaTranslations();
    });

    this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    window.onresize = () => {
      this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    };
  }

  getCriteriaTranslations() {
    this.translate
      .get("signup.password.criteria.lenght")
      .subscribe((len: string) => {
        this.criteria.len = len;
      });
    this.translate
      .get("signup.password.criteria.upper_case")
      .subscribe((upperCase: string) => {
        this.criteria.upperCase = upperCase;
      });
    this.translate
      .get("signup.password.criteria.lower_case")
      .subscribe((lowerCase: string) => {
        this.criteria.lowerCase = lowerCase;
      });
    this.translate
      .get("signup.password.criteria.digit")
      .subscribe((digit: string) => {
        this.criteria.digit = digit;
      });
    this.translate
      .get("signup.password.criteria.special")
      .subscribe((special: string) => {
        this.criteria.special = special;
      });
  }

  onChangeLanguage(evt) {
    this.i18n.language = evt;
  }

  goToWebsite() {
    let url = this.translate.instant(`auth.right.url`);
    window.open(url, "_blank");
  }
}
