// Core
import { Injectable } from "@angular/core";

// Store
import { Store, select } from "@ngrx/store";

import {
  selectTelecomunications,
  selectCompanySize,
  selectSubscriptions,
  selectCompaniesSize,
  selectCustomersReady
} from "../selectors/company-other-data.selector";
import { CompanyOtherDataState, State } from "../reducers";
import { SaveCompanyOtherData } from "../actions/company-other-data.action";

@Injectable()
export class CompanyOtherDataFacade {
  telecomunications$ = this.store.pipe(select(selectTelecomunications));
  company_size$ = this.store.pipe(select(selectCompanySize));
  subscriptions$ = this.store.pipe(select(selectSubscriptions));
  companies_size$ = this.store.pipe(select(selectCompaniesSize));
  customers_ready$ = this.store.pipe(select(selectCustomersReady));

  constructor(private store: Store<CompanyOtherDataState>) {}

  saveCompanyOtherData(companyOtherData: State) {
    this.store.dispatch(new SaveCompanyOtherData(companyOtherData));
  }
}
