import {
  CompanyOtherDataActions,
  CompanyOtherDataActionTypes
} from "../actions/company-other-data.action";

export interface State {
  telecomunications: boolean;
  company_size: string;
  subscriptions: string;
  companies_size: string;
  customers_ready: boolean;
}

export const initialState: State = {
  telecomunications: false,
  company_size: "",
  subscriptions: "",
  companies_size: "",
  customers_ready: false
};

export function reducer(
  state: State = initialState,
  action: CompanyOtherDataActions
) {
  switch (action.type) {
    case CompanyOtherDataActionTypes.SaveCompanyOtherData:
      return {
        ...state,
        telecomunications: action.companyOtherData.telecomunications,
        company_size_size: action.companyOtherData.company_size,
        subscriptions: action.companyOtherData.subscriptions,
        companies_size: action.companyOtherData.companies_size,
        customers_ready: action.companyOtherData.customers_ready
      };
    default:
      return state;
  }
}
