<div class="header">
  <div class="header__menu">
    <button class="header__menu--btn vox-btn vox-btn-secondary" (click)="closeMenu()">
      <span class="header__menu--icon">&nbsp;</span>
    </button>
  </div>
  <div class="header__logo">
    <img src="/assets/img/logo/voxloud-wordmark.svg" alt="voxloud logo" width="150" height="30"
      class="header__logo--img" />
    <span class="header__logo--partners vox-paragraph" translate>header.partners</span>
  </div>

  <div class="header__user" ngbDropdown display="dynamic" placement="bottom-right" #headerDrop="ngbDropdown">
    <div class="header__user--profile" ngbDropdownToggle>
      <vov-avatar class="header__user--avatar" [user]="user$ | async" size="xs"></vov-avatar>
    </div>
    <div class="header__user-menu" ngbDropdownMenu>
      <div class="user-menu__username">
        <div class="user-menu__hello--name">
          <span class="vox-paragraph user-menu__username--hello" translate>header.user.hello</span>
          <span class="vox-paragraph user-menu__username--name">{{(user$ | async)?.first_name}}</span>
        </div>
        <span class="vox-chip vox-chip_royal-blue user-menu__username--role">{{ roleName | translate }}</span>
      </div>
      <a (click)="headerDrop.close()" routerLink="/main/profile" class="user-menu__profile">
        <div class="user-menu__profile--paragraph vox-paragraph" translate>header.user.profile</div>
      </a>

      <button class="user-menu__logout" ngbDropdownItems (click)="onLogout()">
        <div class="user-menu__logout--heading vox-heading-6" translate>header.user.logout</div>
      </button>
    </div>
  </div>
</div>