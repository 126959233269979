import { AuthLoginPageComponent } from "./login/login.component";
import { AuthForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthActivateUserComponent } from './activate-user/activate-user.component';

export const containers: any[] = [
  AuthLoginPageComponent,
  AuthForgotPasswordComponent,
  AuthActivateUserComponent
];

export * from "../containers/login/login.component";
export * from './forgot-password/forgot-password.component';
export * from './activate-user/activate-user.component';
