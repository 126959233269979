// Models
import { Agent } from '../../models/agents.model';
import { PaginationOptions } from '@app/core/config/models/api.model';
// Store
import { AgentsActions, AgentsActionTypes } from '../actions';

export interface State {
  agents: Agent[];
  agent: Agent;
  loading: {
    agents: boolean,
    agent: boolean
  };
  error: {
    agents: any
    agent: any
  };
  success: {
    agents: boolean,
    agent: boolean
  };
}

export const initialState: State = {
  agents: [],
  agent: null,
  loading: {
    agents: false,
    agent: false
  },
  error: {
    agents: null,
    agent: null
  },
  success: {
    agents: null,
    agent: null
  }
};

export function reducer(state = initialState, action: AgentsActions): State {
  switch (action.type) {
  case AgentsActionTypes.GetAgents: {
    return {
      ...state,
      loading: { ...state.loading, agents: true },
      success: { ...state.success, agents: null }
    };
  }
  case AgentsActionTypes.GetAgentsSuccess: {
    return {
      ...state,
      agents: action.payload.agents,
      loading: { ...state.loading, agents: false },
      success: { ...state.success, agents: true }
    };
  }
  case AgentsActionTypes.GetAgentsError: {
    return {
      ...state,
      error : { ...state.error, agents: action.payload.error },
      loading: { ...state.loading, agents: false },
      success: { ...state.success, agents: false }
    };
  }

  case AgentsActionTypes.DeleteAgent: {
    return {
      ...state,
      loading: { ...state.loading, agent: true },
      success: { ...state.success, agent: null }
    };
  }
  case AgentsActionTypes.DeleteAgentSuccess: {
    return {
      ...state,
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: true }
    };
  }
  case AgentsActionTypes.DeleteAgentError: {
    return {
      ...state,
      error : { ...state.error, agent: action.payload.error },
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: false }
    };
  }
  case AgentsActionTypes.AddAgent: {
    return {
      ...state,
      loading: { ...state.loading, agent: true },
      success: { ...state.success, agent: null }
    };
  }
  case AgentsActionTypes.AddAgentSuccess: {
    return {
      ...state,
      agent: action.payload.agent,
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: true }
    };
  }
  case AgentsActionTypes.AddAgentError: {
    return {
      ...state,
      agent: null,
      error : { ...state.error, agent: action.payload.error },
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: false }
    };
  }
  case AgentsActionTypes.UpdateAgent: {
    return {
      ...state,
      loading: { ...state.loading, agent: true },
      success: { ...state.success, agent: null }
    };
  }
  case AgentsActionTypes.UpdateAgentSuccess: {
    return {
      ...state,
      agent: action.payload.agent,
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: true }
    };
  }
  case AgentsActionTypes.UpdateAgentError: {
    return {
      ...state,
      agent: null,
      error : { ...state.error, agent: action.payload.error },
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: false }
    };
  } 
  case AgentsActionTypes.AddAdmin: {
    return {
      ...state,
      loading: { ...state.loading, agent: true },
      success: { ...state.success, agent: null }
    };
  }
  case AgentsActionTypes.AddAdminSuccess: {
    return {
      ...state,
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: true }
    };
  }
  case AgentsActionTypes.AddAdminError: {
    return {
      ...state,
      agent: null,
      error : { ...state.error, agent: action.payload.error },
      loading: { ...state.loading, agent: false },
      success: { ...state.success, agent: false }
    };
  }

  default:
    return state;
  }
}
