// Core
import { Injectable } from '@angular/core';

// Libs
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

// Models
import { Customer, CustomersSearchRequest, CustomersSearch } from '../../models/customers.model';
import { PaginationOptions } from '@app/core/config/models/api.model';
import { USER_ROLE, User } from '@app/domains/user/models/user.model';

// Store
import { UserFacade } from '@app/domains/user/store/facade';
import { State } from '../reducers';
import { GetCustomers, AddCustomer, UpdateCustomer } from '../actions';
import {
  selectCustomers,
  selectLoading,
  selectLoadingCustomers,
  selectLoadingCustomer,
  selectError,
  selectErrorCustomers,
  selectErrorCustomer,
  selectSuccessCustomers,
  selectSuccessCustomer
} from '../selectors';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomersFacade {
  customers$ = this.store.pipe(select(selectCustomers));
  loading$ = this.store.pipe(select(selectLoading));
  loadingCustomers$ = this.store.pipe(select(selectLoadingCustomers));
  loadingCustomer$ = this.store.pipe(select(selectLoadingCustomer));
  error$ = this.store.pipe(select(selectError));
  errorCustomers$ = this.store.pipe(select(selectErrorCustomers));
  errorCustomer$ = this.store.pipe(select(selectErrorCustomer));
  successCustomer$ = this.store.pipe(select(selectSuccessCustomer));
  successCustomers$ = this.store.pipe(select(selectSuccessCustomers));

  constructor(
    private store: Store<State>,
    private userFacade: UserFacade,
    private calendar: NgbCalendar
  ) { }

  private checkRole(u) {
    return [USER_ROLE.ADMIN, USER_ROLE.AGENT].includes(u.roles[0]);
  }

  getCustomers(options?: CustomersSearch) {
    this.userFacade.user$.pipe(filter(u => !!u.id))
      .subscribe((u: User) => {
        let dayAfter = options['dateObj'];
        if (dayAfter) {
          dayAfter = this.calendar.getNext(dayAfter);
          const month = dayAfter.month.toString().padStart(2, '0');
          const day = dayAfter.day.toString().padStart(2, '0');
          options['creation_date_to'] = `${dayAfter.year}-${month}-${day}`;
          delete options['dateObj'];
        }
        if (u.roles.includes(USER_ROLE.ADMIN) || u.roles.includes(USER_ROLE.AGENT)) {
          this.store.dispatch(new GetCustomers({ ...options, partner_id: u.partner.id }));
        }
        if (u.roles.includes(USER_ROLE.SUPERVISOR)) {
          this.store.dispatch(new GetCustomers({ ...options }));

        }
      });
  }

  addCustomer(customer: Customer) {
    this.userFacade.user$.pipe(filter(u => !!u.id))
      .subscribe(u => {
        if (this.checkRole(u)) {
          this.store.dispatch(new AddCustomer({ customer, partnerId: u.partner.id }));
        }
      });
  }

  updateCustomer(customer: Customer) {
    this.userFacade.user$.pipe(filter(u => !!u.id))
      .subscribe(u => {
        if (this.checkRole(u)) {
          this.store.dispatch(new UpdateCustomer({ customer, partnerId: u.partner.id }));
        }
      });
  }

}
