<form class="form-box" [formGroup]="frm" (ngSubmit)="onSubmit()">
  <div *ngIf="error" class="vox-inline-message vox-inline-message_light-grey">
    <div>
      <i class="icon-small-alert"></i>
      <span translate>login.error</span>
    </div>
    <i (click)="error = null;" class="icon-small-close"></i>
  </div>
  <div class="form-group form-group__email">
    <label class="vox-field-label" for="username" translate>login.email.label</label>
    <div class="vox-input-group">
      <input type="email" tabindex="1" id="username" [placeholder]="'login.email.placeholder' | translate"
        class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off" formControlName="username"
        autofocus />
    </div>
  </div>
  <div class="form-group from-group__password">
    <label class="vox-field-label" for="password" translate>login.password.label</label>
    <div class="vox-input-group">
      <input type="password" tabindex="2" id="password" [placeholder]="'login.password.placeholder' | translate"
        class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off" formControlName="password" />
    </div>
  </div>
  <div class="form-group text-box">
    <a href="/auth/forgot-password" translate>login.forgotPassword</a>
  </div>
  <button class="vox-btn vox-btn-primary" type="submit" [disabled]="isDisabled" translate>login.loginBtn</button>
</form>