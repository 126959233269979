import { Action } from "@ngrx/store";
import { State } from "../reducers";

export enum PartnerDataActionTypes {
  SavePartnerData = "[Partner Data] Save Partner Data"
}

export class SavePartnerData implements Action {
  readonly type = PartnerDataActionTypes.SavePartnerData;
  constructor(readonly partnerData: State) {}
}

export type PartnerDataActions = SavePartnerData;
