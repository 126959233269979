// Libs
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Models
import { Customer } from '../../models/customers.model';

// Store
import { CustomersState } from '../reducers';

export const selectCustomersState = createFeatureSelector<CustomersState>('customers');

export const selectCustomersStatus = createSelector(
  selectCustomersState,
  (state: CustomersState) => state.status
);
export const selectCustomers = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    if (state.status && state.status.customers) {
      return state.status.customers;
    }
  }
);
export const selectLoading = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.loading;
  }
);
export const selectLoadingCustomers = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.loading.customers;
  }
);
export const selectLoadingCustomer = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.loading.customer;
  }
);
export const selectError = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.error;
  }
);
export const selectErrorCustomers = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.error.customers;
  }
);
export const selectErrorCustomer = createSelector(
    selectCustomersState,
    (state: CustomersState) => {
      return state.status.error.customer;
  }
);

export const selectSuccessCustomers = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.success.customers;
  }
);
export const selectSuccessCustomer = createSelector(
  selectCustomersState,
  (state: CustomersState) => {
    return state.status.success.customer;
  }
);
