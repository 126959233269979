import { distinctUntilChanged, debounceTime, map, filter } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { PartnersFacade } from 'src/app/domains/partners/store/facade/partners.facade';
import { Partner, PartnersResponse, PartnersSearch, PartnersSearchFilters } from 'src/app/domains/partners/models/partners.model';
import { AgentsTableModalComponent } from '../agents-table-modal/agents-table-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_ROLE } from '@app/domains/user';

interface SortModel {
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  vat: string | undefined;
}

@Component({
  selector: 'vov-partners-table',
  templateUrl: './partners-table.component.html',
  styleUrls: ['./partners-table.component.scss']
})

export class PartnersTableComponent implements OnInit {
  @Output() onEditPartner = new EventEmitter<Partner>();
  @Output() onDeletePartner = new EventEmitter<string>();
  @Output() onAddLink = new EventEmitter<Partner>();
  public partners$: Observable<PartnersResponse>;
  public partnersList$;
  public loading$;
  public searchControl: UntypedFormControl = new UntypedFormControl('');
  public USER_ROLES = USER_ROLE;

  public pagination = {
    page: 0,
    empty: true,
    totalPages: 0
  };

  public sort: SortModel = {
    id: undefined,
    name: undefined,
    email: undefined,
    vat: undefined
  };
  public queryFilters: PartnersSearch = {};
  public options: PartnersSearch;


  constructor(
    private partnersFacade: PartnersFacade,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.partners$ = this.partnersFacade.partners$;
    this.partnersList$ = this.partnersFacade.partners$.pipe(map(p => p.content))
    this.loading$ = this.partnersFacade.loadingPartners$;
    const options = this.parseQueryParams(this.activatedRoute.snapshot.queryParams);
    this.getData();
    this.queryFilters = options;
    this.searchControl
      .valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe(() => {
        this.getData();
      });

    this.partners$.pipe(
      filter((p: PartnersResponse) => p.pageable ? true : false)
    ).subscribe(
        (p) => {
          this.pagination = {
            page: p.pageable.pageNumber,
            empty: p.empty,
            totalPages: p.totalPages
          }
        });
    this.getData();
  }

  editPartner(p: Partner) {
    this.onEditPartner.emit(p);
  }
  deletePartner(id: string) {
    this.onDeletePartner.emit(id);
  }
  impersonatePartner(id: string) { }

  inviteAdmin(id: string) {
    const ref = this.modalService.open(AgentsTableModalComponent, {
      windowClass: 'modal-agents'
    });
    ref.componentInstance.partnerId = id;
  }

  addLink(p: Partner) {
    this.onAddLink.emit(p);
  }

  // TODO: UNCOMMENT TO HAVE FILTERS LOGIC

  // onApplyFilters(filters: PartnersSearchFilters) {
  //   let options: PartnersSearch = {};
  //   Object.keys(filters).forEach((k: string) => {
  //     console.log('FILTERS', filters, k);
  //     switch (k) {
  //       case 'name':
  //         if (!filters[k]) break;
  //         options[k] = filters[k]
  //         console.log('OPTIONS K', options[k])
  //         break;
  //       case 'partner':
  //         if (!filters[k]) break;
  //         options['partner_id'] = filters[k].id;
  //         break;
  //       default:
  //         options[k] = filters[k];
  //     }
  //   });
  //   this.options = options;
  //   this.router.navigate([], {
  //     queryParams: options,
  //     relativeTo: this.activatedRoute
  //   });
  //   this.partnersFacade.getPartners(options);
  // }

  selectPageSize(event: any) {
    this.options = {...this.options, size: event, page: 0};
    this.partnersFacade.getPartners(this.options);
  }

  changeCurrentPage(event: any) {
    this.options = {...this.options, page: event};
    this.partnersFacade.getPartners(this.options);
  }

  parseQueryParams(p: Object): PartnersSearch {
    let options: PartnersSearch = {};
    Object.keys(p).forEach((k: string) => {
      switch (k) {
        case 'name':
        case 'email':
        case 'partner':
          options[k] = p[k];
          break;
        default:
          break;
      }
    });
    return options;
  }

  toggleSort(key: string) {
    const currentValue = this.sort[key];
    if (currentValue) {
      this.sort[key] = currentValue === 'asc' ? 'desc' : undefined;
    } else {
      this.sort[key] = 'asc';
    }
    this.getData();
  }

  getData() {
    let sort = [];
    Object.keys(this.sort).forEach(k => {
      if (this.sort[k] !== undefined) {
        sort.push(`${k},${this.sort[k]}`);
      }
    });

    // TODO: UNCOMMENT TO HAVE FILTERS LOGIC

    // const params: PartnersSearch = {
    //   ...this.options,
    //   page: this.pagination.page,
    //   sort: sort
    // };

    const params: PartnersSearch = {
      ...this.options,
      page: this.pagination.page,
      name: this.searchControl.value,
      sort: sort
    };
    this.partnersFacade.getPartners(params);
  }
}
