// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { IfUserRoleDirective } from './if-user-role/if-user-role.directive';
import { IfCurrentUserDirective } from './if-current-user/if-current-user.directive'
const DIRECTIVES = [
  IfUserRoleDirective,
  IfCurrentUserDirective
];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class SharedDirectivesModule {}
