// Models
import { User } from "../../models/user.model";

// Store
import { UserActionTypes, UserActions } from "../actions";

export interface State {
  user: User;
  users: User[];
  loading: {
    users: boolean,
    user: boolean
  },
  error: {
    users: string,
    user: string
  },
  success: {
    users: boolean,
    user: boolean
  }
}

export const initialState: State = {
  user: {} as any,
  users: [],
  loading: {
    users: false,
    user: false
  },
  error: {
    users: null,
    user: null
  },
  success: {
    users: null,
    user: null
  }
};

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
  case UserActionTypes.GetUser: {
    return {
      ...state,
      user: action.user
    };
  }
  case UserActionTypes.LanguageSelect: {
    return {
      ...state,
      user: {
        ...state.user,
        language: action.language
      }
    };
  }
  case UserActionTypes.GetUsers: {
    return {
      ...state,
      users: [],
      loading: { ...state.loading, users: true },
      success: { ...state.success, users: null },
      error: { ...state.error, users: null }
    };
  }
  case UserActionTypes.GetUsersSuccess: {
    return {
      ...state,
      loading: { ...state.loading, users: false },
      success: { ...state.success, users: true },
      users: action.users,
    };
  }
  case UserActionTypes.GetUsersError: {
    return {
      ...state,
      loading: { ...state.loading, users: false },
      success: { ...state.success, users: false },
      error: { ...state.error, users: action.error }
    };
  }

  case UserActionTypes.ActivateUser: {
    return {
      ...state,
      loading: { ...state.loading, user: true },
      success: { ...state.success, user: null },
      error: { ...state.error, user: null }
    };
  }
  case UserActionTypes.ActivateUserSuccess: {
    return {
      ...state,
      loading: { ...state.loading, user: false },
      success: { ...state.success, user: true }
    };
  }
  case UserActionTypes.ActivateUserError: {
    return {
      ...state,
      loading: { ...state.loading, user: false },
      success: { ...state.success, user: false },
      error: { ...state.error, user: action.error }
    };
  }

  case UserActionTypes.UpdateUserAvatar: {
    return {
      ...state,
      loading: { ...state.loading, user: true },
      success: { ...state.success, user: null },
      error: { ...state.error, user: null }
    };
  }
  case UserActionTypes.UpdateUserAvatarSuccess: {
    // add hash to force reload
    const avatarSrc = action.user.avatar ? `${action.user.avatar}?t=${new Date().getTime()}` : ''
    return {
      ...state,
      loading: { ...state.loading, user: false },
      success: { ...state.success, user: true },
      user: {
        ...action.user,
        avatar: avatarSrc
      },
    };
  }
  case UserActionTypes.UpdateUserAvatarError: {
    return {
      ...state,
      loading: { ...state.loading, user: false },
      success: { ...state.success, user: false },
      error: { ...state.error, user: action.error }
    };
  }
  case UserActionTypes.UpdateGlogalDriveLink: {
    return {
      ...state,
      loading: { ...state.loading, user: true },
      success: { ...state.success, user: null },
      error: { ...state.error, user: null }
    };
  }
  case UserActionTypes.UpdateGlogalDriveLinkSuccess: {
    // add hash to force reload
    return {
      ...state,
      loading: { ...state.loading, user: false },
      success: { ...state.success, user: true },
      user: {
        ...state.user,
        global_google_drive_link: action.data.value
      },
    };
  }
  case UserActionTypes.UpdateUserAvatarError: {
    return {
      ...state,
      loading: { ...state.loading, user: false },
      success: { ...state.success, user: false },
      error: { ...state.error, user: action.error }
    };
  }


  default:
    return state;
  }
}
