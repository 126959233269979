// Core
import { NgModule, Optional, SkipSelf } from '@angular/core';

// Libs
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Services
import * as fromInterceptors from './interceptors';
import * as fromService from './services';

// Store
import { authReducer } from './store/reducers';
import { AuthEffects } from './store/effects';

@NgModule({
  imports: [
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [...fromInterceptors.interceptors, ...fromService.services]
})
export class DomainAuthModule {
  constructor(@Optional() @SkipSelf() parentModule: DomainAuthModule) {
    if (parentModule) {
      throw new Error(
        'DomainAuthModule is already loaded. Import only in AppModule'
      );
    }
  }
}
