// Core
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

// Rxjs
import { Observable, of } from 'rxjs';
import { map, take, filter, switchMap } from 'rxjs/operators';

// Libs
import { Store, select } from '@ngrx/store';

// Store
import { User, USER_ROLE } from '@app/domains/user/models';
import { UserService } from '@app/domains/user/services/user.service';
import { AuthFacade } from '@app/domains/auth/store/facade';


@Injectable({
  providedIn: 'root'
})
export class CustomersGuard implements CanLoad {
  private token$

  constructor(
    private userService: UserService,
    private auth: AuthFacade,
    private router: Router) { }

  private checkUserRole() {
    return this.auth.token$.pipe(
      take(1),
      switchMap((t) => {
        const [token, user] = t.token.split(".");
        const email = JSON.parse(atob(user)).sub;
        return this.userService.getUser(email);
      }),
      map((user: User) => {
        const allowed = user.roles && [USER_ROLE.AGENT, USER_ROLE.ADMIN, USER_ROLE.SUPERVISOR].includes(user.roles[0]);
        if (!allowed) {
          this.router.navigate(['main']);
        }
        return allowed;
      })
    );
  }

  canLoad(): Observable<boolean> {
    return this.checkUserRole();
  }
}
