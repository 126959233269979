<div class="agent-form-container">
  <form *ngIf="form" [formGroup]="form">
    <div class="form-group">
      <label class="vox-field-label" translate>
        agents.admin-form.inputs.first-name.label
      </label>
      <div class="vox-input-group" [ngClass]="{
        'vox-input-group_invalid' : first_name.dirty && first_name.invalid,
        'vox-input-group_valid': first_name.dirty && first_name.valid
        }">
        <input formControlName="first_name" class="vox-text-field" type="text"
          [placeholder]="'agents.admin-form.inputs.first-name.placeholder' | translate">
        <i *ngIf="first_name.dirty && first_name.valid"
          class="vox-text-field__icon vox-text-field__icon_valid icon-small-check"></i>
        <i *ngIf="first_name.dirty && !first_name.valid" class="vox-text-field__icon icon-small-alert"></i>
        <span class="vox-input-group__error-message" translate>
          agents.admin-form.inputs.first-name.error
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="vox-field-label" translate>
        agents.admin-form.inputs.last-name.label
      </label>
      <div class="vox-input-group" [ngClass]="{
        'vox-input-group_invalid' : last_name.dirty && last_name.invalid,
        'vox-input-group_valid': last_name.dirty && last_name.valid
        }">
        <input formControlName="last_name" class="vox-text-field" type="text"
          [placeholder]="'agents.admin-form.inputs.last-name.placeholder' | translate">
        <i *ngIf="last_name.dirty && last_name.valid"
          class="vox-text-field__icon vox-text-field__icon_valid icon-small-check"></i>
        <i *ngIf="last_name.dirty && !last_name.valid" class="vox-text-field__icon icon-small-alert"></i>
        <span class="vox-input-group__error-message" translate>
          agents.admin-form.inputs.last-name.error
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="vox-field-label" translate>
        agents.admin-form.inputs.email.label
      </label>
      <div class="vox-input-group" [ngClass]="{'vox-input-group_invalid' : email.dirty && email.invalid}">
        <input formControlName="email" class="vox-text-field" type="email"
          [placeholder]="'agents.admin-form.inputs.email.placeholder' | translate">
        <i class="vox-text-field__icon vox-text-field__icon_valid ladda" [ngClass]="{
          'ladda-show': email.dirty && email.pending,
          'icon-small-check': email.dirty && email.valid}" [ladda]="email.pending" data-spinner-color="grey"
          data-style="zoom-in"></i>
        <i *ngIf="email.dirty && !email.valid" class="vox-text-field__icon icon-small-alert"></i>
        <span *ngIf="email.errors && email.errors.emailExists" class="vox-input-group__error-message" translate>
          agents.admin-form.inputs.email.error-exists
        </span>
        <span *ngIf="!(email.errors && email.errors.emailExists)" class="vox-input-group__error-message" translate>
          agents.admin-form.inputs.email.error
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="vox-field-label" translate>
        agents.admin-form.inputs.phone.label
      </label>
      <div class="vox-input-group" [ngClass]="{'vox-input-group_invalid' : phone.dirty && phone.invalid}">
        <vox-phone-text-field maxlength="15" state="it" formControlName="phone"></vox-phone-text-field>
        <span class="vox-input-group__error-message" translate>
          agents.admin-form.inputs.phone.error
        </span>
      </div>
    </div>
  </form>
</div>
