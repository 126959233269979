<div class="customers-table__main">
  <div class="customers-table__actions">
    <div class="customer-search">
      <vov-filter-customers (applyFilters)="onApplyFilters($event)" [startingFilters]="queryFilters">
      </vov-filter-customers>
    </div>
  </div>
  <div class="customers-table">
    <table>
      <thead>
        <tr>
          <td translate>customers.table.header.name</td>
          <td translate>customers.table.header.created</td>
          <td translate>customers.table.header.status</td>
          <td *ifUserRole="ROLES.SUPERVISOR" translate>customers.table.header.partner</td>
          <td translate>customers.table.header.actions</td>
        </tr>
      </thead>
      <tbody class="loading" *ngIf="loading$ | async">
        <tr *ngFor="let i of [1,2,3,4,5]">
          <td *ifUserRole="ROLES.SUPERVISOR" colspan="5">
            <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
          </td>
          <td *ifUserRole="[ROLES.ADMIN, ROLES.AGENT]" colspan="4">
            <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ |async) && (customers$ | async).content.length === 0">
        <tr>
          <td *ifUserRole="ROLES.SUPERVISOR" colspan="5" translate>customers.table.empty</td>
          <td *ifUserRole="[ROLES.ADMIN, ROLES.AGENT]" colspan="4" translate>customers.table.empty</td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ | async)">
        <tr *ngFor="let customer of (customers$ | async).content">
          <td>{{customer.name}}</td>
          <td>{{customer.creation_date | localizedDate:datePattern}}</td>
          <td>{{'customers.filters.statuses.' + customer.status | translate}}</td>
          <td *ifUserRole="ROLES.SUPERVISOR">{{customer.partner.name}}</td>
          <td class="customer-actions">

            <i class="icon-small-delivery disabled"
              *ngIf="!customer.remote_access"
              [ngbTooltip]="'customers.table.actions.login-cp-info' | translate">
            </i>

            <i class="icon-small-delivery"
              *ngIf="customer.remote_access"
              (click)="loginCustomer(customer)"
              [ngbTooltip]="'customers.table.actions.login-cp' | translate">
            </i>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <vov-table-pagination (onSelectPageSize)="selectPageSize($event)" (onChangeCurrentPage)="changeCurrentPage($event)" [totalPages]="pagination.totalPages" [pagination]="pagination"></vov-table-pagination>
</div>
