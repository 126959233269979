import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentsFacade } from 'src/app/domains/agents/store/facade/agents.facade';
import { Agent } from '@app/domains/agents/models/agents.model';
import { ConfirmationPopupService } from '@app/shared/components/confirmation-popup/confirmation-popup.service';
import { AddAgentPopupComponent } from '../../components/add-agent-popup/add-agent-popup.component';

@Component({
  selector: 'vov-agents-page',
  templateUrl: './agents-page.component.html',
  styleUrls: ['./agents-page.component.scss']
})

export class AgentsPageComponent implements OnInit {
  error$: Observable<string>;

  constructor(
    private agentsFacade: AgentsFacade,
    private modal: NgbModal
  ) { }

  ngOnInit() {
    this.error$ = this.agentsFacade.errorAgent$;
  }

  addAgent() {
    this.modal.open(AddAgentPopupComponent);
  }

  editAgent(agent: Agent) {
    this.agentsFacade.updateAgent(agent);
  }

  deleteAgent(id: string) {
    this.agentsFacade.deleteAgent(id);
  }
}
