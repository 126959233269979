import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthFacade } from '@app/domains/auth';
import { USER_ROLE, UserFacade } from '@app/domains/user';
import { UserService } from '@app/domains/user/services/user.service';
import { User } from '@app/domains/user';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShowPrivateLinkGuard implements CanLoad {
  private canOpenPage: boolean;

  constructor(
    private usersFacade: UserFacade,
    private router: Router,
    private userService: UserService,
    private auth: AuthFacade
  ) { }

  checkPartnerConfig() {
    return this.auth.token$.pipe(
      take(1),
      switchMap((t) => {
        const [token, user] = t.token.split(".");
        const email = JSON.parse(atob(user)).sub;
        return this.userService.getUser(email);
      }),
      map((user: User) => {
        if ([USER_ROLE.AGENT].includes(user.roles[0])) {
          if (!user.partner.show_private_link_to_agents) {
            this.router.navigate(['main']);
          }
        } else {
          return true;
        }
        return user.partner.show_private_link_to_agents;
      })
    );
  }

  canLoad(): Observable<boolean> {
    return this.checkPartnerConfig();
  }

}
