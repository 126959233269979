import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, timer, of } from 'rxjs';
import { switchMap, map, take, catchError } from 'rxjs/operators';
import { User, USER_ROLE } from '@app/domains/user/models/user.model';
import { Agent } from '@app/domains/agents/models/agents.model';
import { UserService } from '@app/domains/user/services/user.service';
import { AgentsFacade } from '@app/domains/agents/store/facade/agents.facade';

@Component({
  selector: 'vov-edit-agent-form',
  templateUrl: './edit-agent-form.component.html',
  styleUrls: ['./edit-agent-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditAgentFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public loading$: Observable<boolean>;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private agentsFacade: AgentsFacade
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email], this.validateEmail(this.userService)),
      phone: new UntypedFormControl('', [Validators.required])
    });
    this.loading$ = this.agentsFacade.loadingAgent$;
  }

  validateEmail(userService: UserService) {
    return (input: UntypedFormControl) => {
      return timer(500).pipe(
        switchMap(() => userService.getUser(input.value)),
        map(res => {
          // user found -> duplicate email
          return res ? { emailExists: true } : null;
        }),
        catchError(err => {
          // user not found -> valid
          return err.status === 404 ? of(null) :  of({ emailExists: true });
        })
      );
    };
  }

  get valid() {
    return this.form && this.form.valid;
  }
  get dirty() {
    return this.form && this.form.dirty;
  }
  get first_name() {
    return this.form && this.form.get('first_name');
  }
  get last_name() {
    return this.form && this.form.get('last_name');
  }
  get email() {
    return this.form && this.form.get('email');
  }
  get phone() {
    return this.form && this.form.get('phone');
  }

  public saveAgent(partnerId?: string){
    const payload: Agent = {
      ...this.form.value,
      roles: [USER_ROLE.AGENT]
    }
    this.agentsFacade.addAgent(payload, partnerId);
  }

}
