<div class="modal-header">
  <h5 class="modal-title" translate>customers.controlpanel-popup.title</h5>
  <button type="button" class="vox-btn vox-btn-secondary-dark-grey" data-dismiss="modal" aria-label="Close" (click)="close()" translate>
    customers.controlpanel-popup.close
  </button>
</div>
<div class="modal-body">
  <div class="content loading" [ngClass]="{loading: loading}">
    <iframe #iframe *ngIf="!loading && !error" frameborder="0" [src]="iframeUrl" (error)="iframeError()"></iframe>

    <div class="vox-inline-message vox-inline-message_light-grey m-4 ml-auto mr-auto" *ngIf="error && !loading">
      <div><span translate>customers.controlpanel-popup.error</span></div>
      <i class="icon-small-close" (click)="close()"></i>
    </div>

  </div>
</div>
