<div class="main-container">
  <div class="title-container">
    <h2 class="vox-heading-2 title-container__title" translate>onboarding.thanks.title</h2>
  </div>

  <div class="button-box">
    <button class="vox-btn vox-btn-primary vox-btn--back" routerLink="/partner-contact/3"
      translate>onboarding.thanks.buttonBack</button>

    <button class="vox-btn vox-btn-primary vox-btn--next" translate>onboarding.thanks.buttonNext</button>
  </div>

  <div class="skeleton-container" *ngIf="loading">
    <div class="input group">
      <ngx-skeleton-loader [theme]="{
          margin: '0',
          'margin-top': '30px',
          width: '255px',
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</div>