import { environment } from "@src/environments/environment";

import { Api } from "../models";

const apiEndpoint = environment.apiEndpoint;

export const api: Api = {
  auth: {
    login: `${apiEndpoint}/api/v1/auth`
  }
  // pbxes: `${apiEndpoint}/api/v1/pbxes`,
  // users: `${apiEndpoint}/api/v1/users`,
  // tts: `${apiEndpoint}/api/v1/pbxes/tts/voices`,
  // account: `${apiEndpoint}/api/account`
};
