<div class="main-container">
  <div class="title-container">
    <h2 class="vox-heading-2 title-container__title" translate>onboarding.data2.title</h2>
  </div>

  <form class="main-container__form-box" [formGroup]="frm" (ngSubmit)="onSubmit()">
    <div class="form-group form-group__companyName">
      <label class="vox-field-label" for="companyName" translate>onboarding.data2.companyName.label</label>
      <div class="vox-input-group" [ngClass]="{ 'vox-input-group_invalid': isInvalid('companyName') }">
        <input type="text" tabindex="1" id="companyName"
          [placeholder]="'onboarding.data2.companyName.label' | translate" formControlName="companyName"
          class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off" autofocus />
        <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
        <span class="vox-input-group__error-message" translate>onboarding.data2.companyName.error</span>
      </div>
    </div>

    <div class="fields-box">
      <div class="form-group form-group__country">
        <label class="vox-field-label" for="country" translate>onboarding.data2.country.label</label>
        <div class="vox-input-group" [ngClass]="{ 'vox-input-group_invalid': isInvalid('country') }">
          <input type="text" tabindex="1" id="country"
            [placeholder]="'onboarding.data2.country.placeholder' | translate" formControlName="country"
            class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off" autofocus />
          <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
          <span class="vox-input-group__error-message" translate>onboarding.data2.country.error</span>
        </div>
      </div>

      <div class="form-group form-group__city">
        <label class="vox-field-label" for="city" translate>onboarding.data2.city.label</label>
        <div class="vox-input-group" [ngClass]="{ 'vox-input-group_invalid': isInvalid('city') }">
          <input type="text" tabindex="1" id="city" [placeholder]="'onboarding.data2.city.placeholder' | translate"
            formControlName="city" class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off"
            autofocus />
          <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
          <span class="vox-input-group__error-message" translate>onboarding.data2.city.error</span>
        </div>
      </div>
    </div>

    <div class="form-group form-group__companyWebsite">
      <label class="vox-field-label" for="companyWebsite" translate>onboarding.data2.companyWebsite.label</label>
      <div class="vox-input-group" [ngClass]="{ 'vox-input-group_invalid': isInvalid('companyWebsite') }">
        <input type="text" tabindex="1" id="companyWebsite"
          [placeholder]="'onboarding.data2.companyWebsite.placeholder' | translate" formControlName="companyWebsite"
          class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off" autofocus />
        <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
        <span class="vox-input-group__error-message" translate>onboarding.data2.companyWebsite.error</span>
      </div>
    </div>

    <div class="form-group form-group__companyType">
      <label class="vox-field-label" for="companyType" translate>onboarding.data2.companyType.label</label>
      <div class="vox-input-group companyType-dropdown">
        <div class="vox-dropdown" ngbDropdown display="dynamic" placement="bottom-left">
          <div class="vox-text-field text-left btn" id="companyType-dropdown" ngbDropdownToggle>
            {{"onboarding.data2.companyType." + frm.controls.companyType.value.locale | translate}}</div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem *ngFor="let type of mainTypes" (click)="setType(type)">
              {{"onboarding.data2.companyType." + type.locale | translate}}</div>
          </div>
        </div>

        <i class="vox-text-field__icon vox-text-field__icon_valid"></i><span class="vox-input-group__error-message"
          translate>onboarding.data2.companyType.error</span>
      </div>
    </div>

    <div class="button-box">
      <button routerLink="/partner-contact/1" class="vox-btn vox-btn-primary vox-btn--back"
        translate>onboarding.data2.buttonBack</button>

      <button class="vox-btn vox-btn-primary vox-btn--next" type="submit" [disabled]="!frm.valid"
        translate>onboarding.data2.buttonNext</button>
    </div>
  </form>

  <div class="skeleton-container" *ngIf="loading">
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', width: '145px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="skeleton-group">
      <div class="input group skeleton-flex">
        <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '90px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{
            margin: '0',
            height: '56px',
            width: '180px'
          }"></ngx-skeleton-loader>
      </div>
      <div class="input group skeleton-flex">
        <ngx-skeleton-loader [theme]="{
            margin: '0',
            'margin-left': '65px',
            'margin-top': '10px',
            width: '80px'
          }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{
            margin: '0',
            'margin-left': '65px',
            height: '56px',
            width: '180px'
          }"></ngx-skeleton-loader>
      </div>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '165px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '180px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{
          margin: '0',
          'margin-top': '30px',
          width: '130px',
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</div>