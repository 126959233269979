import { ActionReducerMap } from "@ngrx/store";
import { reducer, State } from "./customers.reducer";

export interface CustomersState {
  status: State;
}

export const customersReducer: ActionReducerMap<CustomersState> = {
  status: reducer
};

export * from "./customers.reducer";
