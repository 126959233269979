import { ActionReducerMap } from "@ngrx/store";
import { reducer, State } from "./agents.reducer";

export interface AgentsState {
  status: State;
}

export const agentsReducer: ActionReducerMap<AgentsState> = {
  status: reducer
};

export * from "./agents.reducer";
