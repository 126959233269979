<form [formGroup]="form" class="add-edit-link-form">
    <div class="vox-input-group vox-input-group_dense" [ngClass]="{'vox-input-group_invalid': form.get('google_drive_link').invalid}">
        <input formControlName="google_drive_link" class="vox-text-field" type="text">
        <i class="vox-text-field__icon icon-small-alert"></i>
        <i class="vox-text-field__icon icon-small-check"></i>
        <span class="vox-input-group__error-message" translate>partner-hub.form.invalid</span>
    </div>
    <div class="link__instruction">
        <button type="button" class="info-btn" (click)="collapse.toggle()"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        <span class="icon-small-info-outline"></span>
        <span translate>partner-hub.form.link-instruction.label</span>
        </button>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="card">
                <div class="card-body">
                    <div class="link__instruction-item">
                        <label [innerHTML]="'partner-hub.form.link-instruction.first-link.label' | translate"></label>
                        <p [innerHTML]="'partner-hub.form.link-instruction.first-link.url' | translate"></p>
                    </div>
                    <div class="link__instruction-item">
                        <label [innerHTML]="'partner-hub.form.link-instruction.second-link.label' | translate"></label>
                        <p [innerHTML]="'partner-hub.form.link-instruction.second-link.url' | translate"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
