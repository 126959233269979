<div class="agents-table-modal__main">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
    <i class="icon-small-close"></i>
  </button>
  <div class="agents-table-modal__title">
    <div class="vox-heading-3" translate>partners.agents-modal.table.title</div>
    <button (click)="inviteAdmin()" class="vox-btn vox-btn-secondary"
      translate>partners.agents-modal.table.add-admin</button>
  </div>
  <div class="agents-table-modal__actions">
    <div class="agent-search">
      <i class="icon-small-search"></i>
      <input [formControl]="searchControl" class="agent-search-input"
        [placeholder]="'agents.actions.search-placeholder' | translate" />
    </div>
  </div>
  <div class="agents-table-modal">
    <table>
      <thead>
        <tr>
          <td class="sortable" (click)="toggleSort('name')">
            <span translate>partners.agents-modal.table.header.name</span>
            <i *ngIf="sort.name" class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.name === 'asc' }"></i>
          </td>
          <td class="sortable" (click)="toggleSort('email')">
            <span translate>partners.agents-modal.table.header.email</span>
            <i *ngIf="sort.email" class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.email === 'asc' }"></i>
          </td>
          <td translate>partners.agents-modal.table.header.phone</td>
          <td translate>
            <span translate>partners.agents-modal.table.header.active</span>
          </td>
          <td>
            <span translate>partners.agents-modal.table.header.admin</span>
          </td>
          <td translate>partners.agents-modal.table.header.actions</td>
        </tr>
      </thead>
      <tbody class="loading" *ngIf="loading$ | async">
        <tr *ngFor="let i of [1,2,3,4,5]">
          <td colspan="6">
            <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ |async) && (displayedAgents$ | async).length === 0">
        <tr>
          <td colspan="6" translate>partners.agents-modal.table.empty</td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ | async)">
        <tr *ngFor="let agent of (displayedAgents$ | async)[pagination.page]; let idx = index">
          <td>
            <span>{{agent.first_name}} {{agent.last_name}}</span>
            <span *ifCurrentUser="{user:agent}" class="vox-chip vox-chip_royal-blue" translate>app.you</span>
          </td>
          <td>{{agent.email}}</td>
          <td>{{agent.phone}}</td>
          <td>
            <i *ngIf="!agent.enabled" class="icon-small-alert"
              [ngbTooltip]="'partners.agents-modal.table.user-inactive-info' | translate">
            </i>
            <i *ngIf="agent.enabled" class="icon-small-check-circle"></i>
          </td>
          <td class="center">
            <span class="admin-check" *ifUserRole="[ROLES.AGENT, ROLES.SUPERVISOR]">
              <input class="vox-checkbox admin-check__input" [checked]="canBeAgent(agent)" type="checkbox" disabled>
            </span>
          </td>
          <td class="agent-actions">
            <i *ngIf="!agent.enabled && !resentLoading[agent.email]" class="icon-small-mail"
              [ngbTooltip]="'partners.agents-modal.table.actions.resend' | translate"
              (click)="resendEmail(agent.email)">
            </i>
            <i *ngIf="resentLoading[agent.email] === 'error'" class="icon-small-close"
              [ngbTooltip]="'partners.agents-modal.table.status.resend-error' | translate"></i>
            <i *ngIf="resentLoading[agent.email] === 'sent'" class="icon-small-check"
              [ngbTooltip]="'partners.agents-modal.table.status.resend-success' | translate"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav *ngIf="!(loading$ | async) && (displayedAgents$ | async).length !== 0 && pages.length !== 1"
    class="agents-table-modal__pagination">
    <button [disabled]="prevDisabled()" (click)="prevPage()" class="icon-small-arrow-left"></button>
    <ul class="list">
      <li *ngFor="let p of pages" [ngClass]="{ 'list__item': true, 'list__item_current': p === pagination.page }">
        <a (click)="goToPage(p)">{{p+1}}</a>
      </li>
    </ul>
    <button [disabled]="nextDisabled()" (click)="nextPage()" class="icon-small-arrow-right"></button>
  </nav>
</div>
