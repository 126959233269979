// Core
import { Injectable } from '@angular/core';

// Libs
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

// Models
import { Agent, Admin } from '../../models/agents.model';
import { PaginationOptions } from '@app/core/config/models/api.model';
import { USER_ROLE } from '@app/domains/user/models/user.model';

// Store
import { UserFacade } from '@app/domains/user/store/facade';
import { State } from '../reducers';
import { GetAgents, DeleteAgent, AddAgent, UpdateAgent, AddAdmin } from '../actions';
import {
  selectAgents,
  selectLoading,
  selectLoadingAgents,
  selectLoadingAgent,
  selectError,
  selectErrorAgents,
  selectErrorAgent,
  selectSuccessAgents,
  selectSuccessAgent
} from '../selectors';

@Injectable({
  providedIn: 'root'
})
export class AgentsFacade {
  agents$ = this.store.pipe(select(selectAgents));
  loading$ = this.store.pipe(select(selectLoading));
  loadingAgents$ = this.store.pipe(select(selectLoadingAgents));
  loadingAgent$ = this.store.pipe(select(selectLoadingAgent));
  error$ = this.store.pipe(select(selectError));
  errorAgents$ = this.store.pipe(select(selectErrorAgents));
  errorAgent$ = this.store.pipe(select(selectErrorAgent));
  successAgents$ = this.store.pipe(select(selectSuccessAgents));
  successAgent$ = this.store.pipe(select(selectSuccessAgent));

  constructor(
    private store: Store<State>,
    private userFacade: UserFacade
  ) { }

  private checkRole(u) {
    return [USER_ROLE.ADMIN, USER_ROLE.AGENT].includes(u.roles[0]);
  }

  getAgents() {
    this.userFacade.user$.pipe(filter(u => !!u.id))
      .subscribe(u => {
        if (this.checkRole(u)) {
          this.store.dispatch(new GetAgents({ partnerId: u.partner.id }));
        }
      });
  }

  // Special method to get the agents for the supervisor (to use in partners)
  // to get a list of users
  getAgentsWithId(id: string) {
    this.store.dispatch(new GetAgents({ partnerId: id }))
  }

  deleteAgent(id: string) {
    this.userFacade.user$.pipe(filter(u => !!u.id))
      .subscribe(u => {
        if (this.checkRole(u)) {
          this.store.dispatch(new DeleteAgent({ id, partnerId: u.partner.id }));
        }
      });
  }

  addAgent(agent: Agent, partnerId?: string) {
    if (partnerId) {
      this.store.dispatch(new AddAgent({ agent, partnerId }));
    } else {
      this.userFacade.user$
        .pipe(filter(u => !!u.id))
        .subscribe(u => {
          if (this.checkRole(u)) {
            this.store.dispatch(new AddAgent({ agent, partnerId: u.partner.id }));
          }
        });
    }
  }

  addAdmin(admin: Admin, partnerId: string) {
    this.store.dispatch(new AddAdmin({ admin, partnerId }));
  }


  updateAgent(agent: Agent) {
    this.userFacade.user$.pipe(filter(u => !!u.id))
      .subscribe(u => {
        if (this.checkRole(u)) {
          this.store.dispatch(new UpdateAgent({ agent, partnerId: u.partner.id }));
        }
      });
  }

}
