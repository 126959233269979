<div class="partners-filter__container-outer">
  <form [formGroup]="form" class="partners-filter__container-inner" (ngSubmit)="emitFilters()">
    <div class="partners-filter__filter" *ngFor="let used of usedFilters; let uIdx = index">
      <button type="button" class="partners-filter__filter-remove  vox-btn vox-btn-secondary-grey"
        (click)="removeFilter(uIdx)" [ngClass]="{'hidden': usedFilters.length === 1}">
        <i class="partners-filter__filter-remove_symbol icon-small-remove">
        </i>
      </button>


      <div ngbDropdown class="d-inline-block partners-filter__filter-dropdown">
        <button type="button" class="vox-btn vox-btn-secondary partners-filter__filter-dropdown_btn"
          id="dropdownBasic1" ngbDropdownToggle
          [disabled]="availableFilters.length < 1">{{'partners.filters.' + used | translate}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button type="button" ngbDropdownItem class="partners-filter__filter-dropdown_item"
            *ngFor="let filter of availableFilters; let idx = index" (click)="updateFilters(idx, uIdx)">
            {{'partners.filters.' + filter | translate}}
          </button>
        </div>
        <div class="partners-filter__filter-input" [ngSwitch]="used">
          <div class="partners-filter__filter-input_name vox-input-group vox-input-group_dense">
            <input class="vox-text-field" type="text" formControlName="name" *ngSwitchCase="'name'"
              (keydown.enter)="$event.preventDefault();emitFilters();">
          </div>
          <div class="partners-filter__filter-input_name vox-input-group vox-input-group_dense">
            <input class="vox-text-field" type="text" formControlName="email" *ngSwitchCase="'email'"
              (keydown.enter)="$event.preventDefault();emitFilters();">
          </div>
          <div class="partners-filter__filter-input_name vox-input-group vox-input-group_dense">
            <input class="vox-text-field" type="text" formControlName="partner_id" *ngSwitchCase="'partner_id'"
              (keydown.enter)="$event.preventDefault();emitFilters();">
          </div>
        </div>
      </div>
    </div>
    <div class="partners-filter__filter-add_container">
      <button type="button" class="vox-btn vox-btn-secondary partners-filter__filter-add" (click)="addFilter(0)"
        [disabled]="availableFilters.length < 1">
        <i class="partners-filter__filter-add_symbol icon-small-add">
        </i>
      </button>
      <button type="submit" class="vox-btn vox-btn-secondary partners-filter__filter-apply">
        {{'partners.actions.apply-filters' | translate}}
      </button>
      <button type="button" class="ml-2 btn btn-link partners-filter__filter-clear" (click)="resetFilters()">
        {{'partners.actions.clear-filters' | translate}}
      </button>
    </div>
  </form>
</div>