import { ActionReducerMap } from "@ngrx/store";
import { reducer, State } from "./partners.reducer";

export interface PartnersState {
  status: State;
}

export const partnersReducer: ActionReducerMap<PartnersState> = {
  status: reducer
};

export * from "./partners.reducer";
