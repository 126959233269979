// Core
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Environments
import { environment } from 'src/environments/environment';

// Models
import { USER_ROLE } from '@app/domains/user/models/user.model';
import { Agent } from '../models/agents.model';
import { PaginationOptions } from '@app/core/config/models/api.model';


@Injectable()
export class AgentsService {
  constructor(private http: HttpClient) { }

  getAgents(partnerId: string): Observable<Agent[]> {
    return this.http.get<Agent[]>(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/users`);
  }

  getAgent(id: string, partnerId: string) {
    return this.http.get(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/users/${id}`);
  }

  deleteAgent(id: string, partnerId: string) {
    return this.http.delete(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/users/${id}`);
  }

  addAgent(a: Agent, partnerId: string) {
    return this.http.post(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/users/invite`, a);
  }

  updateAgent(p: Agent, partnerId: string) {
    return this.http.put(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/users/${p.email}`, p);
  }

  resendEmail(email: string) {
    return this.http.post(`${environment.apiEndpoint}/api/v1/users/reinvite/${email}`, {});
  }

}
