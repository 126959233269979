import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { I18nService } from "@app/core/i18n";
import { environment } from "@src/environments/environment";

import { UserService } from "@app/domains/user/services/user.service";
import { ResetPasswordRequest } from "@app/domains/user/models/user.model";
import { TranslateService } from "@ngx-translate/core";
import { ContactPhone, CONTACT_PHONES } from '@app/shared/contact-phones.constant';

@Component({
  selector: "vov-forgot-password-page",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class AuthForgotPasswordComponent implements OnInit {
  public isLoading = false;
  public langs: any[];
  public currentLang: string;
  public restoreResponse: string;
  public resetResponse: string;
  public resetToken: string;
  currentWidth: string;

  public phoneNumbers: ContactPhone[] = CONTACT_PHONES;
  public selectedPhoneNumber: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private i18nService: I18nService,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.i18nService.language;
    this.langs = environment.supportedLanguages;
    this.route.queryParams.subscribe((params) => {
      this.resetToken = params.token ? params.token : null;
    });

    this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    window.onresize = () => {
      this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    };

    this.selectedPhoneNumber = this.phoneNumbers[0];
  }

  goToWebsite() {
    let url = this.translate.instant(`auth.right.url`);
    window.open(url, "_blank");
  }

  onChangeLanguage(event: string) {
    this.i18nService.language = event;
  }

  onRestoreAccount($event: { email: string }) {
    this.isLoading = true;
    this.userService.forgottenPassword($event.email).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(["auth/login"], {
          state: { message: "forgot-password.success" },
        });
      },
      () => {
        this.isLoading = false;
        this.restoreResponse = "forgot-password.error";
      }
    );
  }

  onResetPassword(event: ResetPasswordRequest) {
    this.isLoading = true;
    this.userService
      .resetPassword({ ...event, token: this.resetToken })
      .subscribe(
        () => {
          this.isLoading = false;
          this.router.navigate(["auth/login"], {
            state: { message: "reset-password.success" },
          });
        },
        () => {
          this.isLoading = false;
          this.resetResponse = "reset-password.error";
        }
      );
  }

  onSelectPhoneNumber(phoneNumber: string) {
    this.selectedPhoneNumber = phoneNumber;
  }
}
