import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PartnersFacade } from '@app/domains/partners/store/facade/partners.facade';
import { Partner } from '@app/domains/partners/models/partners.model';
import { EditPartnerFormComponent } from '@app/shared/components/edit-partner-form/edit-partner-form.component';

@Component({
  selector: 'vov-add-partner-popup',
  templateUrl: './add-partner-popup.component.html',
  styleUrls: ['./add-partner-popup.component.scss']
})
export class AddPartnerPopupComponent implements OnInit {
  public partner: Partner;
  @ViewChild(EditPartnerFormComponent) public form: EditPartnerFormComponent;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.partner = {
      name: '',
      email: '',
      vat: '',
      contacts: null,
      address: null
    };
  }

  addPartner() {
    this.form.savePartner();
  }

  close() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
