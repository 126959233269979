// Store
import {
  OnboardingActionTypes,
  OnboardingActions
} from "../actions/onboarding.action";

// Models
import { PartnerData } from "src/app/domains/partner-data/models/partner-data.model";
import { CompanyData } from "src/app/domains/company-data/models/company-data.model";
import { CompanyOtherData } from "src/app/domains/company-other-data/models/company-other-data.model";

export interface State {
  partner_data: PartnerData;
  company_data: CompanyData;
  company_other_data: CompanyOtherData;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  partner_data: null,
  company_data: null,
  company_other_data: null,
  loading: false,
  error: null
};

export function reducer(state = initialState, action: OnboardingActions) {
  switch (action.type) {
    case OnboardingActionTypes.SendPartner:
      return {
        ...state,
        full_name: action.payload.full_name,
        email: action.payload.email,
        phone: action.payload.phone,
        company_name: action.payload.company_name,
        country: action.payload.country,
        city: action.payload.city,
        company_website: action.payload.company_website,
        company_type: action.payload.company_type,
        telecomunications: action.payload.telecomunications,
        company_size: action.payload.company_size,
        subscriptions: action.payload.subscriptions,
        companies_size: action.payload.companies_size,
        customers_ready: action.payload.customers_ready,
        loading: true
      };
    case OnboardingActionTypes.SendPartnerSuccess:
      return {
        ...state,
        full_name: action.payload.full_name,
        email: action.payload.email,
        phone: action.payload.phone,
        company_name: action.payload.company_name,
        country: action.payload.country,
        city: action.payload.city,
        company_website: action.payload.company_website,
        company_type: action.payload.company_type,
        telecomunications: action.payload.telecomunications,
        company_size: action.payload.company_size,
        subscriptions: action.payload.subscriptions,
        companies_size: action.payload.companies_size,
        customers_ready: action.payload.customers_ready,
        loading: false
      };
    case OnboardingActionTypes.SendPartnerFailure:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    default:
      break;
  }
}
