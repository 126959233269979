import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { UserFacade } from '@app/domains/user/store/facade/user.facade';
import { User } from '@app/domains/user/models/user.model';
import { State } from '../reducers/agents.reducer';
import { PaginationOptions } from '@app/core/config/models/api.model';
import { Agent, Admin } from '../../models/agents.model';
import { AgentsService } from '../../services/agents.service';
import { selectAgentsStatus } from '../selectors/agents.selectors';
import {
  AgentsActionTypes,
  GetAgents,
  GetAgentsSuccess,
  GetAgentsError,
  DeleteAgent,
  DeleteAgentSuccess,
  DeleteAgentError,
  AddAgent,
  AddAgentSuccess,
  AddAgentError,
  AddAdmin,
  AddAdminSuccess,
  AddAdminError,
  UpdateAgent,
  UpdateAgentSuccess,
  UpdateAgentError
} from '../actions';

@Injectable()
export class AgentsEffects {

  @Effect()
  getAgents$ = this.actions$.pipe(
    ofType(AgentsActionTypes.GetAgents),
    map((action: GetAgents) => action.payload),
    switchMap((payload:{partnerId: string}) => {
      return this.service.getAgents(payload.partnerId).pipe(
        map((response: Agent[] ) => new GetAgentsSuccess({ agents: response })),
        catchError(error => of(new GetAgentsError({ error })))
      );
    })
  );

  @Effect()
  deleteAgent$ = this.actions$.pipe(
    ofType(AgentsActionTypes.DeleteAgent),
    map((action: DeleteAgent) => action.payload),
    switchMap((payload: {id: string, partnerId: string}) => {
      return this.service.deleteAgent(payload.id, payload.partnerId).pipe(
        map((response: any ) => new DeleteAgentSuccess()),
        catchError(error => of(new DeleteAgentError({ error })))
      );
    })
  );

  @Effect()
  deleteAgentSuccess$ = this.actions$.pipe(
    ofType(AgentsActionTypes.DeleteAgentSuccess),
    withLatestFrom(this.userFacade.user$),
    map((args: [DeleteAgentSuccess, User]) => {
      return new GetAgents({ partnerId: args[1].partner.id });
    })
  );


  @Effect()
  addAgent$ = this.actions$.pipe(
    ofType(AgentsActionTypes.AddAgent),
    map((action: AddAgent) => action.payload),
    switchMap((payload: {agent: Agent, partnerId: string}) => {
      return this.service.addAgent(payload.agent, payload.partnerId).pipe(
        map((response: Agent) => new AddAgentSuccess({ agent: response})),
        catchError(error => of(new AddAgentError({ error })))
      );
    })
  );

  @Effect()
  addAgentSuccess$ = this.actions$.pipe(
    ofType(AgentsActionTypes.AddAgentSuccess),
    withLatestFrom(this.userFacade.user$),
    map((args: [AddAgentSuccess, User]) => {
      return new GetAgents({ partnerId: args[1].partner.id });
    })
  );

  @Effect()
  addAdmin$ = this.actions$.pipe(
    ofType(AgentsActionTypes.AddAdmin),
    map((action: AddAdmin) => action.payload),
    switchMap((payload: {admin: Admin, partnerId: string}) => {
      return this.service.addAgent(payload.admin, payload.partnerId).pipe(
        map((response: Admin) => new AddAdminSuccess({ admin: response})),
        catchError(error => of(new AddAdminError({ error })))
      );
    })
  );

  
  @Effect()
  updateAgent$ = this.actions$.pipe(
    ofType(AgentsActionTypes.UpdateAgent),
    map((action: AddAgent) => action.payload),
    switchMap((payload:{agent: Agent, partnerId: string} ) => {
      return this.service.updateAgent(payload.agent, payload.partnerId).pipe(
        map((response: Agent) => new UpdateAgentSuccess({ agent: response})),
        catchError(error => of(new UpdateAgentError({ error })))
      );
    })
  );

  @Effect()
  updateAgentSuccess$ = this.actions$.pipe(
    ofType(AgentsActionTypes.UpdateAgentSuccess),
    withLatestFrom(this.userFacade.user$),
    map((args: [UpdateAgentSuccess, User]) => {
      return new GetAgents({ partnerId: args[1].partner.id });
    })
  );



  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private userFacade: UserFacade,
    private service: AgentsService
  ) {}
}
