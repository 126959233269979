import { Injectable } from "@angular/core";
import { Intercom } from "ng-intercom";
import { environment } from '@src/environments/environment';

@Injectable({ providedIn: "root" })
export class IntercomChatService {
  constructor(public intercom: Intercom) { }

  show() {
    this.intercom.show();
  }

  hide() {
    this.intercom.hide();
  }

  showNewMessage(message: string) {
    this.intercom.showNewMessage(message);
  }

  loginUser(name, email) {
    this.intercom.update({
      name: name,
      email: email
    });
  }

  /**
   * Boot intercom
   * @param options Intercom options and data (name, email, id, options)
   * https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
   */
  boot(options: Object) {
    this.intercom.boot({
      app_id: environment.intercom_app_id,
      ...options,
      widget: {
        activator: '#intercom'
      }
    })
  }

  shutdown() {
    this.intercom.shutdown();
  }
}
