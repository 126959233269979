<form [formGroup]="form">
  <div class="select-date_container">
    <div class="select-date_input-container">
      <label class="select-date_label vox-field-label" translate>customers.filters.dates.from</label>
      <div class="input-group select-date_since">
        <input class="form-control select-date_input"
          placeholder="{{'customers.filters.dates.placeholder' | translate}}" formControlName="creation_date_from"
          ngbDatepicker #d="ngbDatepicker" [footerTemplate]="footerTemplate1" [dayTemplate]="customDay">
        <div class="input-group-append">
          <button type="button" class="btn btn-outline-secondary select-date_icon" (click)="d.toggle()" type="button">
            <i class="icon-small-calendar-schedule"></i>
          </button>
        </div>
      </div>

      <ng-template #footerTemplate1>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-sm m-2 float-left select-date_footer-btn"
          (click)="setToday('creation_date_from')" translate>customers.filters.dates.today</button>
      </ng-template>

    </div>

    <div class="select-date_input-container">
      <label class="select-date_label vox-field-label" translate>customers.filters.dates.to</label>

      <div class="input-group select-date_till">
        <input class="select-date_input form-control"
          placeholder="{{'customers.filters.dates.placeholder' | translate}}" formControlName="creation_date_to"
          ngbDatepicker #d2="ngbDatepicker" [footerTemplate]="footerTemplate2"
          [minDate]="form.value.creation_date_from || null" [dayTemplate]="customDay">
        <div class="input-group-append">
          <button type="button" class="btn btn-outline-secondary select-date_icon" (click)="d2.toggle()" type="button">
            <i class="icon-small-calendar-schedule"></i>
          </button>
        </div>
      </div>

      <ng-template #footerTemplate2>
        <hr class="my-0">
        <button type="button" class="btn btn-primary btn-sm m-2 float-left select-date_footer-btn"
          (click)="setToday('creation_date_to')" translate>customers.filters.dates.today</button>
      </ng-template>
    </div>
  </div>
</form>


<ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
  let-focused="focused">
  <span class="custom-day" [class.select-date_custom-day]="selected" [class.text-muted]="disabled"
    [class.focused]="focused">
    {{ date.day }}
  </span>
</ng-template>