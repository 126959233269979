<div class="main-container">
  <div class="title-container">
    <h2 class="vox-heading-2 title-container__title" translate>onboarding.data3.title</h2>
  </div>

  <form class="main-container__form-box" [formGroup]="frm" (ngSubmit)="onSubmit()">
    <div class="form-group form-group__telecomunications">
      <label class="vox-field-label" for="telecomunications" translate>onboarding.data3.telecomunications.label</label>
      <div class="input-box">
        <div class="vox-input-group vox-input-group__radio vox-input-group__radio--left">
          <input class="vox-radio" name="telecomunications" formControlName="telecomunications" type="radio" value="yes"
            (change)="onRadioChange($event)" />
          <span translate>onboarding.data3.telecomunications.yes</span>
          <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
          <span class="vox-input-group__error-message" translate>onboarding.data3.telecomunications.error</span>
        </div>
        <div class="vox-input-group vox-input-group__radio vox-input-group__radio--right">
          <input class="vox-radio" name="telecomunications" formControlName="telecomunications" type="radio" value="no"
            (change)="onRadioChange($event)" />
          <span translate>onboarding.data3.telecomunications.no</span>
          <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
          <span class="vox-input-group__error-message" translate>onboarding.data3.telecomunications.error</span>
        </div>
      </div>
    </div>

    <div class="form-group form-group__companySize">
      <label class="vox-field-label" for="companySize" translate>onboarding.data3.companySize.label</label>
      <div class="vox-input-group dropdown">
        <div class="vox-dropdown" ngbDropdown placement="bottom-left">
          <div class="vox-text-field text-left btn" id="companySize-dropdown" ngbDropdownToggle>
            {{"onboarding.data3.companySize." + frm.controls.companySize.value.locale | translate }}
          </div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem *ngFor="let size of sizes" (click)="setSize(size)">
              {{ "onboarding.data3.companySize." + size.locale | translate }}</div>
          </div>
        </div>
        <i class="vox-text-field__icon vox-text-field__icon_valid"></i><span class="vox-input-group__error-message"
          translate>onboarding.data3.companySize.error</span>
      </div>
    </div>

    <div class="form-group form-group__subscriptions">
      <label class="vox-field-label" for="subscriptions" translate>onboarding.data3.subscriptions.label</label>
      <div class="vox-input-group dropdown">
        <div class="vox-dropdown" ngbDropdown placement="bottom-left">
          <div class="vox-text-field text-left btn" id="subscriptions-dropdown" ngbDropdownToggle>
            {{ "onboarding.data3.subscriptions." + frm.controls.subscriptions.value.locale | translate }}</div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem *ngFor="let sub of subscriptions" (click)="setSub(sub)">
              {{ "onboarding.data3.subscriptions." + sub.locale | translate }}</div>
          </div>
        </div>
        <i class="vox-text-field__icon vox-text-field__icon_valid"></i><span class="vox-input-group__error-message"
          translate>onboarding.data3.subscriptions.error</span>
      </div>
    </div>

    <div class="form-group form-group__companiesSize">
      <label class="vox-field-label" for="companiesSize" translate>onboarding.data3.companiesSize.label</label>
      <div class="vox-input-group dropdown">
        <div class="vox-dropdown" ngbDropdown placement="bottom-left">
          <div class="vox-text-field text-left btn" id="companiesSize-dropdown" ngbDropdownToggle>
            {{ "onboarding.data3.companiesSize." + frm.controls.companiesSize.value.locale | translate }}</div>
          <div ngbDropdownMenu>
            <div ngbDropdownItem *ngFor="let companySize of companiesSize" (click)="setCompanySize(companySize)">
              {{ "onboarding.data3.companiesSize." + companySize.locale | translate }}</div>
          </div>
        </div>
        <i class="vox-text-field__icon vox-text-field__icon_valid"></i><span class="vox-input-group__error-message"
          translate>onboarding.data3.companiesSize.error</span>
      </div>
    </div>

    <div class="form-group form-group__customersReady">
      <label class="vox-field-label" for="customersReady" translate>onboarding.data3.customersReady.label</label>
      <div class="input-box">
        <div class="vox-input-group vox-input-group__radio vox-input-group__radio--left">
          <input class="vox-radio" name="customersReady" formControlName="customersReady" type="radio" value="yes"
            (change)="onRadioChange($event)" />
          <span translate>onboarding.data3.customersReady.yes</span>
          <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
          <span class="vox-input-group__error-message" translate>onboarding.data3.customersReady.error</span>
        </div>
        <div class="vox-input-group vox-input-group__radio vox-input-group__radio--right">
          <input class="vox-radio" name="customersReady" formControlName="customersReady" type="radio" value="no"
            (change)="onRadioChange($event)" />
          <span translate>onboarding.data3.customersReady.no</span>
          <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
          <span class="vox-input-group__error-message" translate>onboarding.data3.customersReady.error</span>
        </div>
      </div>
    </div>

    <div class="button-box">
      <button class="vox-btn vox-btn-primary vox-btn--back" routerLink="/partner-contact/2"
        translate>onboarding.data3.buttonBack</button>

      <button class="vox-btn vox-btn-primary" type="submit" [disabled]="!frm.valid"
        translate>onboarding.data3.buttonNext</button>
    </div>
  </form>

  <div class="skeleton-container" *ngIf="loading">
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', width: '390px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '120px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', width: '250px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '350px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '390px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{
          margin: '0',
          'margin-top': '30px',
          width: '130px',
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</div>