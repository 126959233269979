import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CompanyOtherDataState, State } from "../reducers";

export const selectCompanyOtherDataState = createFeatureSelector<
  CompanyOtherDataState
>("company-other-data");

export const selectCompanyOtherDataStatus = createSelector(
  selectCompanyOtherDataState,
  (state: CompanyOtherDataState) => state.status
);

export const selectTelecomunications = createSelector(
  selectCompanyOtherDataStatus,
  (state: State) => state.telecomunications
);

export const selectCompanySize = createSelector(
  selectCompanyOtherDataStatus,
  (state: State) => state.company_size
);

export const selectSubscriptions = createSelector(
  selectCompanyOtherDataStatus,
  (state: State) => state.subscriptions
);

export const selectCompaniesSize = createSelector(
  selectCompanyOtherDataStatus,
  (state: State) => state.companies_size
);

export const selectCustomersReady = createSelector(
  selectCompanyOtherDataStatus,
  (state: State) => state.customers_ready
);
