<div class="main-container">
  <div class="title-container">
    <h2 class="vox-heading-2 title-container__title" translate>onboarding.data1.title</h2>
    <h5 class="vox-heading-5 title-container__subtitle" translate>onboarding.data1.subtitle</h5>
  </div>

  <form class="main-container__form-box" [formGroup]="frm" (ngSubmit)="onSubmit()">
    <div class="form-group form-group__name">
      <label class="vox-field-label" for="name" translate>onboarding.data1.name.label</label>
      <div class="vox-input-group" [ngClass]="{ 'vox-input-group_invalid': isInvalid('full_name') }">
        <input type="text" tabindex="1" id="name" [placeholder]="'onboarding.data1.name.placeholder' | translate"
          formControlName="full_name" class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off"
          autofocus />
        <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
        <span class="vox-input-group__error-message" *ngIf="!getNameError()"
          translate>onboarding.data1.name.error</span>
        <span class="vox-input-group__error-message" *ngIf="getNameError()"
          translate>onboarding.data1.name.error-format</span>
      </div>
    </div>

    <div class="form-group form-group__email">
      <label class="vox-field-label" for="email" translate>onboarding.data1.email.label</label>
      <div class="vox-input-group" [ngClass]="{ 'vox-input-group_invalid': isInvalid('email') }">
        <input type="email" tabindex="1" id="email" [placeholder]="'onboarding.data1.email.placeholder' | translate"
          formControlName="email" class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off"
          autofocus />
        <i class="vox-text-field__icon vox-text-field__icon_valid icon-small-alert"></i>
        <span class="vox-input-group__error-message" *ngIf="!getEmailError()"
          translate>onboarding.data1.email.error</span>
        <span class="vox-input-group__error-message" *ngIf="getEmailError()"
          translate>onboarding.data1.email.error-invalid</span>
      </div>
    </div>

    <div class="form-group form-group__phone">
      <label class="vox-field-label" for="phone" translate>onboarding.data1.phone.label</label>
      <vox-phone-text-field state="it" formControlName="phone">
      </vox-phone-text-field>
    </div>

    <div class="button-box">
      <button class="vox-btn vox-btn-primary" [disabled]="!frm.valid" type="submit"
        translate>onboarding.data1.button</button>
    </div>
  </form>

  <div class="skeleton-container" *ngIf="loading">
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', width: '120px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '120px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '120px' }"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
    </div>
    <div class="input group">
      <ngx-skeleton-loader [theme]="{
          margin: '0',
          'margin-top': '30px',
          width: '130px',
          height: '60px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</div>