import { OnboardingPartnerDataFormComponent } from "./partner-data-form/partner-data-form.component";
import { OnboardingCompanyDataFormComponent } from "./company-data-form/company-data-form.component";
import { OnboardingCompanyOtherDataFormComponent } from "./company-other-data-form/company-other-data-form.component";
import { OnboardingThanksPageComponent } from "./thanks-page/thanks-page.component";

export const components: any[] = [
  OnboardingPartnerDataFormComponent,
  OnboardingCompanyDataFormComponent,
  OnboardingCompanyOtherDataFormComponent,
  OnboardingThanksPageComponent
];

export * from "./partner-data-form/partner-data-form.component";
export * from "./company-data-form/company-data-form.component";
export * from "./company-other-data-form/company-other-data-form.component";
export * from "./thanks-page/thanks-page.component";
