// Core
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Guards
import { AuthGuard } from "src/app/domains/auth/guards";
import { PartnersGuard } from "src/app/domains/partners/guards";
import { AgentsGuard, ShowPrivateLinkGuard } from "src/app/domains/agents/guards";
import { CustomersGuard } from "src/app/domains/customers/guards";

// Components
import { LayoutMainComponent } from "./layout/main/main.component";

export const routes: Routes = [
  {
    path: "",
    component: LayoutMainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard"
      },
      {
        path: "dashboard",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("../dashboard/dashboard.module").then(m => m.DashboardModule)
      },
      {
        path: "profile",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("../user/user.module").then(m => m.UserModule)
      },
      {
        path: "partners",
        canLoad: [AuthGuard, PartnersGuard],
        loadChildren: () =>
          import("../partners/partners.module").then(m => m.PartnersModule)
      },
      {
        path: "agents",
        canLoad: [AuthGuard, AgentsGuard],
        loadChildren: () =>
          import("../agents/agents.module").then(m => m.AgentsModule)
      },
      {
        path: "customers",
        canLoad: [AuthGuard, CustomersGuard],
        loadChildren: () =>
          import("../customers/customers.module").then(m => m.CustomersModule)
      },
      {
        path: "partner-hub",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("../partner-hub/partner-hub.module").then(m => m.PartnerHubModule)
      },
      {
        path: "partner-doc",
        canLoad: [AuthGuard, ShowPrivateLinkGuard],
        loadChildren: () =>
          import("../partner-doc/partner-doc.module").then(m => m.PartnerDocModule)
      },
      {
        path: "contact-us",
        loadChildren: () =>
          import("../contacts-us/contact-us.module").then(
            m => m.ContactUsModule
          )
      },
      {
        path: "sales",
        loadChildren: () => 
          import("../sales/sales.module").then(
            m => m.SalesModule
          )
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
