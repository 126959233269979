// Core
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SharedModule } from '@app/shared/shared.module';

// Libs
import { TranslateModule } from "@ngx-translate/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { VoxLanguagePickerModule } from "ng-voxloud";

// Components
import { LayoutHeaderComponent } from "./header/header.component";
import { LayoutMainComponent } from "./main/main.component";
import { LayoutMenuComponent } from "./menu/menu.component";

@NgModule({
  declarations: [
    LayoutHeaderComponent,
    LayoutMainComponent,
    LayoutMenuComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    TranslateModule.forChild()
  ]
})
export class MainLayoutModule {}
