import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from '../../../../core/i18n';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'auth-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class AuthResetPasswordFormComponent implements OnInit {
  @Input() loading: boolean;
  @Output() submitted = new EventEmitter<any>();
  criteria: any;

  frm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private i18nService: I18nService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  get language() {
    return this.translate.currentLang;
  }

  get isDisabled() {
    if (!this.frm.invalid) {
      return this.loading;
    }
    return this.frm.invalid;
  }

  createForm() {
    this.frm = this.fb.group({ new_password: ['', Validators.required] });
  }

  onSubmit() {
    if (this.frm.valid) {
      this.submitted.emit(this.frm.value);
    }
  }
}
