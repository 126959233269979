// Libs
import { createFeatureSelector, createSelector } from "@ngrx/store";

// Models
import { Partner } from "../../models/partners.model";

// Store
import { PartnersState } from "../reducers";

export const selectPartnersState = createFeatureSelector<PartnersState>("partners");

export const selectPartnersStatus = createSelector(
  selectPartnersState,
  (state: PartnersState) => state.status
);
export const selectPartners = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    if (state.status && state.status.partners) {
      return state.status.partners;
    }
  }
);

export const selectPartnersOptions = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    if (state.status && state.status.partnersOptions) {
      return state.status.partnersOptions;
    }
  }
);

export const selectPartner = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    if (state.status && state.status.partner) {
      return state.status.partner;
    }
  }
);

export const selectLoading = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.loading;
  }
);
export const selectLoadingPartners = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.loading.partners;
  }
);
export const selectLoadingPartner = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.loading.partner;
  }
);
export const selectSuccessPartner = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.success.partner;
  }
);
export const selectSuccessPartners = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.success.partners;
  }
);
export const selectError = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.error;
  }
);
export const selectErrorPartners = createSelector(
  selectPartnersState,
  (state: PartnersState) => {
    return state.status.error.partners;
  }
);
export const selectErrorPartner = createSelector(
    selectPartnersState,
    (state: PartnersState) => {
      return state.status.error.partner;
  }
);