import {
  PartnerDataActionTypes,
  PartnerDataActions
} from "../actions/partner-data.action";

export interface State {
  full_name: string;
  email: string;
  phone: string;
}

export const initialState: State = {
  full_name: "",
  email: "",
  phone: ""
};

export function reducer(
  state: State = initialState,
  action: PartnerDataActions
) {
  switch (action.type) {
    case PartnerDataActionTypes.SavePartnerData:
      return {
        ...state,
        full_name: action.partnerData.full_name,
        email: action.partnerData.email,
        phone: action.partnerData.phone
      };
    default:
      return state;
  }
}
