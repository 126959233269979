<div class="pagination">
  <div class="paginator">
      <button class="paginator__arrow" [disabled]="prevDisabled()" (click)="prevPage()" [ngClass]="{ 'paginator__arrow--disabled': prevDisabled() }">
          <i class="icon-small-arrow-left"></i>
      </button>
      <ul class="paginator__nav">
          <li *ngFor="let p of pages">
              <a class="paginator__nav" (click)="goToPage(p)" [ngClass]="{ 'paginator__nav--active': p === pagination.page }" (click)="goToPage(p)">{{p + 1}}</a>
          </li>
      </ul>
      <button class="paginator__arrow" [disabled]="nextDisabled()" (click)="nextPage()" [ngClass]="{ 'paginator__arrow--disabled': nextDisabled() }">
          <i class="icon-small-arrow-right"></i>
      </button>
  </div>
  <div class="paginate-size">
      <span class="paginate-size__label" translate>pagination.size-label</span>
      <div ngbDropdown class="vox-dropdown-input paginate-size__dropdown" placement="top">
          <button class="paginate-size__toggle" id="paginate-size" ngbDropdownToggle>
              {{selectedPageSize}}
          </button>
          <div ngbDropdownMenu aria-labelledby="paginate-size">
            <button ngbDropdownItem *ngFor="let size of pageSizes" (click)="selectPageSize(size)">
                {{size}}
            </button>
          </div>
        </div>
  </div>
</div>