// Core
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';

// Store
import { AuthFacade } from "src/app/domains/auth";

@Component({
  selector: "vov-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"]
})
export class AuthLoginFormComponent implements OnInit {
  frm: UntypedFormGroup;
  @Input() error: boolean;
  @Input() loading: boolean;
  @Output() submitted = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private facade: AuthFacade
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.frm = this.fb.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });
  }

  get isDisabled() {
    if (!this.frm.invalid) {
      return this.loading;
    }
    return this.frm.invalid;
  }

  onClose() {
    this.error = false;
  }

  onSubmit() {
    if (this.frm.valid) {
      this.submitted.emit(this.frm.value);
    }
  }
}
