// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

// Libs
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

// Services
import { PartnerCreationService } from "src/app/domains/onboarding/services/partner-creation.service";

// Modules
import { OnboardingRoutingModule } from "./onboarding-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CompanyDataDomainModule } from "src/app/domains/company-data/company-data.domain";
import { CompanyOtherDataDomainModule } from "src/app/domains/company-other-data/company-other-data.domain";
import { OnboardingDomainModule } from "src/app/domains/onboarding/onboarding.domain";
import { PartnerDomainModule } from "src/app/domains/partner-data/partner-data.domain";

// Components
import * as fromComponents from "./components";
import * as fromContainers from "./containers";

@NgModule({
  imports: [
    OnboardingRoutingModule,
    SharedModule,
    PartnerDomainModule,
    CompanyDataDomainModule,
    CompanyOtherDataDomainModule,
    OnboardingDomainModule
  ],
  declarations: [...fromComponents.components, ...fromContainers.containers],
  providers: [PartnerCreationService]
})
export class OnboardingModule {}
