// Core
import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

// Rxjs
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

// Libs
import { Store, select } from "@ngrx/store";

// Store
import {
  AuthState,
  LoginJustLogged,
  LoginReset,
  selectIsAuthenticated
} from "../store";

@Injectable({
  providedIn: "root"
})
export class AuthLoginGuard implements CanActivate {
  constructor(private store: Store<AuthState>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsAuthenticated),
      map(isValidToken => {
        if (!isValidToken) {
          this.store.dispatch(new LoginReset());
          return true;
        } else {
          this.store.dispatch(new LoginJustLogged());
          return false;
        }
      }),
      take(1)
    );
  }
}
