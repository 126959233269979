<div class="agents-table__main">
  <div class="agents-table__actions">
    <div class="agent-search">
      <i class="icon-small-search"></i>
      <input [formControl]="searchControl" class="agent-search-input"
        [placeholder]="'agents.actions.search-placeholder' | translate" />
    </div>
  </div>
  <div class="agents-table">
    <table>
      <thead>
        <tr>
          <td class="sortable" (click)="toggleSort('name')">
            <span translate>agents.table.header.name</span>
            <i *ngIf="sort.name" class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.name === 'asc' }"></i>
          </td>
          <td class="sortable" (click)="toggleSort('email')">
            <span translate>agents.table.header.email</span>
            <i *ngIf="sort.email" class="icon-small-arrow-dropdown" [ngClass]="{'asc':sort.email === 'asc' }"></i>
          </td>
          <td translate>agents.table.header.phone</td>
          <td translate>
            <span translate>agents.table.header.active</span>
          </td>
          <td>
            <span translate>agents.table.header.admin</span>
          </td>
          <td translate>agents.table.header.actions</td>
        </tr>
      </thead>
      <tbody class="loading" *ngIf="loading$ | async">
        <tr *ngFor="let i of [1,2,3,4,5]">
          <td colspan="6">
            <ngx-skeleton-loader [theme]="{'margin-bottom': '0'}"></ngx-skeleton-loader>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ |async) && (displayedAgents$ | async).length === 0">
        <tr>
          <td colspan="6" translate>agents.table.empty</td>
        </tr>
      </tbody>
      <tbody *ngIf="!(loading$ | async)">
        <tr *ngFor="let agent of (displayedAgents$ | async)[pagination.page]">
          <td>
            <span>{{agent.first_name}} {{agent.last_name}}</span>
            <span *ifCurrentUser="{user:agent}" class="vox-chip vox-chip_royal-blue" translate>app.you</span>
          </td>
          <td>{{agent.email}}</td>
          <td>{{agent.phone}}</td>
          <td>
            <i *ngIf="!agent.enabled" class="icon-small-alert"
              [ngbTooltip]="'agents.table.user-inactive-info' | translate">
            </i>
            <i *ngIf="agent.enabled" class="icon-small-check-circle"></i>
          </td>
          <td class="center">
            <span class="admin-check" *ifUserRole="[ROLES.ADMIN]">
              <input class="vox-checkbox admin-check__input" (click)="onCheckboxClick($event, agent)"
                [checked]="canBeAgent(agent)" type="checkbox" *ifCurrentUser="{user:agent, reverse: true}">
              <input class="vox-checkbox admin-check__input" [checked]="canBeAgent(agent)" type="checkbox" disabled
                *ifCurrentUser="{user:agent}">
            </span>
            <span class="admin-check" *ifUserRole="[ROLES.AGENT, ROLES.SUPERVISOR]">
              <input class="vox-checkbox admin-check__input" [checked]="canBeAgent(agent)" type="checkbox" disabled>
            </span>
          </td>
          <td class="agent-actions">
            <span *ifUserRole="[ROLES.ADMIN]">
              <span *ngIf="canBeAgent(agent)">
                <i *ifCurrentUser="{user:agent}" placement="left"
                  [ngbTooltip]="'agents.table.actions.info-self'| translate" class="icon-small-info"></i>
              </span>

              <i (click)="deleteAgent(agent.email)" *ifCurrentUser="{user:agent, reverse: true}"
                class="icon-small-delete"></i>
            </span>
            <i *ifUserRole="[ROLES.AGENT]" class="icon-small-info"
              [ngbTooltip]="'agents.table.actions.info'| translate"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav *ngIf="!(loading$ | async) && (displayedAgents$ | async).length !== 0 && pages.length !== 1"
    class="agents-table__pagination">
    <button [disabled]="prevDisabled()" (click)="prevPage()" class="icon-small-arrow-left"></button>
    <ul class="list">
      <li *ngFor="let p of pages" [ngClass]="{ 'list__item': true, 'list__item_current': p === pagination.page }">
        <a (click)="goToPage(p)">{{p+1}}</a>
      </li>
    </ul>
    <button [disabled]="nextDisabled()" (click)="nextPage()" class="icon-small-arrow-right"></button>
  </nav>
</div>
