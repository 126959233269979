import { Injectable } from "@angular/core";
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Metrics, CustomerMetrics } from '../models/metrics.model';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient) { }

  public getSupervisorMetrics() {
    return this.http.get<Metrics>(`${environment.apiEndpoint}/api/v1/customers/metrics`);
  }

  public getAdminMetrics(partnerId: string) {
    return this.http.get<CustomerMetrics>(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/metrics`).pipe(
      map((metrics: CustomerMetrics) => {
        return metrics.customer_metrics;
      })
    );
  }

  public getAgentMetrics(partnerId: string, agentId: string) {
    return this.http.get<CustomerMetrics>(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/agents/${agentId}/metrics`).pipe(
      map((metrics: CustomerMetrics) => {
        return metrics.customer_metrics;
      })
    );
  }
}