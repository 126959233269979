<form class="form-box" [formGroup]="frm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="vox-field-label" for="email" translate>forgot-password.form.email.label</label>
    <div class="vox-input-group">
      <input type="email" tabindex="1" id="email" [placeholder]="'forgot-password.form.email.placeholder' | translate"
        class="vox-text-field" autocapitalize="off" autocomplete="off" autocorrect="off" formControlName="email" />
    </div>
  </div>
  <div class="form-group">
    <ngx-recaptcha2 [siteKey]="KEY" [useGlobalDomain]="false"
      (reset)="handleReset($event)" (expire)="handleExpire($event)" (load)="handleLoad($event)"
      (success)="handleSuccess($event)" formControlName="recaptcha">
    </ngx-recaptcha2>
  </div>
  <button class="vox-btn vox-btn-primary" type="submit" [disabled]="isDisabled"
    translate>forgot-password.form.restore</button>
</form>
