<div class="header header--color-white">
  <a href="/auth/login" class="header__logo">
    <picture>
      <source media="(max-width: 992px)" srcset="/assets/img/logo/voxloud-logo-only.svg">
      <img src="/assets/img/logo/voxloud-wordmark.svg" alt="voxloud logo" width="150" h eight="30">
    </picture>
    <span class="vox-heading-5" translate>auth.header.partners</span>
  </a>
  <div class="header__actions">
    <i class="icon-small-call"></i>
    <span translate>auth.header.help</span>
    <div ngbDropdown class="vox-dropdown">
      <button ngbDropdownToggle id="dropdownPhoneNumbers">
        <span class="flag flag-select flag-icon {{selectedPhoneNumber.flagClass}}"></span> <b>{{selectedPhoneNumber.number}}</b>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownPhoneNumbers">
        <button ngbDropdownItem
        *ngFor="let phoneNumber of phoneNumbers"
        (click)="onSelectPhoneNumber(phoneNumber)">
          <span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}
        </button>
      </div>
    </div>
    <vox-language-picker class="language-picker" [language]="currentLang" [availableLanguages]="langs"
      (selected)="onChangeLanguage($event)" [side]='currentWidth'>
    </vox-language-picker>
  </div>
  <div ngbDropdown class="vox-dropdown header__mobile-phone">
    <button ngbDropdownToggle id="dropdownMobilePhoneNumbers">
      <i class="icon-small-call"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMobilePhoneNumbers">
      <a href="tel:{{phoneNumber.number}}" ngbDropdownItem
      *ngFor="let phoneNumber of phoneNumbers"><span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}</a>
    </div>
  </div>
</div>
<div class="main-container">
  <section class="main-container__left">
    <h2 class="vox-heading-2 header-box" translate>activate-user.title</h2>
    <div *ngIf="loading$ | async">
      <div *ngFor="let i of [1,2,3]" class="form-group vox-input-group">
        <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': 0}"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader [theme]="{'height': '56px', 'width': '220px'}"></ngx-skeleton-loader>
    </div>
    <div *ngIf="errorMessage" class="vox-inline-message vox-inline-message_light-grey">
      <div>
        <i class="icon-small-alert"></i>
        <span translate>{{errorMessage}}</span>
      </div>
      <i class="icon-small-close" (click)="errorMessage = null"></i>
    </div>
    <div *ngIf="!token" class="vox-inline-message vox-inline-message_light-grey" translate>
      activate-user.missing-token
    </div>
    <vov-activate-user-form *ngIf="!(loading$ | async) && token" (onSubmit)="activateUser($event)">
    </vov-activate-user-form>
  </section>
  <section class="main-container__right">
    <div class="product-container">
      <h2 class="vox-heading-2" translate>auth.right.title</h2>
      <p class="text" translate>auth.right.description</p>
      <button (click)="goToWebsite()" class="vox-btn" translate>auth.right.button</button>
    </div>
  </section>
</div>