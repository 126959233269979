import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Partner, PartnersFacade } from '@app/domains/partners';
import { UserFacade } from '@app/domains/user';
import { Observable } from 'rxjs';


@Component({
  selector: 'vov-add-edit-link-form',
  templateUrl: './add-edit-link-form.component.html',
  styleUrls: ['./add-edit-link-form.component.scss']
})
export class AddEditLinkFormComponent implements OnInit{
  @Output() onClose = new EventEmitter<any>();
  @Input() linkType: string;
  @Input() data?: Partner;
  public form: FormGroup;
  public loading$: Observable<boolean>;
  public error$: Observable<any>;
  public defaultValue;
  isCollapsed = true;


  constructor(
    private fb: FormBuilder,
    private partnersFacade: PartnersFacade,
    private userFacade: UserFacade
  ){}

  ngOnInit(): void {
    if(this.linkType == 'global') {
      this.userFacade.getGlobalDriveLink$.subscribe(link => {
        console.log(link);
        this.defaultValue = link;
      })
    } else {
      this.defaultValue = this.data?.google_drive_link;
    }
    this.form = this.fb.group({
      google_drive_link: new FormControl(this.defaultValue, [Validators.required, Validators.pattern('https?://.+')])
    })

    this.loading$ = this.partnersFacade.loadingPartner$;
    this.error$ = this.partnersFacade.errorPartner$;
  }

  saveLink() {
    let google_drive_link = this.form.controls.google_drive_link.value;
    if(this.linkType == 'global') {
      this.userFacade.updateGlobalDriveLink({name: 'GLOBAL_GOOGLE_DRIVE_LINK', value: google_drive_link});
    } else {
      this.partnersFacade.updatePartnerDrivelink(this.data.id, google_drive_link);
    }
    
    this.loading$.subscribe((loading) => {
      this.error$.subscribe(error => {
        console.log(loading)

        if (!loading && !error) {
          this.onClose.emit();
        }
      });
    });
  }

  public canSubmit() {
    return this.form.controls.google_drive_link.valid;
  }
}
