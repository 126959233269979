import { ActionReducerMap } from "@ngrx/store";
import { State, reducer } from "./onboarding.reducer";

export * from "./onboarding.reducer";

export interface OnboardingState {
  status: State;
}

export const onboardingReducer: ActionReducerMap<OnboardingState> = {
  status: reducer
};
