import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export const DEFAULT_PAGE_SIZE = 20;
// MAX_PAGES not counting current page, better to use odd number
const MAX_PAGES = 6;

@Component({
  selector: 'vov-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {
  public pageSizes: Array<number> = [5, 10, 20, 30, 50]
  public selectedPageSize: number = DEFAULT_PAGE_SIZE;
  public loadingLines: Array<number> = [];
  public pages: number[] = [];
  @Input() totalPages: number;
  @Input() pagination: any;
  @Output() onSelectPageSize: EventEmitter<any> = new EventEmitter();
  @Output() onChangeCurrentPage: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    let pages: any[] = [];
    if (this.pagination.totalPages) {
      const totalPages = this.pagination.totalPages;
      const currentPage = this.pagination.page;
      // Default page limits
      let startPage = 0;
      let endPage = totalPages;
      const isMaxSized = totalPages >= MAX_PAGES;

      // recompute if isMaxSized
      if (isMaxSized) {
        startPage = Math.max(currentPage - Math.ceil(MAX_PAGES / 2), 0);
        endPage = startPage + MAX_PAGES + 1;

        // Adjust if limit is exceeded
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - (MAX_PAGES + 1);
        }
      }
      for (let num = startPage; num < endPage; num++) {
        pages.push(num);
      }
    }
    this.pages = pages || [];
  }

  selectPageSize(size: number) {
    this.selectedPageSize = size;
    this.onSelectPageSize.emit(this.selectedPageSize);
  }

  goToPage(p) {
    if (this.pagination.page !== p) {
      this.pagination.page = p;
      this.onChangeCurrentPage.emit(p);
    }
  }
  nextPage() {
    this.pagination.page += 1;
    this.onChangeCurrentPage.emit(this.pagination.page);
  }
  prevPage() {
    this.pagination.page -= 1;
    this.onChangeCurrentPage.emit(this.pagination.page);
  }

  prevDisabled() {
    return this.pagination.page === 0 || this.pagination.totalPages === 0;
  }
  nextDisabled() {
    return this.pagination.page === this.pages[this.pages.length - 1] || this.pagination.totalPages === 0;
  }

}
