// Core
import { NgModule } from "@angular/core";

// Modules
import { MainRoutingModule } from "./main-routing.module";
import { MainLayoutModule } from "./layout/layout.module";

@NgModule({
  imports: [
    MainRoutingModule,
    MainLayoutModule
  ],
  declarations: []
})
export class MainModule {}
