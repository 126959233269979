import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserFacade } from '@app/domains/user/store/facade';
import { USER_ROLE, User } from '@app/domains/user/models/';


@Directive({
  selector: '[ifUserRole]'
})

export class IfUserRoleDirective {
  private user$: Observable<User>;
  
  constructor(
    private facade: UserFacade,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.user$ = facade.user$;
  }

  @Input() set ifUserRole(roles: [USER_ROLE]) {
    this.user$.subscribe((u)=>{
      if(roles && u.roles && roles.includes(u.roles[0])) {
        if (this.viewContainer.length === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        this.viewContainer.clear();
      }
    });
    
  }
  
}
