// Core
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";

// Rxjs
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

// Environments
import { environment } from "src/environments/environment";

// Services
import { Logger } from "../logger/logger.service";
import { ConfirmationPopupService } from "src/app/shared/components/confirmation-popup/confirmation-popup.service";

const log = new Logger("ErrorHandlerInterceptor");

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private readonly confirmation: ConfirmationPopupService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(event: HttpEvent<any>) {
    if (!environment.production) {
      if (event instanceof HttpResponse) {
        log.error("Request error", event.body);
      }
    }
    log.error("Request error", event);
    return throwError(event);
  }
}
