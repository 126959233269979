// Core
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, CanActivateChild } from '@angular/router';

// Rxjs
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Libs
import { Store, select } from '@ngrx/store';

// Store
import { AuthState, Logout, selectIsAuthenticated } from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private store: Store<AuthState>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsAuthenticated),
      map(isValidToken => {
        if (!isValidToken) {
          this.store.dispatch(new Logout());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

  canLoad(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsAuthenticated),
      map(isValidToken => {
        if (!isValidToken) {
          this.store.dispatch(new Logout());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
