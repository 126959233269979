import { Component, OnInit } from "@angular/core";

@Component({
  selector: "onboarding-thanks-page",
  templateUrl: "./thanks-page.component.html",
  styleUrls: ["./thanks-page.component.scss"]
})
export class OnboardingThanksPageComponent implements OnInit {
  public loading: boolean = false;
  constructor() {}

  ngOnInit() {}
}
