<div class="toasts-container">
  <ngb-toast
    *ngFor="let toast of notificationService.toasts"
    [class]="toast.classname ? toast.classname : ''"
    [autohide]="true"
    [delay]="toast.delay || DEFAULT_DELAY"
    (hide)="notificationService.remove(toast)"
>
    <div class="toast-content">
      <div>
        <i *ngIf="toast.iconclass" [ngClass]="toast.iconclass"></i>
        <span translate [innerHTML]="toast.message | translate"></span>
      </div>
      <i class="icon-small-close" (click)="notificationService.remove(toast)"></i>
    </div>
  </ngb-toast>
</div>