// Libs
import { Action } from '@ngrx/store';

// Models
import { Agent, Admin } from '../../models/agents.model';
import { PaginationOptions } from '@app/core/config/models/api.model';

export enum AgentsActionTypes {
  GetAgents = '[Agents] Get Agents list',
  GetAgentsSuccess = '[Agents] Get Agents list success',
  GetAgentsError = '[Agents] Get Agents list error',
  DeleteAgent = '[Agents] Delete Agent',
  DeleteAgentSuccess = '[Agents] Delete Agent success',
  DeleteAgentError = '[Agents] Delete Agent error',
  AddAgent = '[Agents] Add Agent',
  AddAgentSuccess = '[Agents] Add Agent success',
  AddAgentError = '[Agents] Add Agent error',
  UpdateAgent = '[Agents] Update Agent',
  UpdateAgentSuccess = '[Agents] Update Agent success',
  UpdateAgentError = '[Agents] Update Agent error',
  AddAdmin = '[Agents] Add Admin',
  AddAdminSuccess = '[Agents] Add Admin success',
  AddAdminError = '[Agents] Add Admin error',
}

export class GetAgents implements Action {
  readonly type = AgentsActionTypes.GetAgents;
  constructor(readonly payload: { partnerId: string }) {}
}

export class GetAgentsSuccess implements Action {
  readonly type = AgentsActionTypes.GetAgentsSuccess;
  constructor(readonly payload: { agents: Agent[] }) {}
}

export class GetAgentsError implements Action {
  readonly type = AgentsActionTypes.GetAgentsError;
  constructor(readonly payload: { error: string }) {}
}

export class DeleteAgent implements Action {
  readonly type = AgentsActionTypes.DeleteAgent;
  constructor(readonly payload: { id: string, partnerId: string}) {}
}

export class DeleteAgentSuccess implements Action {
  readonly type = AgentsActionTypes.DeleteAgentSuccess;
  constructor() {}
}

export class DeleteAgentError implements Action {
  readonly type = AgentsActionTypes.DeleteAgentError;
  constructor(readonly payload: { error: string }) {}
}

export class AddAgent implements Action {
  readonly type = AgentsActionTypes.AddAgent;
  constructor(readonly payload: { agent: Agent, partnerId: string }) {}
}

export class AddAgentSuccess implements Action {
  readonly type = AgentsActionTypes.AddAgentSuccess;
  constructor(readonly payload: { agent: Agent }) {}
}

export class AddAgentError implements Action {
  readonly type = AgentsActionTypes.AddAgentError;
  constructor(readonly payload: { error: string }) {}
}

export class AddAdmin implements Action {
  readonly type = AgentsActionTypes.AddAdmin;
  constructor(readonly payload: { admin: Admin, partnerId: string }) {}
}

export class AddAdminSuccess implements Action {
  readonly type = AgentsActionTypes.AddAdminSuccess;
  constructor(readonly payload: { admin: Admin }) {}
}

export class AddAdminError implements Action {
  readonly type = AgentsActionTypes.AddAdminError;
  constructor(readonly payload: { error: string }) {}
}

export class UpdateAgent implements Action {
  readonly type = AgentsActionTypes.UpdateAgent;
  constructor(readonly payload: { agent: Agent, partnerId: string }) {}
}

export class UpdateAgentSuccess implements Action {
  readonly type = AgentsActionTypes.UpdateAgentSuccess;
  constructor(readonly payload: { agent: Agent }) {}
}

export class UpdateAgentError implements Action {
  readonly type = AgentsActionTypes.UpdateAgentError;
  constructor(readonly payload: { error: string }) {}
}



export type AgentsActions =
  GetAgents |
  GetAgentsSuccess |
  GetAgentsError |
  DeleteAgent |
  DeleteAgentSuccess |
  DeleteAgentError |
  AddAgent |
  AddAgentSuccess |
  AddAgentError |
  UpdateAgent |
  UpdateAgentSuccess |
  UpdateAgentError |
  AddAdmin |
  AddAdminSuccess |
  AddAdminError;
