// Core
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// Libs
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// i18n
import { I18nService } from "src/app/core/i18n";

// Environments
import { environment } from "src/environments/environment";

// Models
import { Languages } from "src/app/shared/languages.model";
import { UserService } from "src/app/domains/user/services/user.service";
import { User, USER_ROLE  } from "src/app/domains/user/models";
import { UserFacade } from "src/app/domains/user";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "vov-layout-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class LayoutMenuComponent implements OnInit {
  @Output() closingMenu = new EventEmitter<boolean>();

  public closed: boolean = true;
  public langs: Languages[];
  public currentLang: string;
  public userLanguage: Subscription;
  public ROLES = USER_ROLE;
  public iframeUrl: SafeResourceUrl;
  public collapseState: boolean = true;
  public showPrivateLink$: Observable<boolean>;

  criteria = {
    len: null,
    upperCase: null,
    lowerCase: null,
    digit: null,
    special: null
  };

  constructor(
    private i18n: I18nService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public userFacade: UserFacade
  ) {
    this.iframeUrl = sanitizer.bypassSecurityTrustResourceUrl('https://voverc.typeform.com/to/AqFAWH');
  }

  ngOnInit() {
    this.showPrivateLink$ = this.userFacade.showPrivateLink$;
    this.currentLang = this.i18n.language;
    this.userLanguage = this.userFacade.language$
      .pipe(filter(e => e !== undefined))
      .subscribe(language => {
        if (language !== this.currentLang) {
          this.onChangeLanguage(language);
        }
        this.currentLang = language;
    });

    this.langs = environment.supportedLanguages;

    this.translate.onLangChange.subscribe((evt: LangChangeEvent) => {
        this.currentLang = evt.lang;
    });
  }

  onChangeLanguage(evt) {
    this.i18n.language = evt;
  }

  closeMenu() {
    this.closingMenu.emit(this.closed);
  }

  openFeedbackPopup(content) {
    this.modalService.open(content, {size: 'lg'});
  }

}
