// Core
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

// Router
import { Router } from "@angular/router";

// Store
import { CompanyOtherDataFacade } from "src/app/domains/company-other-data/store/facade/company-other-data.facade";
import { OnboardingFacade } from "src/app/domains/onboarding/store/facade/onboarding.facade";

// Models
import { CompanyOtherData } from "src/app/domains/company-other-data/models/company-other-data.model";

// Services
import { PartnerCreationService } from "src/app/domains/onboarding/services/partner-creation.service";

@Component({
  selector: "onboarding-company-other-data-form",
  templateUrl: "./company-other-data-form.component.html",
  styleUrls: ["./company-other-data-form.component.scss"]
})
export class OnboardingCompanyOtherDataFormComponent implements OnInit {
  frm: UntypedFormGroup;

  sizes: any[] = [
    { locale: "size1", name: "Solo io" },
    { locale: "size2", name: "1-3" },
    { locale: "size3", name: "4-6" },
    { locale: "size4", name: "7-10" },
    { locale: "size5", name: "11-15" },
    { locale: "size6", name: "15+" }
  ];

  subscriptions: any[] = [
    { locale: "sub1", name: "1-9" },
    { locale: "sub2", name: "10-20" },
    { locale: "sub3", name: "21-50" },
    { locale: "sub4", name: "51-100" },
    { locale: "sub5", name: "101-500" },
    { locale: "sub6", name: "500+" }
  ];

  companiesSize: any[] = [
    { locale: "companySize1", name: "1-9 dipendenti" },
    { locale: "companySize2", name: "10-20 dipendenti" },
    { locale: "companySize3", name: "20-50 dipendenti" },
    { locale: "companySize4", name: "50+ dipendenti" }
  ];

  selected = {
    yes: true,
    no: false
  };

  companyOtherData: CompanyOtherData = {
    telecomunications: false,
    company_size: "",
    subscriptions: "",
    companies_size: "",
    customers_ready: false
  };

  public loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private codFacade: CompanyOtherDataFacade,
    private onboardingFacade: OnboardingFacade,
    private pcService: PartnerCreationService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.frm = this.fb.group({
      telecomunications: [null, Validators.required],
      companySize: [this.sizes[0]],
      subscriptions: [this.subscriptions[0]],
      companiesSize: [this.companiesSize[0]],
      customersReady: [null, Validators.required]
    });
  }

  setSize(size: any) {
    this.frm.controls.companySize.setValue(size);
  }

  setSub(sub: any) {
    this.frm.controls["subscriptions"].setValue(sub);
  }

  setCompanySize(companySize: any) {
    this.frm.controls["companiesSize"].setValue(companySize);
  }

  onRadioChange(e) {
    switch (e.target.value) {
      case "yes":
        this.selected = {
          yes: true,
          no: false
        };
        break;
      default:
        break;
    }
  }

  saveFormData() {
    const payload = {
      telecomunications: this.frm.get("telecomunications").value,
      company_size: this.frm.get("companySize").value,
      subscriptions: this.frm.get("subscriptions").value,
      companies_size: this.frm.get("companiesSize").value,
      customers_ready: this.frm.get("customersReady").value
    };
    this.codFacade.saveCompanyOtherData(payload);
  }

  onSubmit() {
    if (this.frm.valid) {
      this.saveFormData();

      this.onboardingFacade.partner_informations$.subscribe(partner => {
        this.pcService.sendPartner(partner).then(() => {
          this.router.navigate(["/partner-contact/4"]);
        });
      });
    }
  }
}
