<div class="header header--color-white">
  <div class="header__logo">
    <picture>
      <source />
      <img src="/assets/img/logo/voxloud-wordmark.svg" alt="voxloud logo" width="150" height="30">
    </picture>
    <span class="vox-heading-5" translate>auth.header.partners</span>
  </div>
  <div class="header__actions">
    <i class="icon-small-call"></i>
    <span class="header__actions--help" translate>auth.header.help</span>
    <div ngbDropdown class="vox-dropdown">
      <button ngbDropdownToggle id="dropdownPhoneNumbers">
        <span class="flag flag-select flag-icon {{selectedPhoneNumber.flagClass}}"></span> <b>{{selectedPhoneNumber.number}}</b>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownPhoneNumbers">
        <button ngbDropdownItem
        *ngFor="let phoneNumber of phoneNumbers"
        (click)="onSelectPhoneNumber(phoneNumber)">
          <span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}
        </button>
      </div>
    </div>
    <vox-language-picker class="language-picker" [language]="currentLang" [availableLanguages]="langs"
      [side]='currentWidth' (selected)="onChangeLanguage($event)">
    </vox-language-picker>
  </div>
  <div ngbDropdown class="vox-dropdown header__mobile-phone">
    <button ngbDropdownToggle id="dropdownMobilePhoneNumbers">
      <i class="icon-small-call"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMobilePhoneNumbers">
      <a href="tel:{{phoneNumber.number}}" ngbDropdownItem
      *ngFor="let phoneNumber of phoneNumbers"><span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}</a>
    </div>
  </div>
</div>

<div class="main-container">
  <section class="main-container__left">
    <h2 class="vox-heading-2 header-box" translate>login.title</h2>
    <div *ngIf="routerMessage" class="vox-inline-message vox-inline-message_royal-blue">
      <div>
        <i class="icon-small-check-circle"></i>
        <span translate>{{routerMessage}}</span>
      </div>
      <i (click)="routerMessage = null;" class="icon-small-close"></i>
    </div>
    <div *ngIf="!(loading$ | async)">
      <vov-login-form class="vov-login-form" [error]="error$ | async" [loading]="loading$ | async"
        (submitted)="onSubmit($event)">
      </vov-login-form>
    </div>
    <div *ngIf="loading$ | async">
      <div class="input group">
        <ngx-skeleton-loader [theme]="{ margin: '0', width: '120px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
      </div>
      <div class="input group">
        <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '120px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ margin: '0', height: '56px' }"></ngx-skeleton-loader>
      </div>
      <div class="input group">
        <ngx-skeleton-loader [theme]="{ margin: '0', 'margin-top': '10px', width: '140px' }"></ngx-skeleton-loader>
      </div>
      <div class="input group">
        <ngx-skeleton-loader [theme]="{
            margin: '0',
            'margin-top': '10px',
            width: '120px',
            height: '50px'
          }"></ngx-skeleton-loader>
      </div>
    </div>
  </section>

  <section class="main-container__right">
    <div class="product-container">
      <h2 class="vox-heading-2" translate>auth.right.title</h2>
      <p class="text" translate>auth.right.description</p>
      <button (click)="goToWebsite()" class="vox-btn" translate>auth.right.button</button>
    </div>
  </section>
</div>