<form [formGroup]="form" class="edit-partner-form">
  <div class="form-group" *ngIf="loading$ | async">
    <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': 0}"></ngx-skeleton-loader>
  </div>
  <div class="form-group" [hidden]="loading$ | async">
    <label class="vox-field-label" translate>partners.partner-form.inputs.name.label</label>
    <div class="vox-input-group"
      [ngClass]="{'vox-input-group_invalid' : name.dirty && name.invalid, 'vox-input-group_valid': name.dirty && name.valid }">
      <input formControlName="name" class="vox-text-field" type="text"
        [title]="'partners.partner-form.inputs.name.placeholder' | translate"
        [placeholder]="'partners.partner-form.inputs.name.placeholder' | translate">
      <i *ngIf="name.dirty && name.valid" class="vox-text-field__icon vox-text-field__icon_valid icon-small-check"></i>
      <i *ngIf="name.dirty && !name.valid" class="vox-text-field__icon icon-small-alert"></i>
      <span class="vox-input-group__error-message" translate>
        partners.partner-form.inputs.name.error
      </span>
    </div>
  </div>
  <div class="form-group" *ngIf="loading$ | async">
    <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': 0}"></ngx-skeleton-loader>
  </div>
  <div class="form-group" [hidden]="loading$ | async">
    <label class="vox-field-label" translate>partners.partner-form.inputs.email.label</label>
    <div class="vox-input-group" [ngClass]="{'vox-input-group_invalid' : email.dirty && email.invalid}">
      <input formControlName="email" class="vox-text-field" type="email"
        [title]="'partners.partner-form.inputs.email.placeholder' | translate"
        [placeholder]="'partners.partner-form.inputs.email.placeholder' | translate">
      <i class="vox-text-field__icon vox-text-field__icon_valid ladda"
        [ngClass]="{'ladda-show': email.dirty && email.pending, 'icon-small-check': email.dirty && email.valid}"
        [ladda]="email.pending" data-spinner-color="grey" data-style="zoom-in"></i>
      <i *ngIf="email.dirty && !email.valid" class="vox-text-field__icon icon-small-alert"></i>
      <span *ngIf="email.errors && email.errors.emailExists" class="vox-input-group__error-message" translate>
        partners.partner-form.inputs.email.error-exists
      </span>
      <span *ngIf="!(email.errors && email.errors.emailExists)" class="vox-input-group__error-message" translate>
        partners.partner-form.inputs.email.error
      </span>
    </div>
  </div>
  <div class="form-group" *ngIf="loading$ | async">
    <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': 0}"></ngx-skeleton-loader>
  </div>
  <div class="form-group" [hidden]="loading$ | async">
    <label class="vox-field-label" translate>partners.partner-form.inputs.vat.label</label>
    <div class="vox-input-group"
      [ngClass]="{'vox-input-group_invalid' : invalidVat, 'vox-input-group_valid': !invalidVat }">
      <vox-vat-field formControlName="vat"
        [defaultVat]="vatCountry"
        (isFocus)="onCheckVatErrors($event)"
        [language]="translationService.currentLang"
        [placeholder]="'partners.partner-form.inputs.vat.placeholder' | translate">
      </vox-vat-field>
      <i *ngIf="invalidVat" class="vox-text-field__icon icon-small-alert"></i>
      <span class="vox-input-group__error-message" translate>
        partners.partner-form.inputs.vat.error
      </span>
    </div>
  </div>
  <div class="form-group" *ngIf="loading$ | async">
    <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': 0}"></ngx-skeleton-loader>
  </div>
  <div class="form-group" [hidden]="loading$ | async">
    <label class="vox-field-label" translate>partners.partner-form.inputs.address.label</label>
    <div class="vox-input-group">
      <vox-address-search formControlName="address"
        [country]="vatCountry"
        [googleKey]="googlePlacesApiKey"
        [placeholder]="'partners.partner-form.inputs.address.placeholder' | translate">
      </vox-address-search>
    </div>
  </div>
  <!-- TODO: uncomment when clarified -->
  <!-- <div class="form-group" *ngIf="loading$ | async">
    <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '6px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{'height': '75px', 'margin-bottom': 0}"></ngx-skeleton-loader>
  </div>
  <div class="form-group" [hidden]="loading$ | async">
    <label class="vox-field-label" translate>partners.partner-form.inputs.contacts.label</label>
    <div class="vox-input-group"
      [ngClass]="{'vox-input-group_invalid' : contacts.dirty && contacts.invalid, 'vox-input-group_valid': contacts.diry && contacts.valid }">
      <vov-contact-list maxElements="5" [contacts]="users$ | async" formControlName="contacts"></vov-contact-list>
      <span *ngIf="contacts.invalid" class="vox-input-group__error-message" translate>contacts.errors.empty</span>
    </div>
  </div> -->
</form>
