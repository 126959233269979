// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: "en",
  supportedLanguages: [
    {
      locale: "it",
      name: "Italiano"
    },
    {
      locale: "en",
      name: "English"
    }
  ],
  reCaptchaKey: "6LeS0tcUAAAAAMpvTOh92H1CaiH26aZkU0KPW_Qj",
  apiEndpoint: "https://test-reselling-api.voxloud.com",
  intercom_app_id: "ne99i0tn",
  partnerCreationUrl: "https://hooks.zapier.com/hooks/catch/3074487/ot93noo/",
  resourcesDriveFolder:
    "https://drive.google.com/embeddedfolderview?id=1MEOpmoJYVBA0DDJnPZTPbC53-SZfvHj0#grid",
  googlePlacesApiKey: 'AIzaSyButP_itSUxAj98vsXTDCNYKs9Ec58CzPE',
  onboardingLink: 'https://test-go.voxloud.com',
  microservicesEndpoint: 'https://devapi.voverc.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
