import { Component, forwardRef, Injectable } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DatePickerModel } from '@app/domains/customers';
import { NgbCalendar, NgbDatepickerI18n, NgbDateStruct, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { I18n } from './select-dates.service';


@Component({
  selector: 'vov-select-dates',
  templateUrl: './select-dates.component.html',
  styleUrls: ['./select-dates.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDatesComponent),
      multi: true
    }
  ]
})
export class SelectDatesComponent implements ControlValueAccessor {

  onChange: any = () => { };
  onTouch: any = () => { };

  public datesModel: {
    creation_date_from: DatePickerModel,
    creation_date_to: DatePickerModel
  }
  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private calendar: NgbCalendar, private translation: TranslateService, private i18n: I18n) { }

  ngOnInit() {
    this.form = this.fb.group({
      creation_date_from: new UntypedFormControl(),
      creation_date_to: new UntypedFormControl()
    });

    this.i18n.language = this.translation.currentLang;

    this.translation.onLangChange.subscribe((language: any) => {
      this.i18n.language = language.lang;
    })

    this.form.get('creation_date_from').valueChanges.subscribe((changes: any) => {
      const dateTo = this.form.get('creation_date_to').value;
      const dateFrom = this.form.get('creation_date_from').value;
      if (dateTo && dateFrom) {
        if (this.isBefore(dateTo, changes)) {
          this.form.get('creation_date_to').setValue(changes);
        }
      }
      //if (dateTo && dateFrom) {
      this.onChange({ creation_date_from: dateFrom, creation_date_to: dateTo });
      //}
    });

    this.form.get('creation_date_to').valueChanges.subscribe((changes: any) => {
      const dateTo = this.form.get('creation_date_to').value;
      const dateFrom = this.form.get('creation_date_from').value;
      //if (dateTo && dateFrom) {
      this.onChange({ creation_date_from: dateFrom, creation_date_to: dateTo });
      // }
    })
  }

  private isBefore(date1: DatePickerModel, date2: DatePickerModel) {
    return (date1.year < date2.year ||
      date1.year === date2.year && date1.month < date2.month ||
      date1.year === date2.year && date1.month === date2.month && date1.day < date2.day);
  }

  setToday(dateEl: string) {
    this.form.get(dateEl).setValue(this.calendar.getToday())
  }

  writeValue(obj: { creation_date_from: DatePickerModel, creation_date_to: DatePickerModel }): void {
    if (obj) {
      this.form.get('creation_date_from').setValue(obj.creation_date_from);
      this.form.get('creation_date_to').setValue(obj.creation_date_to);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
