import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomersService } from '@app/domains/customers/services/customers.service';
import { CustomersFacade } from '@app/domains/customers/store/facade/customers.facade';
import { Customer } from '@app/domains/customers/models/customers.model';

@Component({
  selector: 'vov-customer-controlpanel-popup',
  templateUrl: './customer-controlpanel-popup.component.html',
  styleUrls: ['./customer-controlpanel-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerControlpanelPopupComponent implements OnInit {
  @ViewChild('iframe', { static: true }) iframe: ElementRef;
  public customer: Customer;
  public iframeUrl: SafeResourceUrl = '';
  public loading = false;
  public error = false;

  constructor(
    private customerService: CustomersService,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loading = true;
    this.customerService
      .getControlPanelLink(this.customer.company_id)
      .pipe(take(1))
      .subscribe((r) => {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(r.link);
        this.loading = false;
      }, () => {
        this.loading = false;
        this.error = true;
      });

  }

  iframeError() {
    this.error = true;
  }

  close() {
    this.activeModal.close();
  }
}
