import { createSelector, createFeatureSelector } from '@ngrx/store';

import {
  AuthState,
  getError,
  getLoading,
  getIsAuthenticated,
  getToken
} from '../reducers';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatus = createSelector(
  selectAuthState,
  (state: AuthState) => state.status
);

export const selectError = createSelector(
  selectAuthStatus,
  getError
);

export const selectLoading = createSelector(
  selectAuthStatus,
  getLoading
);

export const selectToken = createSelector(
  selectAuthStatus,
  getToken
);

export const selectIsAuthenticated = createSelector(
  selectAuthStatus,
  getIsAuthenticated
);
