// Core
import { NgModule } from "@angular/core";

// Router
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./features/auth/auth.module").then(m => m.AuthModule),
    data: { preload: true }
  },
  {
    path: "main",
    loadChildren: () =>
      import("./features/main/main.module").then(m => m.MainModule),
    data: { preload: false }
  },
  {
    path: "partner-contact",
    loadChildren: () =>
      import("./features/onboarding/onboarding.module").then(m => m.OnboardingModule),
    data: { preload: false }
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./features/not-found/not-found.module').then(m => m.NotFoundModule),
    data: { preload: false }
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
