// Core
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

// Router
import { Router } from "@angular/router";

// Store
import { PartnerDataFacade } from "src/app/domains/partner-data/store/facade/partner-data.facade";

// Models
import { PartnerData } from "src/app/domains/partner-data/models/partner-data.model";

@Component({
  selector: "onboarding-partner-data-form",
  templateUrl: "./partner-data-form.component.html",
  styleUrls: ["./partner-data-form.component.scss"]
})
export class OnboardingPartnerDataFormComponent implements OnInit {
  frm: UntypedFormGroup;
  private namePattern = /^(\w+\s+)+\w+|(\w+\s+)+\w+\s+|\s+(\w+\s+)+\w+|\s+(\w+\s+)+\w+\s+$/;
  private emailPattern = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/;

  partnerData: PartnerData = {
    full_name: "",
    email: "",
    phone: ""
  };

  public loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private pdFacade: PartnerDataFacade
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.frm = this.fb.group({
      full_name: [
        null,
        [Validators.required, Validators.pattern(this.namePattern)]
      ],
      email: [
        null,
        [Validators.required, Validators.pattern(this.emailPattern)]
      ],
      phone: [null]
    });
  }

  isInvalid(name: string) {
    return this.frm.get(name).invalid && this.frm.get(name).dirty;
  }

  getNameError() {
    if (this.frm.get("full_name").errors) {
      if (
        this.frm.get("full_name").errors.pattern &&
        this.frm.get("full_name").value
      ) {
        return true;
      }
    }
  }

  getEmailError() {
    if (this.frm.get("email").errors) {
      if (this.frm.get("email").errors.pattern && this.frm.get("email").value) {
        return true;
      }
    }
  }

  saveFormData() {
    const payload = {
      full_name: this.frm.get("full_name").value,
      email: this.frm.get("email").value,
      phone: this.frm.get("phone").value
    };
    this.pdFacade.savePartnerData(payload);
  }

  onSubmit() {
    if (this.frm.valid) {
      this.saveFormData();
      this.router.navigate(["/partner-contact/2"]);
    }
  }
}
