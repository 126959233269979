import { Vats } from "../models";

export const europeanVatsInfo = [
  {
    prefix: "IT",
    name: "Italy (Italia)",
    regex: /^IT[0-9]{11}$|^IT[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/
  }
  // TODO: UNCOMMENT WHEN ADDRESS FIELD IS FIXED FOR ALL COUNTRIES
  // {
  //   prefix: "BE",
  //   name: "Belgium (Belgique)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "BG",
  //   name: "Bulgaria (България)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "CZ",
  //   name: "Czechia (Česká republika)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "DK",
  //   name: "Denmark (Kongeriget Danmark)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "DE",
  //   name: "Germany (Bundesrepublik Deutschland)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "EE",
  //   name: "Estonia (Eesti Vabariik)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "IE",
  //   name: "Ireland (Éire)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "EL",
  //   name: "Greece (Ελλάδα)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "ES",
  //   name: "Spain (España)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "FR",
  //   name: "France (France)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "HR",
  //   name: "Croatia (Republika Hrvatska)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "CY",
  //   name: "Cyprus (Κύπρος)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "LV",
  //   name: "Latvia (Latvijas Republika)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "LT",
  //   name: "Lithuania (Kongeriget Danmark)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "LU",
  //   name: "Luxembourg (Groussherzogtum Lëtzebuerg)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "HU",
  //   name: "Hungary (Magyarország)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "MT",
  //   name: "Malta (Repubblika ta' Malta)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "NL",
  //   name: "Netherlands (Nederland)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "AT",
  //   name: "Austria (Ésterreich)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "PL",
  //   name: "Poland (Rzeczpospolita Polska)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "PT",
  //   name: "Portugal (Portugal)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "RO",
  //   name: "Romania (România)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "SI",
  //   name: "Slovenia (Republika Slovenija)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "SK",
  //   name: "Slovakia (Slovensko)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "FI",
  //   name: "Finland (Suomi)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "SE",
  //   name: "Sweden (Sverige)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "UK",
  //   name: "United Kingdom (United Kingdom)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "IS",
  //   name: "Iceland (Lýðveldið Ísland)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "LI",
  //   name: "Liechtenstein (Liechtenstein)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "NO",
  //   name: "Norway (Kongeriket Norge)",
  //   regex: /.*/
  // },
  // {
  //   prefix: "CH",
  //   name: "Switzerland (Schweizerische Eidgenossenschaft)",
  //   regex: /.*/
  // }
];
