// Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Libs
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { CustomersRoutingModule } from './customers-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
// Components
import * as fromContainers from './containers';
import * as fromComponents from './components';
import {
  CustomerControlpanelPopupComponent
} from './components/customer-controlpanel-popup/customer-controlpanel-popup.component';
import { SelectStatusComponent } from './components/select-status/select-status.component';
import { SelectDatesComponent } from './components/select-dates/select-dates.component';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n, CustomDateParserFormatter } from './components/select-dates/select-dates.service';


@NgModule({
    declarations: [
        ...fromContainers.containers,
        ...fromComponents.components,
        SelectStatusComponent,
        SelectDatesComponent
    ],
    imports: [
        CustomersRoutingModule,
        SharedModule
    ],
    providers: [
        I18n,
        {
            provide: NgbDatepickerI18n,
            useClass: CustomDatepickerI18n
        },
        {
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter
        }
    ]
})
export class CustomersModule { }
