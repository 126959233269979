import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { USER_ROLE } from 'src/app/domains/user/models';
import { CustomersFacade } from 'src/app/domains/customers/store/facade/customers.facade';
import { Customer, CustomersResponse, CustomersSearch, CustomerStatus, CustomerSearchFilters, CustomersSearchRequest } from 'src/app/domains/customers/models/customers.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'vov-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})

export class CustomersTableComponent implements OnInit {
  @Output() onLoginCustomer = new EventEmitter<string>();
  public customers$: Observable<CustomersResponse>;
  public loading$: Observable<boolean>;
  public searchControl: UntypedFormControl = new UntypedFormControl('');
  public ROLES = USER_ROLE;
  public datePattern: string;
  public queryFilters: CustomersSearch = {};
  public options: CustomersSearch = {}

  public pagination = {
    page: 0,
    empty: true,
    totalPages: 0
  };

  constructor(private customersFacade: CustomersFacade, private activatedRoute: ActivatedRoute, private translation: TranslateService, private router: Router) { }

  ngOnInit() {

    this.customers$ = this.customersFacade.customers$;
    this.customers$.pipe(
      filter((c: CustomersResponse) => c.pageable ? true : false)
    ).subscribe((c: CustomersResponse) => {
      this.pagination = {
        page: c.pageable.pageNumber,
        empty: c.empty,
        totalPages: c.totalPages
      }
    })
    this.loading$ = this.customersFacade.loadingCustomers$;
    const options = this.parseQueryParams(this.activatedRoute.snapshot.queryParams);
    this.getData(options);
    this.queryFilters = options;
    this.changeDatePattern(this.translation.currentLang);
    this.translation.onLangChange.subscribe((l: LangChangeEvent) => {
      this.changeDatePattern(l.lang);
    })
  }

  private changeDatePattern(locale: string) {
    switch (locale) {
    case 'it':
      this.datePattern = 'dd/MM/yyyy - hh:mm aaa';
      break;
    default:
      this.datePattern = 'MM/dd/yyyy - hh:mm aaa';
    }
  }

  loginCustomer(id: string) {
    this.onLoginCustomer.emit(id);
  }

  getData(o?: CustomersSearch) {
    const options: CustomersSearch = o ? o : {
      status: CustomerStatus.CANCELLED,
    };

    this.customersFacade.getCustomers(options);
  }

  selectPageSize(event: any) {
    this.options = {...this.options, size: event, page: 0};
    this.customersFacade.getCustomers(this.options);
  }

  changeCurrentPage(event:any) {
    this.options = {...this.options, page: event};
    this.customersFacade.getCustomers(this.options);
  }

  onApplyFilters(filters: CustomerSearchFilters) {
    let options: CustomersSearch = {};
    let dateObj = null;
    Object.keys(filters).forEach((k: string) => {
      switch (k) {
        case 'name':
          if (!filters[k]) break;
          options[k] = filters[k]
          break;
        case 'reference':
          if (!filters[k]) break;
          options['agent_id'] = filters['reference'].id;
          break;
        case 'dates':
          if (!filters[k]) break;
          let date = filters[k]['creation_date_from'];
          options['creation_date_from'] = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
          date = filters[k]['creation_date_to'];
          options['creation_date_to'] = `${date.year}-${date.month.toString().padStart(2, '0')}-${(date.day).toString().padStart(2, '0')}`;
          dateObj = date;
          break;
        case 'partner':
          if (!filters[k]) break;
          options['partner_id'] = filters[k].id;
          break;
        default:
          options[k] = filters[k];
      }
    });
    this.options = options;
    this.router.navigate([], {
      queryParams: options,
      relativeTo: this.activatedRoute
    });
    if (dateObj) options['dateObj'] = dateObj
    this.customersFacade.getCustomers(options);

  }


  parseQueryParams(p: Object): CustomersSearch {
    let options: CustomersSearch = {};
    Object.keys(p).forEach((k: string) => {
      switch (k) {
        case 'name':
        case 'status':
        case 'creation_date_from':
        case 'creation_date_to':
        case 'agent_id':
        case 'partner_id':
          options[k] = p[k];
          break;
        default:
          break;
      }
    });
    return this.fixMissingDate(options);
  }

  // As our backend can't manage single date interval, this method will
  // fix the date with defaults if one part is missing.
  private fixMissingDate(options) {
    if (options['creation_date_from'] && !options['creation_date_to']) {
      let now = new Date();
      let date = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
      options['creation_date_to'] = date;
    }
    if (options['creation_date_to'] && !options['creation_date_from']) {
      let splitDate: string[] = options['creation_date_to'].split('-');
      splitDate[2] = '01';
      options['creation_date_from'] = splitDate.join('-');
    }
    return options;
  }

}
