import { Component, OnInit, forwardRef, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, distinctUntilChanged, switchMap, map, catchError, tap } from 'rxjs/operators';
import { PartnersService } from '@app/domains/partners/services/partners.service';
import { PartnersResponse, Partner } from '@app/domains/partners';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vov-select-partner',
  templateUrl: './select-partner.component.html',
  styleUrls: ['./select-partner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPartnerComponent),
      multi: true
    }
  ]
})
export class SelectPartnerComponent implements OnInit, ControlValueAccessor {

  public partner: Partner;

  onChange: any = () => { };
  onTouch: any = () => { };

  constructor(private partnersService: PartnersService) { }


  ngOnInit() {
  }

  public search = (term$: Observable<String>) => {
    return term$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term: string) => term.length > 2),
      switchMap((term: string) => {
        if (term === '') {
          return of([]);
        }
        return this.partnersService.getPartners({ search: term }).pipe(
          map((result: PartnersResponse) => result.content),
          catchError((err) => {
            console.log(err);
            return of(null)
          })
        )
      }
      )
    )
  }

  public formatPartner(p: Partner) {
    return p.name;
  }

  onModelChange(event: Partner) {
    if (event) {
      this.onChange(this.partner);
    } else {
      this.onChange(undefined);
    }
  }


  writeValue(id: string): void {
    if (id) {
      this.partnersService.getPartner(id).subscribe(
        (r: Partner) => {
          this.partner = r;
        }
      )
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

}
