// Core
import { NgModule, Optional, SkipSelf } from "@angular/core";

// Libs
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from '@ngrx/effects';

// Store
import { customersReducer } from './store';
import { CustomersEffects } from './store/effects/customers.effects';
// Services
import { CustomersService } from "./services/customers.service";

@NgModule({
  imports: [
    StoreModule.forFeature("customers", customersReducer),
    EffectsModule.forFeature([CustomersEffects])
  ],
  providers: [CustomersService]
})
export class DomainCustomersModule {
  constructor(@Optional() @SkipSelf() parentModule: DomainCustomersModule) {
    if (parentModule) {
      throw new Error(
        "DomainCustomersModule is already loaded. Import only in AppModule"
      );
    }
  }
}
