// Models
import { Customer, CustomersResponse } from '../../models/customers.model';
import { PaginationOptions } from '@app/core/config/models/api.model';
// Store
import { CustomersActions, CustomersActionTypes } from '../actions';

export interface State {
  customers: CustomersResponse;
  customer: Customer;
  loading: {
    customers: boolean,
    customer: boolean
  };
  error: {
    customers: any
    customer: any
  };
  success: {
    customers: boolean,
    customer: boolean
  }
}

export const initialState: State = {
  customers: { content: [], size: 0, sort: null, pageable: null, number: 0, empty: true, first: false, last: false, numberOfElements: 0, totalElements: 0, totalPages: 0 },
  customer: null,
  loading: {
    customers: false,
    customer: false
  },
  error: {
    customers: null,
    customer: null
  },
  success: {
    customers: false,
    customer: false
  }
};

export function reducer(state = initialState, action: CustomersActions): State {
  switch (action.type) {
    case CustomersActionTypes.GetCustomers: {
      return {
        ...state,
        loading: { ...state.loading, customers: true },
        success: { ...state.success, customers: null }
      };
    }
    case CustomersActionTypes.GetCustomersSuccess: {
      return {
        ...state,
        customers: action.payload.customers,
        loading: { ...state.loading, customers: false },
        success: { ...state.success, customers: true }
      };
    }
    case CustomersActionTypes.GetCustomersError: {
      return {
        ...state,
        error: { ...state.error, customers: action.payload.error },
        loading: { ...state.loading, customers: false },
        success: { ...state.success, customers: false }
      };
    }
    case CustomersActionTypes.AddCustomer: {
      return {
        ...state,
        loading: { ...state.loading, customer: true },
        success: { ...state.success, customer: null }
      };
    }
    case CustomersActionTypes.AddCustomerSuccess: {
      return {
        ...state,
        customer: action.payload.customer,
        loading: { ...state.loading, customer: false },
        success: { ...state.success, customer: true }
      };
    }
    case CustomersActionTypes.AddCustomerError: {
      return {
        ...state,
        customer: null,
        error: { ...state.error, customer: action.payload.error },
        loading: { ...state.loading, customer: false },
        success: { ...state.success, customer: false }
      };
    }
    case CustomersActionTypes.UpdateCustomer: {
      return {
        ...state,
        loading: { ...state.loading, customer: true },
        success: { ...state.success, customer: null }
      };
    }
    case CustomersActionTypes.UpdateCustomerSuccess: {
      return {
        ...state,
        customer: action.payload.customer,
        loading: { ...state.loading, customer: false },
        success: { ...state.success, customer: true }
      };
    }
    case CustomersActionTypes.UpdateCustomerError: {
      return {
        ...state,
        customer: null,
        error: { ...state.error, customer: action.payload.error },
        loading: { ...state.loading, customer: false },
        success: { ...state.success, customer: false }
      };
    }

    default:
      return state;
  }
}
