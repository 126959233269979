// Core
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Environments
import { environment } from 'src/environments/environment';

// Models
import { PartnersResponse, Partner, PartnerSendEmailRequest, PartnersSearch } from '../models/partners.model';


@Injectable()
export class PartnersService {
  constructor(private http: HttpClient) { }

  getPartners(options: PartnersSearch): Observable<PartnersResponse> {
    return this.http.get<PartnersResponse>(`${environment.apiEndpoint}/api/v1/partners`, { params: options as any });
  }

  getPartner(id: string) {
    return this.http.get(`${environment.apiEndpoint}/api/v1/partners/${id}`);
  }

  getPartnerUsers(id: string) {
    return this.http.get(`${environment.apiEndpoint}/api/v1/partners/${id}/users`);
  }

  deletePartner(id: string) {
    return this.http.delete(`${environment.apiEndpoint}/api/v1/partners/${id}`);
  }

  addPartner(p: Partner) {
    return this.http.post(`${environment.apiEndpoint}/api/v1/partners`, p);
  }

  updatePartner(p: Partner) {
    return this.http.put(`${environment.apiEndpoint}/api/v1/partners/${p.id}`, p);
  }

  updatePartnerLogo(data: Blob | null, id: string) {
    if (data === null) {
      return this.http.put(`${environment.apiEndpoint}/api/v1/partners/${id}/partner-logo`, null);
    } else {
      const formData = new FormData();
      const extension = data.type.split('/')[1];
      const fileName = `partner-logo-${(10e10 * Math.random()).toFixed(9)}`;
      formData.append('file', data, `${fileName}.${extension}`);
      return this.http.put(`${environment.apiEndpoint}/api/v1/partners/${id}/partner-logo`, formData);
    }
  }

  getPartnerResellerLink(partnerId: string, userId: string) {
    return this.http.get(`${environment.apiEndpoint}/api/v1/partners/${partnerId}/users/${userId}/reselling-link`);
  }

  sendEmail(id: string, data: PartnerSendEmailRequest) {
    return this.http.post(`${environment.apiEndpoint}/api/v1/partners/${id}/send-referral`, data);
  }

  updatePartnerDrivelink(id: string, google_drive_link: string) {
    return this.http.put(`${environment.apiEndpoint}/api/v1/partners/${id}/google-drive-link?link=${google_drive_link}`, {});
  }
}
