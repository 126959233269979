// Core
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Rxjs
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Libs
import { Store } from '@ngrx/store';

import { AuthState, Logout } from '../store';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  status = [401, 403];
  constructor(private store: Store<AuthState>, private modal: NgbModal) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpEvent<any>) => {
        if (error instanceof HttpErrorResponse) {
          if (this.status.includes(error.status)) {
            this.modal.dismissAll('4XX error, closing');
            this.store.dispatch(new Logout());
          }
        }
        return throwError(error);
      })
    );
  }
}
