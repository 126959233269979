<form class="activate-user-form" [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group">
    <label class="vox-field-label" for="password" translate>activate-user.form.password.label</label>
    <div class="vox-input-group">
      <vox-password-input formControlName="password" [language]="language"></vox-password-input>
    </div>
  </div>
  <div class="form-group">
    <label class="vox-field-label" translate>activate-user.form.repeat-password.label</label>
    <div class="vox-input-group">
      <input formControlName="repeatPassword"
        class="vox-text-field"
        type="password"
        [placeholder]="'activate-user.form.password.placeholder' | translate"/>
    </div>
  </div>
  <div class="form-group">
    <label class="vox-field-label" for="password" translate>activate-user.form.language.label</label>
    <div class="vox-input-group language-dropdown">
      <div class="vox-dropdown" ngbDropdown placement="bottom-left">
        <div class="vox-text-field text-left btn" id="language-dropdown" ngbDropdownToggle>
          {{'language.' + language | translate}}
        </div>
        <div ngbDropdownMenu>
          <div ngbDropdownItem
            *ngFor="let lang of languages"
            (click)="setLanguage(lang.locale)">
            {{ "language." + lang.locale | translate }}
          </div>
        </div>
      </div>
      <i class="vox-text-field__icon vox-text-field__icon_valid"></i>
    </div>
  </div>
  <button class="vox-btn vox-btn-primary" type="submit" [disabled]="!form.valid" translate>
    activate-user.form.activate
  </button>
</form>
