import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserFacade } from '@app/domains/user/store/facade';
import { USER_ROLE, User } from '@app/domains/user/models/';


@Directive({
  selector: '[ifCurrentUser]'
})

export class IfCurrentUserDirective {
  private currentUser$: Observable<User>;
  private _reverse = false;

  constructor(
    private facade: UserFacade,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.currentUser$ = facade.user$;
  }

  private draw() {
    if (this.viewContainer.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  @Input() set ifCurrentUser(data: { user: User | User[], reverse?: boolean }) {
    if (data.reverse == undefined) {
      data.reverse = false;
    }
    this.currentUser$.subscribe((u: User) => {
      let list = (
        (data.user instanceof Array) ? data.user : [data.user]
      ).map(user => user.id);

      if (data.reverse) {
        if (list.includes(u.id)) {
          this.viewContainer.clear();
        } else {
          this.draw();
        }
      } else {
        if (list.includes(u.id)) {
          this.draw();
        } else {
          this.viewContainer.clear();
        }
      }
    });
  }

}
