// Core
import { NgModule, Optional, SkipSelf } from "@angular/core";

// Ngrx
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  NavigationActionTiming,
  StoreRouterConnectingModule
} from "@ngrx/router-store";

// Env
import { environment } from "src/environments/environment";

// Store
import { reducer, RouterEffects, CustomRouterStateSerializer } from "./router";
import { metaReducers } from "./metareducer";

// Modules
import { DomainAuthModule } from "./auth/auth.module";
import { DomainUserModule } from "./user/user.module";
import { DomainPartnersModule } from "./partners/partners.module";
import { DomainAgentsModule } from "./agents/agents.module";
import { DomainCustomersModule } from "./customers/customers.module";
import { DomainDashboardModule } from './dashboard/dashboard.module';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducer, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([RouterEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      serializer: CustomRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    StoreDevtoolsModule.instrument({
      name: "Voverc",
      maxAge: 10,
      logOnly: environment.production
    }),
    DomainAuthModule,
    DomainUserModule,
    DomainPartnersModule,
    DomainAgentsModule,
    DomainCustomersModule,
    DomainDashboardModule
  ]
})
export class DomainsModule {
  constructor(@Optional() @SkipSelf() parentModule: DomainsModule) {
    if (parentModule) {
      throw new Error(
        "DomainsModule is already loaded. Import only in AppModule"
      );
    }
  }
}
