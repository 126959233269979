<div class="quantity-selector">
    <div class="subtract-container">
        <button type="button" (click)="subtractStep()" [disabled]="value === _min.toString() || isDisabled" class="subtract-btn vox-btn vox-btn-secondary">
            <i class="icon-small-remove"></i>
        </button>
    </div>
    <div class="input-container vox-input-group vox-input-group_dense">
        <input [disabled]="isDisabled" (keydown)="onKeydown($event)" [(ngModel)]="value" (ngModelChange)="onModelChange($event)"  class="vox-text-field" type="number" min="_min" max="_max" step="1">
    </div>
    <div class="add-container">
        <button type="button" (click)="addStep()" [disabled]="value === _max.toString() || isDisabled" class="add-btn vox-btn vox-btn-secondary">
            <i class="icon-small-add"></i>
        </button>
    </div>
</div>
