// Store
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { OnboardingState, State } from "../reducers";
import { State as PartnerDataState } from "src/app/domains/partner-data/store/reducers";
import { State as CompanyDataState } from "src/app/domains/company-data/store/reducers";
import { State as CompanyOtherDataState } from "src/app/domains/company-other-data/store/reducers";

import { selectPartnerDataStatus } from "src/app/domains/partner-data/store/selectors/partner-data.selector";
import { selectCompanyDataStatus } from "src/app/domains/company-data/store/selectors/company-data.selector";
import { selectCompanyOtherDataStatus } from "src/app/domains/company-other-data/store/selectors/company-other-data.selector";

// Models
import { Partner } from "../../models/onboarding.model";

export const selectOnboardingState = createFeatureSelector<OnboardingState>(
  "onboarding"
);

export const selectOnboardingStatus = createSelector(
  selectOnboardingState,
  (state: OnboardingState) => state.status
);

export const selectPartnerData = createSelector(
  selectOnboardingStatus,
  (state: State) => state.partner_data
);

export const selectCompanyData = createSelector(
  selectOnboardingStatus,
  (state: State) => state.company_data
);

export const selectCompanyOtherData = createSelector(
  selectOnboardingStatus,
  (state: State) => state.company_other_data
);

export const selectLoading = createSelector(
  selectOnboardingStatus,
  (state: State) => state.loading
);

export const buildFullObject = createSelector(
  selectPartnerDataStatus,
  selectCompanyDataStatus,
  selectCompanyOtherDataStatus,
  (
    partnerDataStatus: PartnerDataState,
    companyDataStatus: CompanyDataState,
    companyOtherDataStatus: CompanyOtherDataState
  ) => {
    let partnerInformations: Partner = {
      full_name: partnerDataStatus.full_name,
      email: partnerDataStatus.email,
      phone: partnerDataStatus.phone,
      company_name: companyDataStatus.company_name,
      country: companyDataStatus.country,
      city: companyDataStatus.city,
      company_website: companyDataStatus.company_website,
      company_type: companyDataStatus.company_type,
      telecomunications: companyOtherDataStatus.telecomunications,
      company_size: companyOtherDataStatus.companies_size,
      subscriptions: companyOtherDataStatus.subscriptions,
      companies_size: companyOtherDataStatus.companies_size,
      customers_ready: companyOtherDataStatus.customers_ready
    };
    return partnerInformations;
  }
);

export const selectError = createSelector(
  selectOnboardingStatus,
  (state: State) => state.error
);
