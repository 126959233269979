import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/domains/user/services/user.service";
import { UserFacade, User } from "src/app/domains/user";
import { IntercomChatService } from "@app/core/intercom/services/intercom-chat.service";

@Component({
  selector: "vov-layout-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class LayoutMainComponent implements OnInit {
  onClose: boolean = true;

  constructor(
    private userService: UserService,
    private userFacade: UserFacade,
    private chatService: IntercomChatService
  ) {}

  ngOnInit() {
    const jwt = JSON.parse(localStorage.getItem("auth"))?.status.token.token;
    const tokens = jwt?.split(".");
    let email;
    if (tokens && tokens.length >= 2) {
      email = JSON.parse(atob(tokens[1])).sub;
    } else {
        console.error("Tokens array is undefined or does not have at least 2 elements");
    }
    this.userService.getUser(email).subscribe((response: User) => {
      this.userFacade.setUser(response);
    });
    this.userFacade.user$.subscribe(user => {
      this.chatService.loginUser(user.first_name + user.last_name, user.email);
    });
  }

  onCloseMenu(closing) {
    if (window.innerWidth <= 1000) this.onClose = !this.onClose;
  }
}
