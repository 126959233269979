import { Agent } from '@app/domains/agents/models/agents.model';
import { Partner } from '@app/domains/partners/models/partners.model';

export enum CustomerStatus {
  CANCELLED = 'CANCELLED',
  IN_TRIAL = 'IN_TRIAL',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  UNKNOWN = 'UNKNOWN',
  FUTURE = 'FUTURE',
  NOT_RENEWING = 'NOT_RENEWING'
}

export interface Customer {
  partner: { id: string } | Partner;
  name: string;
  email: string;
  status: CustomerStatus;
  creation_date: Date | string;
  company_id: string;
  reference_agent: { id: string } | Agent;
  other_agents: { id: string }[];
  remote_access: boolean;
}

export interface CustomersResponse {
  content: Customer[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: Sort;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  pageSize: number;
  pageNumber: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface CustomersSearchRequest extends CustomersSearch {
  partner_id?: string;
}
export interface CustomersSearch {
  name?: string;
  email?: string;
  status?: CustomerStatus;
  creation_date_from?: string;
  creation_date_to?: string;
  agent_id?: string;
  page?: number;
  size?: number;
}

export interface CustomerSearchFilters {
  name?: string;
  status?: CustomerStatus;
  reference?: Agent;
  creation_date_from?: DatePickerModel;
  creation_date_to?: DatePickerModel;
}

export interface DatePickerModel {
  year: number;
  month: number;
  day: number;
}
