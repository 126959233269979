import { Action } from '@ngrx/store';
import { Credentials, AuthToken } from '../../models';

export enum AuthActionTypes {
  Login = '[Auth] Page Login',
  LoginFailure = '[Auth] Api Login Failure',
  LoginJustLogged = '[Auth] Guard Login Just Logged',
  LoginReset = '[Auth] Guard Login Reset',
  LoginSuccess = '[Auth] Api Login Success',
  Logout = '[Auth] Page Logout',
  LogoutFailure = '[Auth] Api Logout Failure',
  LogoutSuccess = '[Auth] Api Logout Success'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(readonly payload: { credentials: Credentials }) { }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;
  constructor(readonly payload: { error: any }) { }
}

export class LoginJustLogged implements Action {
  readonly type = AuthActionTypes.LoginJustLogged;
}

export class LoginReset implements Action {
  readonly type = AuthActionTypes.LoginReset;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(readonly payload: { token: AuthToken }) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
  constructor(readonly payload?: { message?: string }) { }
}

export type AuthActions =
  | Login
  | LoginFailure
  | LoginJustLogged
  | LoginReset
  | LoginSuccess
  | Logout;
