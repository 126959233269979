// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Libs
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgVoxloudModule } from "ng-voxloud";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LaddaModule } from "angular2-ladda";

// Components
import { SharedComponentsModule } from "./components/components.module";
// Directives
import { SharedDirectivesModule } from "./directives/directives.module";
// Pipes
import { SharedPipesModule } from "./pipes/pipes.module";

@NgModule({
  imports: [
    SharedComponentsModule,
    SharedDirectivesModule
  ],
  exports: [
    SharedPipesModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    // re-share dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    NgVoxloudModule,
    LaddaModule
  ]
})
export class SharedModule { }
