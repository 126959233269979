import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/shared/services/notification.service';


@Component({
  selector: 'vov-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {
  public DEFAULT_DELAY = 15000;
  constructor(public notificationService: NotificationService) {}

  ngOnInit() {
  }


}
