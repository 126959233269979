import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Agent } from '@app/domains/agents';

@Component({
  selector: 'vov-select-agent',
  templateUrl: './select-agent.component.html',
  styleUrls: ['./select-agent.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAgentComponent),
      multi: true
    }
  ]
})
export class SelectAgentComponent implements ControlValueAccessor {

  public selectedAgent: Agent;
  public agents: Agent[];

  @Input() set agentsList(agents: Agent[]) {
    this.agents = agents;
    if (this.agents.length === 0) {
      this.selectedAgent =
        {
          first_name: 'customers.filters.no-agent',
          last_name: ''
        } as any;
    } else {
      this.selectedAgent = this.agents[0];
    }
  };

  onChange: any = () => { }
  onTouch: any = () => { }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(val: Agent) {
    if (!val) {
      this.selectedAgent = {
        first_name: 'customers.filters.no-agents',
        last_name: ''
      } as any
    } else {
      this.selectedAgent = val;
    }
  }

  public selectReferenceAgent(agent: Agent) {
    this.selectedAgent = agent;
    this.onChange(agent);
  }


}