import { HttpErrorResponse } from "@angular/common/http";

import { throwError } from "rxjs";

import { Logger } from "../../core/logger";

const log = new Logger("ErrorHandlerService");

export const httpErrorHandler = (error: HttpErrorResponse) => {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    log.error("An error occurred:", error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    log.error(
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    );
  }
  // return an observable with a user-facing error message
  return throwError("Something bad happened; please try again later.");
};
