// Core
import { Injectable } from '@angular/core';

// Libs
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

// Models
import { Partner, PartnersSearch } from '../../models/partners.model';

// Store
import { State } from '../reducers';
import { GetPartners, DeletePartner, AddPartner, UpdatePartner, UpdatePartnerLogo, UpdatePartnerGoogleDriveLink } from '../actions';
import {
  selectPartnersOptions,
  selectPartners,
  selectPartner,
  selectLoading,
  selectLoadingPartners,
  selectLoadingPartner,
  selectSuccessPartner,
  selectError,
  selectErrorPartners,
  selectErrorPartner,
  selectSuccessPartners
} from '../selectors';

@Injectable({
  providedIn: 'root'
})
export class PartnersFacade {
  loading$ = this.store.pipe(select(selectLoading));
  error$ = this.store.pipe(select(selectError));

  partner$ = this.store.pipe(select(selectPartner));
  errorPartner$ = this.store.pipe(select(selectErrorPartner));
  loadingPartner$ = this.store.pipe(select(selectLoadingPartner));
  successPartner$ = this.store.pipe(select(selectSuccessPartner));

  partners$ = this.store.pipe(select(selectPartners));
  loadingPartners$ = this.store.pipe(select(selectLoadingPartners));
  errorPartners$ = this.store.pipe(select(selectErrorPartners));
  successPartners$ = this.store.pipe(select(selectSuccessPartners));

  constructor(private store: Store<State>) {}

  getPartners(options: PartnersSearch) {
    this.store.dispatch(new GetPartners({ options }));
  }

  refreshPartners() {
    this.store.
      pipe(select(selectPartnersOptions), take(1))
      .subscribe(options => {
        this.store.dispatch(new GetPartners({ options }));
      });
  }
  
  deletePartner(id: string) {
    this.store.dispatch(new DeletePartner({ id }));
  }

  addPartner(partner: Partner) {
    this.store.dispatch(new AddPartner({ partner }));
  }

  updatePartner(partner: Partner) {
    this.store.dispatch(new UpdatePartner({ partner }));
  }

  updatePartnerLogo(data: Blob | null, id: string) {
    this.store.dispatch(new UpdatePartnerLogo({ data, id }));
  }

  updatePartnerDrivelink(id: string, data: string) {
    this.store.dispatch( new UpdatePartnerGoogleDriveLink({id, data}) );
  }
}
