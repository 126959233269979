<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" translate>partners.edit-popup.title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
      <i class="icon-small-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="content">
      <vov-edit-partner-form (onClose)="close()" [data]="partner" #form></vov-edit-partner-form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="vox-btn vox-btn-secondary-grey" (click)="close()" translate>
      partners.edit-popup.cancel
    </button>
    <button type="button" class="vox-btn vox-btn-secondary" [disabled]="!form.canSubmit()" (click)="savePartner()" translate>
      partners.edit-popup.update
    </button>
  </div>
</div>
