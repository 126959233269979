import { Component, OnInit, Input } from '@angular/core';
import { User } from '@app/domains/user/models/user.model';

@Component({
  selector: 'vov-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnInit {
  @Input() user: User;
  @Input() size: string;
  // available sizes: micro, xs, sm
  public sizeClass: string;

  constructor() {}

  ngOnInit() {
    if (this.size) {
      this.sizeClass = `vox-avatar-circular-${this.size}`;
    } else {
      this.sizeClass = '';
    }
  }

  get initials() {
    if (this.user?.first_name && this.user?.last_name) {
      return `${this.user?.first_name[0]}${this.user?.last_name[0]}`;
    } else {
      return '';
    }
  }
}
