// Ngrx
import { MetaReducer, ActionReducer } from '@ngrx/store';

// Storage
import { localStorageSync } from 'ngrx-store-localstorage';

// Env
import { environment } from '../../environments/environment';

import { AuthActionTypes } from './auth/store/index';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ['auth'], rehydrate: true })(reducer);
}

export function clearState(reducer: Function) {
  return function(state: any, action: any) {
    if (action.type === AuthActionTypes.Logout) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

const metaReducersList = [localStorageSyncReducer, clearState];
if (!environment.production) {
  // metaReducersList.push(storeFreeze);
}

export const metaReducers: Array<MetaReducer<any, any>> = metaReducersList;
