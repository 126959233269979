export interface ContactPhone {
  country: string;
  flagClass: string;
  number: string;
}

export const CONTACT_PHONES: ContactPhone[] =  [
  {
    country: 'it',
    flagClass: 'flag-icon-it',
    number: '+39 06 97631527'
  },
  {
    country: 'gb',
    flagClass: 'flag-icon-gb',
    number: '+44 20 37691920'
  },
  {
    country: 'us',
    flagClass: 'flag-icon-us',
    number: '+1 917 9098338'
  }
];
