import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { europeanVatsInfo } from '@app/core/config/values/vats.value';
import { Observable } from 'rxjs';
import { User, USER_ROLE } from '@app/domains/user/models/user.model';
import { Partner, Address } from '@app/domains/partners/models/partners.model';
// TODO: uncomment when clarified about contacts
// import { UserService } from '@app/domains/user/services/user.service';
import { UserFacade } from '@app/domains/user/store/facade/user.facade';
import { PartnersFacade } from '@app/domains/partners/store/facade/partners.facade';
import { environment } from '@src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vov-edit-partner-form',
  templateUrl: './edit-partner-form.component.html',
  styleUrls: ['./edit-partner-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditPartnerFormComponent implements OnInit {
  @Input('data') data: Partner;
  @Output() onClose = new EventEmitter<any>();
  public form: UntypedFormGroup;
  public vatsInfo = europeanVatsInfo;
  public vatCountry = 'IT';
  public users$: Observable<User[]>;
  public error$: Observable<any>;
  public loading$: Observable<boolean>;
  public googlePlacesApiKey: string = environment.googlePlacesApiKey;
  public invalidVat: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private userFacade: UserFacade,
    // private userService: UserService,
    private partnersFacade: PartnersFacade,
    private cdRef: ChangeDetectorRef,
    public translationService: TranslateService
  ) { }

  ngOnInit() {
    this.userFacade.getUsers(USER_ROLE.SUPERVISOR);
    this.users$ = this.userFacade.users$;
    this.error$ = this.partnersFacade.errorPartner$;
    this.loading$ = this.partnersFacade.loadingPartner$;

    this.initForm(this.data);
  }

  initForm(p: Partner) {
    const address: Address = p?.address;
    const isAddressEmpty = [null, undefined].includes(p?.address);

    const addressString = isAddressEmpty
      ? ''
      : `${address.address_line_1}, ${address.city}, ${address.province}, ${address.zip}`;

    const vat = isAddressEmpty
      ? { country: this.vatCountry, vat: p?.vat }
      : { country: p?.address.country, vat: p?.vat };
    this.form = this.fb.group({
      name: new UntypedFormControl(p?.name, null),
      vat: new UntypedFormControl(vat, null),
      email: new UntypedFormControl(p?.email, Validators.email),
      address: new UntypedFormControl(addressString, null),
      // TODO: uncomment when clarified
      // contacts: new FormControl([])
    });

    this.form.get('vat')
      .valueChanges
      .subscribe(
        (val: { country: string, vat: string }) => {
          if (val.country !== this.vatCountry) {
            this.form.controls.address.setValue('');
          }
          this.vatCountry = val.country;
        }
      );

    // I had to take out the email validation because it was giving a false positive
    // This code makes so the initial change of form.valid will not happen after
    // angular already checked giving the
    // expression changed after it was checked error
    this.cdRef.detectChanges();
  }

  public canSubmit() {
    const nameValid = this.form.controls.name.value?.length > 1;
    const emailValid = this.form.controls.email.valid;
    const vatValid = this.form.controls.vat.valid;
    const addressValid = this.form.controls.address.errors === null || this.form.controls.address.errors.select_from_list;
    return nameValid && emailValid && vatValid && addressValid;
  }


  // Not urgent but the email check for Partners email (not user)
  // is in backlog
  //
  // validateEmail(userService: UserService) {
  //   return (input: FormControl) => {
  //     return timer(300).pipe(
  //       switchMap(() => userService.getUser(input.value)),
  //       map(res => {
  //         // user found -> duplicate email
  //         return res ? { emailExists: true } : null;
  //       }),
  //       catchError(err => {
  //         // user not found -> valid
  //         return err.status === 404 ? of(null) : of({ emailExists: true });
  //       })
  //     );
  //   };
  // }


  get name() { return this.form.get('name'); }
  get email() { return this.form.get('email'); }
  get address() { return this.form.get('address'); }
  get vat() { return this.form.get('vat'); }
  // TODO: uncomment when clarified
  // get contacts() { return this.form.get('contacts'); }
  public get valid() { return this.form.valid };

  public onCheckVatErrors(event: boolean) {
    if (!event) {
      if (this.form.get("vat").invalid) {
        this.invalidVat = true;
      } else if (this.form.get("vat").value && this.form.get("vat").value.vat === '') {
        this.invalidVat = true;
      } else {
        this.invalidVat = false;
      }
    } else {
      this.invalidVat = false;
    }
  }

  private getProvince(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return gMapsAddress.find(
      el => el.types.includes('administrative_area_level_1'))
      .short_name;
  }

  private getCity(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return gMapsAddress.find(
      el => el.types.includes('locality') || el.types.includes('administrative_area_level_3'))
      .short_name;
  }

  private getZip(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return gMapsAddress.find(
      el => el.types.includes('postal_code'))
      .short_name;
  }

  private getAddressLine(gMapsAddress: google.maps.GeocoderAddressComponent[]) {
    const street = gMapsAddress.find((el) => el.types.includes('route')).short_name;
    const streetNumber = gMapsAddress.find((el) => el.types.includes('street_number')).short_name;
    return `${streetNumber}, ${street}`;
  }

  public savePartner() {
    const address = this.form.value.address ? {
        address_line_1: this.getAddressLine(this.form.value.address),
        country: this.vatCountry,
        city: this.getCity(this.form.value.address),
        province: this.getProvince(this.form.value.address),
        zip: this.getZip(this.form.value.address)
    } : null;
    const data = {
      address,
      // TODO: uncomment when clarified
      // contacts: this.form.controls.contacts.value.map(c => ({id: c.id})),
      contacts: null,
      email: this.form.controls.email.value,
      name: this.form.controls.name.value,
      vat: this.form.controls.vat.value.vat,
    };
    if (this.data.id) {
      this.partnersFacade.updatePartner({ ...data, id: this.data.id });
    } else {
      this.partnersFacade.addPartner(data);
    }
    this.loading$.subscribe((loading) => {
      this.error$.subscribe(error => {
        if (!loading && !error) {
          this.onClose.emit();
        }
      });
    });
  }

}
