// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Libs
import { TranslateModule } from "@ngx-translate/core";

// Modules
import { PartnersRoutingModule } from "./partners-routing.module";
import { SharedModule } from 'src/app/shared/shared.module';
// Components
import * as fromContainers from "./containers";
import * as fromComponents from "./components";
import { AddPartnerPopupComponent } from './components/add-partner-popup/add-partner-popup.component';
import { EditPartnerPopupComponent } from './components/edit-partner-popup/edit-partner-popup.component';
import { AddAdminPopupComponent } from './components/add-admin-popup/add-admin-popup.component';
import { AgentsTableModalComponent } from './components';
import { AddEditLinkPopupComponent } from './components/add-edit-link-popup/add-edit-link-popup.component';
import { LaddaModule } from "angular2-ladda";

@NgModule({
    declarations: [
        ...fromContainers.containers,
        ...fromComponents.components,
        AddEditLinkPopupComponent
    ],
    imports: [
        PartnersRoutingModule,
        SharedModule,
        LaddaModule.forRoot({
            spinnerColor: 'grey',
        })
    ]
})
export class PartnersModule { }
