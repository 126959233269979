import { PaginatedResponse } from '@app/core/config/models/api.model';
import { Partner } from '@app/domains/partners/models/partners.model';

export enum USER_ROLE {
  SUPERVISOR = 'ROLE_SUPERVISOR',
  ADMIN = 'ROLE_ADMIN',
  AGENT = 'ROLE_AGENT',
  ALL = 'ALL'
}

export interface UserActivateRequest {
  language: string;
	activation_token: string;
  password: string;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  phone: string;
  roles: USER_ROLE[];
  enabled: boolean;
  locked: boolean;
  expired: boolean;
  credentials_expired: boolean;
  avatar: string;
  partner?: Partner;
  global_google_drive_link?: string;
  partner_google_drive_link?: string;
}

export interface UserProfile {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  language: string;
}

export interface ChangePasswordRequest {
  new_password: string;
  old_password: string;
}

export interface UsersResponse extends PaginatedResponse {
  content: User[];
}

export interface ResetPasswordRequest {
  token: string;
  new_password: string;
}

export interface GlobalProperty {
  name: string;
  value: string;
}

export interface GlobalPropertyResponse extends GlobalProperty{
  updated_at: Date;
}