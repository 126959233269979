import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  OnboardingPartnerDataFormComponent,
  OnboardingCompanyDataFormComponent,
  OnboardingCompanyOtherDataFormComponent,
  OnboardingThanksPageComponent
} from "./components";
import { OnboardingMainComponent } from "./containers";

export const routes: Routes = [
  {
    path: "",
    component: OnboardingMainComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "1" },
      { path: "1", component: OnboardingPartnerDataFormComponent },
      { path: "2", component: OnboardingCompanyDataFormComponent },
      { path: "3", component: OnboardingCompanyOtherDataFormComponent },
      { path: "4", component: OnboardingThanksPageComponent }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule {}
