// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LocalizedDatePipe } from './localized-date/localized-date.pipe';
import { SafePipe } from "./safe/safe.pipe";
const PIPES = [
  LocalizedDatePipe,
  SafePipe
];

@NgModule({
  declarations: PIPES,
  exports: PIPES
})
export class SharedPipesModule {}
