import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { State } from '../reducers/partners.reducer';
import { PartnersResponse, Partner, PartnersSearch } from '../../models/partners.model';
import { PartnersService } from '../../services/partners.service';
import { selectPartnersStatus } from '../selectors/partners.selectors';
import {
  PartnersActionTypes,
  GetPartners,
  GetPartnersSuccess,
  GetPartnersError,
  DeletePartner,
  DeletePartnerSuccess,
  DeletePartnerError,
  AddPartner,
  AddPartnerSuccess,
  AddPartnerError,
  UpdatePartner,
  UpdatePartnerSuccess,
  UpdatePartnerError,
  UpdatePartnerLogo,
  UpdatePartnerLogoSuccess,
  UpdatePartnerLogoError,
  UpdatePartnerGoogleDriveLink,
  UpdatePartnerGoogleDriveLinkSuccess,
  UpdatePartnerGoogleDriveLinkError
} from '../actions';

@Injectable()
export class PartnersEffects {

  @Effect()
  getPartners$ = this.actions$.pipe(
    ofType(PartnersActionTypes.GetPartners),
    map((action: GetPartners) => action.payload.options),
    switchMap((options: PartnersSearch) => {
      return this.service.getPartners(options).pipe(
        map((response: PartnersResponse ) => new GetPartnersSuccess({ partners: response })),
        catchError(error => of(new GetPartnersError({ error })))
      );
    })
  );

  @Effect()
  deletePartner$ = this.actions$.pipe(
    ofType(PartnersActionTypes.DeletePartner),
    map((action: DeletePartner) => action.payload.id),
    switchMap((id: string) => {
      return this.service.deletePartner(id).pipe(
        map((response: any ) => new DeletePartnerSuccess()),
        catchError(error => of(new DeletePartnerError({ error })))
      );
    })
  );

  @Effect()
  deletePartnerSuccess$ = this.actions$.pipe(
    ofType(PartnersActionTypes.DeletePartnerSuccess),
    withLatestFrom(this.store$.select(selectPartnersStatus)),
    map((args: [DeletePartnerSuccess, State]) => {
      const options: any = args[1].partnersOptions;
      return new GetPartners({ options });
    })
  );


  @Effect()
  addPartner$ = this.actions$.pipe(
    ofType(PartnersActionTypes.AddPartner),
    map((action: AddPartner) => action.payload.partner),
    switchMap((partner: Partner) => {
      return this.service.addPartner(partner).pipe(
        map((response: Partner) => new AddPartnerSuccess({ partner: response})),
        catchError(error => of(new AddPartnerError({ error })))
      );
    })
  );

  @Effect()
  addPartnerSuccess$ = this.actions$.pipe(
    ofType(PartnersActionTypes.AddPartnerSuccess),
    withLatestFrom(this.store$.select(selectPartnersStatus)),
    map((args: [AddPartnerSuccess, State]) => {
      const options: any = args[1].partnersOptions;
      return new GetPartners({ options });
    })
  );

  @Effect()
  updatePartner$ = this.actions$.pipe(
    ofType(PartnersActionTypes.UpdatePartner),
    map((action: AddPartner) => action.payload.partner),
    switchMap((partner: Partner) => {
      return this.service.updatePartner(partner).pipe(
        map((response: Partner) => new UpdatePartnerSuccess({ partner: response})),
        catchError(error => of(new UpdatePartnerError({ error })))
      );
    })
  );

  @Effect()
  updatePartnerSuccess$ = this.actions$.pipe(
    ofType(PartnersActionTypes.UpdatePartnerSuccess),
    withLatestFrom(this.store$.select(selectPartnersStatus)),
    map((args: [UpdatePartnerSuccess, State]) => {
      const options: any = args[1].partnersOptions;
      return new GetPartners({ options });
    })
  );

  @Effect()
  updatePartnerLogo$ = this.actions$.pipe(
    ofType(PartnersActionTypes.UpdatePartnerLogo),
    map((action: UpdatePartnerLogo) => action.payload),
    switchMap((d: {data: Blob | null, id: string}) => {
      return this.service.updatePartnerLogo(d.data,d.id).pipe(
        map((partner: Partner) => new UpdatePartnerLogoSuccess({partner})),
        catchError(error => of(new UpdatePartnerLogoError(error)))
      );
    })
  );

  @Effect()
  updatePartnerGoogleDriveLink$ = this.actions$.pipe(
    ofType(PartnersActionTypes.UpdatePartnerGoogleDriveLink),
    map((action: UpdatePartnerGoogleDriveLink) => action.payload),
    switchMap((d: {data: string, id: string}) => {
      return this.service.updatePartnerDrivelink(d.id, d.data).pipe(
        map((partner: Partner) => new UpdatePartnerGoogleDriveLinkSuccess({partner})),
        catchError(error => of(new UpdatePartnerGoogleDriveLinkError(error)))
      )
    })
  )

  @Effect()
  updatePartnerGoogleDriveLinkSuccess$ = this.actions$.pipe(
    ofType(PartnersActionTypes.UpdatePartnerGoogleDriveLinkSuccess),
    withLatestFrom(this.store$.select(selectPartnersStatus)),
    map((args: [UpdatePartnerSuccess, State]) => {
      const options: any = args[1].partnersOptions;
      return new GetPartners({ options });
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private service: PartnersService,
  ) {}
}
