import { Action } from "@ngrx/store";
import { State } from "../reducers";

export enum CompanyOtherDataActionTypes {
  SaveCompanyOtherData = "[Company Other Data] Save Company Other Data"
}

export class SaveCompanyOtherData implements Action {
  readonly type = CompanyOtherDataActionTypes.SaveCompanyOtherData;
  constructor(readonly companyOtherData: State) {}
}

export type CompanyOtherDataActions = SaveCompanyOtherData;
