import {
  CompanyDataActions,
  CompanyDataActionTypes
} from "../actions/company-data.action";

export interface State {
  company_name: string;
  country: string;
  city: string;
  company_website: string;
  company_type: string;
}

export const initialState: State = {
  company_name: "",
  country: "",
  city: "",
  company_website: "",
  company_type: ""
};

export function reducer(
  state: State = initialState,
  action: CompanyDataActions
) {
  switch (action.type) {
    case CompanyDataActionTypes.SaveCompanyData:
      return {
        ...state,
        company_name: action.companyData.company_name,
        country: action.companyData.country,
        city: action.companyData.city,
        company_website: action.companyData.company_website,
        company_type: action.companyData.company_type
      };
    default:
      return state;
  }
}
