import { Action } from "@ngrx/store";
import { State } from "../reducers";

export enum CompanyDataActionTypes {
  SaveCompanyData = "[Company Data] Save Company Data"
}

export class SaveCompanyData implements Action {
  readonly type = CompanyDataActionTypes.SaveCompanyData;
  constructor(readonly companyData: State) {}
}

export type CompanyDataActions = SaveCompanyData;
