import { NgModule } from "@angular/core";
import { partnerDataReducer } from "./store/reducers";
import { PartnerDataFacade } from "./store/facade/partner-data.facade";
import { StoreModule } from "@ngrx/store";

@NgModule({
  imports: [StoreModule.forFeature("partner-data", partnerDataReducer)],
  providers: [PartnerDataFacade]
})
export class PartnerDomainModule {}
