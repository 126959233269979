// Libs
import { Action } from "@ngrx/store";

// Models
import { User, UserActivateRequest, USER_ROLE, GlobalProperty } from "../../models/user.model";

export enum UserActionTypes {
  LanguageSelect = "[User] Page LanguageSelect",
  GetUser = "[User] Page GetUser",
  GetUsers = "[User] Page GetUsers",
  GetUsersSuccess = "[User] Page GetUsers Success",
  GetUsersError = "[User] Page GetUsers Error",
  UpdateUserAvatar = "[User] Page Update User Avatar",
  UpdateUserAvatarSuccess = "[User] Page Update User Avatar Success",
  UpdateUserAvatarError = "[User] Page Update User Avatar Error",
  ActivateUser = "[User] Activate User",
  ActivateUserSuccess = "[User] Activate User Success",
  ActivateUserError = "[User] Activate User Error",
  UpdateGlogalDriveLink = "[User] Update Glogal Drive Link",
  UpdateGlogalDriveLinkSuccess = "[User] Update Glogal Drive Link success",
  UpdateGlogalDriveLinkError = "[User] Update Glogal Drive Link error"
}

export class LanguageSelect implements Action {
  readonly type = UserActionTypes.LanguageSelect;
  constructor(readonly language: string) {}
}

export class GetUser implements Action {
  readonly type = UserActionTypes.GetUser;
  constructor(readonly user: User) {}
}

export class GetUsers implements Action {
  readonly type = UserActionTypes.GetUsers;
  constructor(readonly role: USER_ROLE) {}
}

export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.GetUsersSuccess;
  constructor(readonly users: User[]) {}
}

export class GetUsersError implements Action {
  readonly type = UserActionTypes.GetUsersError;
  constructor(readonly error: string) {}
}

export class UpdateUserAvatar implements Action {
  readonly type = UserActionTypes.UpdateUserAvatar;
  constructor(readonly payload: { data: Blob | null, email: string} ) {}
}

export class UpdateUserAvatarSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserAvatarSuccess;
  constructor(readonly user: User) {}
}

export class UpdateUserAvatarError implements Action {
  readonly type = UserActionTypes.UpdateUserAvatarError;
  constructor(readonly error: string) {}
}

export class ActivateUser implements Action {
  readonly type = UserActionTypes.ActivateUser;
  constructor(readonly payload: UserActivateRequest ) {}
}

export class ActivateUserSuccess implements Action {
  readonly type = UserActionTypes.ActivateUserSuccess;
  constructor(readonly user: User) {}
}

export class ActivateUserError implements Action {
  readonly type = UserActionTypes.ActivateUserError;
  constructor(readonly error: string) {}
}

export class UpdateGlogalDriveLink implements Action {
  readonly type = UserActionTypes.UpdateGlogalDriveLink;
  constructor(readonly payload: GlobalProperty){}
}

export class UpdateGlogalDriveLinkSuccess implements Action {
  readonly type = UserActionTypes.UpdateGlogalDriveLinkSuccess;
  constructor(readonly data: GlobalProperty){}
}

export class UpdateGlogalDriveLinkError implements Action {
  readonly type = UserActionTypes.UpdateGlogalDriveLinkError;
  constructor(readonly error: string){}
}

export type UserActions =
  LanguageSelect |
  GetUser |
  GetUsers |
  GetUsersSuccess |
  GetUsersError |
  UpdateUserAvatar |
  UpdateUserAvatarSuccess |
  UpdateUserAvatarError |
  ActivateUser |
  ActivateUserSuccess |
  ActivateUserError |
  UpdateGlogalDriveLink |
  UpdateGlogalDriveLinkSuccess |
  UpdateGlogalDriveLinkError;
