// Core
import { Injectable } from "@angular/core";

// Store
import { Store, select } from "@ngrx/store";

import { PartnerDataState, State } from "../reducers";
import {
  selectFullName,
  selectEmail,
  selectPhone
} from "../selectors/partner-data.selector";
import { SavePartnerData } from "../actions/partner-data.action";

@Injectable()
export class PartnerDataFacade {
  full_name$ = this.store.pipe(select(selectFullName));
  email$ = this.store.pipe(select(selectEmail));
  phone$ = this.store.pipe(select(selectPhone));

  constructor(private store: Store<PartnerDataState>) {}

  savePartnerData(partnerData: State) {
    this.store.dispatch(new SavePartnerData(partnerData));
  }
}
