import { Component, OnInit, ViewChild } from '@angular/core';
import { Partner, PartnersFacade } from '@app/domains/partners';
import { AddEditLinkFormComponent } from '@app/shared/components/add-edit-link-form/add-edit-link-form.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'vov-add-edit-link-popup',
  templateUrl: './add-edit-link-popup.component.html',
  styleUrls: ['./add-edit-link-popup.component.scss']
})
export class AddEditLinkPopupComponent implements OnInit{
  public partner: Partner;
  public loading$: Observable<boolean>;
  @ViewChild(AddEditLinkFormComponent) public form: AddEditLinkFormComponent;

  constructor(
    private activeModal: NgbActiveModal,
    private partnersFacade: PartnersFacade
  ) {}

  ngOnInit(): void {
    this.loading$ = this.partnersFacade.loadingPartners$;
  }

  saveLink() {
    this.form.saveLink();
  }

  close() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
