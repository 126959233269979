<div [ngClass]="disabled ? 'vox-card disabled': 'vox-card'">
  <div class="contacts-filter">
    <i *ngIf="filterContactInput.value === ''" class="icon-small-search"></i>
    <i *ngIf="filterContactInput.value !== ''" class="icon-small-close" (click)="filterContactInput.setValue('')"
      [title]="'contact-list.clear-search' | translate"></i>
    <input [formControl]="filterContactInput" type="text"
      [placeholder]="'contact-list.search-placeholder' | translate" />
  </div>
  <ul class="contact-list" [style.max-height]="maxHeight">
    <li *ngFor="let c of filteredContacts" title="{{c.email}}" class="contact" (click)="selectContact(c)"
      [ngClass]="{'selected': isSelected(c)}">
      <i class="icon-small-check"></i>
      <span class="contact-name">
        {{c.first_name}} {{c.last_name}}
        <span class="vox-chip vox-chip_royal-blue" *ifCurrentUser="{'user': c}" translate>app.you</span>
      </span>
    </li>
    <li *ngIf="filteredContacts?.length === 0" class="contact empty" translate>contact-list.empty</li>
  </ul>
</div>
