import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { AuthLayoutMainComponent } from "./main/main.component";

@NgModule({
  declarations: [AuthLayoutMainComponent],
  imports: [CommonModule, RouterModule]
})
export class AuthLayoutModule {}
