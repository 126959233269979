import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@src/environments/environment'
import { I18nService } from '@app/core/i18n';

@Component({
  selector: 'auth-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class AuthForgotPasswordFormComponent implements OnInit {
  @Input() loading: boolean;
  @Output() submitted = new EventEmitter<any>();
  public KEY = environment.reCaptchaKey;
  public frm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private i18nService: I18nService) {}
  ngOnInit() {
    this.createForm();
  }

  get isDisabled() {
    if (!this.frm.invalid) {
      return this.loading;
    }
    return this.frm.invalid;
  }

  createForm() {
    this.frm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      recaptcha: ['', Validators.required]
    });
  }

  // Captcha methods
  handleReset(event: any) {
    console.log('ReCaptcha reset', event);
  }
  handleExpire(event: any) {
    console.log('ReCaptcha expire', event);
  }
  handleLoad(event: any) {
    console.log('ReCaptcha load', event);
  }
  handleSuccess(event: any) {
    console.log('ReCaptcha success', event);
  }

  onSubmit() {
    if (this.frm.valid) {
      this.submitted.emit(this.frm.value);
    }
  }
}
