import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CompanyDataState, State } from "../reducers";

export const selectCompanyDataState = createFeatureSelector<CompanyDataState>(
  "company-data"
);

export const selectCompanyDataStatus = createSelector(
  selectCompanyDataState,
  (state: CompanyDataState) => state.status
);

export const selectCompanyName = createSelector(
  selectCompanyDataStatus,
  (state: State) => state.company_name
);

export const selectCountry = createSelector(
  selectCompanyDataStatus,
  (state: State) => state.country
);

export const selectCity = createSelector(
  selectCompanyDataStatus,
  (state: State) => state.city
);

export const selectCompanyWebsite = createSelector(
  selectCompanyDataStatus,
  (state: State) => state.company_website
);

export const selectCompanyType = createSelector(
  selectCompanyDataStatus,
  (state: State) => state.company_type
);
