<form class="form-box" [formGroup]="frm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="vox-field-label" for="password" translate>reset-password.password</label>
    <div class="vox-input-group">
      <vox-password-input formControlName="new_password" [language]="language"></vox-password-input>
    </div>
  </div>
  <button class="vox-btn vox-btn-primary" type="submit" [disabled]="isDisabled"
    translate>reset-password.change-button</button>
</form>
