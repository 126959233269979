// Core
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Components
import { PartnersPageComponent } from "./containers/page/partners-page.component";

export const routes: Routes = [
  {
    path: "",
    component: PartnersPageComponent,
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnersRoutingModule {}
