import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { CustomerStatus, CustomerSearchFilters, CustomersSearch, DatePickerModel } from '@app/domains/customers';
import { AgentsFacade, Agent } from '@app/domains/agents';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { User, UserFacade, USER_ROLE } from '@app/domains/user';
import { take, filter } from 'rxjs/operators';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'vov-filter-customers',
  templateUrl: './filter-customers.component.html',
  styleUrls: ['./filter-customers.component.scss']
})
export class FilterCustomersComponent implements OnInit {
  public availableFilters = ['name', 'status', 'creation', 'reference'];
  public usedFilters = [];
  public statuses: string[] = ['ACTIVE', 'IN_TRIAL', 'CANCELLED'];
  public currentUser: User;
  public options: CustomersSearch;

  @Output() applyFilters = new EventEmitter<CustomerSearchFilters>();

  // Set the form controller with starting filters.
  @Input() set startingFilters(options: CustomersSearch) {
    let has_parsed: boolean = false;
    this.options = options;

    Object.keys(options).forEach((k: string) => {
      switch (k) {
        case 'name':
          //set has parsed to true to check if an empty name filter has to be added
          //Same for all filters: If the form control doesn't already exists, create it with the value form params, then update filters lists
          has_parsed = true;
          if (!this.form.get('name')) {
            this.form.addControl('name', new UntypedFormControl(options['name']));
            this.usedFilters.push('name');
            this.availableFilters.splice(this.availableFilters.indexOf('name'), 1);
          } else {
            //else just update the value
            this.form.get('name').setValue(options['name']);
          }
          break;
        case 'status':
          has_parsed = true;
          if (!this.form.get('status')) {
            this.form.addControl('status', new UntypedFormControl(options['status']));
            this.usedFilters.push('status');
            this.availableFilters.splice(this.availableFilters.indexOf('status'), 1);
          } else {
            this.form.get('status').setValue(options['status']);
          }
          break;
        case 'creation_date_from':
          //case 'creation_date_to':
          has_parsed = true;
          let dates = {
            creation_date_from: this.parseDatepickerDate(options['creation_date_from']),
            creation_date_to: this.parseDatepickerDate(options['creation_date_to'])
          }
          if (!this.form.get('creation')) {
            this.form.addControl('dates', new UntypedFormControl(
              dates
            ));
            this.usedFilters.push('creation');
            this.availableFilters.splice(this.availableFilters.indexOf('creation'), 1);
          } else {
            this.form.get('creation').setValue(
              dates
            );
          }
          break;
        // parse partner_id

        case 'partner_id':
          has_parsed = true;
          if (!this.form.get('partner')) {
            this.form.addControl('partner', new UntypedFormControl(options['partner_id']));
            this.usedFilters.push('partner');
          } else {
            this.form.get('partner').setValue(options['partner_id']);
          }
          break;
        case 'agent_id':
          has_parsed = true;
          break;
      }
    });

    // If the params does not contains any filter then add the name form control as defaul;
    if (!has_parsed) {
      this.form.addControl('name', new UntypedFormControl(''));
      this.usedFilters.push('name');
      this.availableFilters.splice(this.availableFilters.indexOf('name'), 1);
    }
  }

  public agents: Agent[];
  public form: UntypedFormGroup;

  constructor(private agentsFacade: AgentsFacade, private userFacade: UserFacade, private fb: UntypedFormBuilder, private calendar: NgbCalendar) {
    this.form = this.fb.group({});
  }

  ngOnInit() {
    this.availableFilters.sort();
    //I need agents for agents filter.
    this.userFacade.user$.pipe(filter(d => d.id ? true : false), take(1)).subscribe((data: any) => {
      let u = data;
      this.currentUser = u;
      let agents = data[1];
      this.agents = agents;
      // If we are supervisor but don't have the partner, add it to available filters
      if (u.roles[0] === USER_ROLE.SUPERVISOR) {
        let idx = this.usedFilters.indexOf('partner');
        if (idx === -1) {
          this.availableFilters.push('partner');
          this.availableFilters.sort();
        }
      } else {
        // if we have the partner id but we are not supervisor, remove it from
        // filter list
        this.form.removeControl('partner');
        let idx = this.usedFilters.indexOf('partner');
        if (idx !== -1) this.usedFilters.splice(idx, 1); //remove it from used
        let aidx = this.availableFilters.indexOf('partner');
        if (aidx !== -1) this.availableFilters.splice(aidx, 1);
        // Because probably the partner id is setted even when we are not supervisor
        // this code manage to put name as the first filter is only partner_id is given
        if (this.options['partner_id'] && Object.keys(this.options).length === 1) {
          this.form.addControl('name', new UntypedFormControl(''));
          this.usedFilters.push('name');
          this.availableFilters.splice(this.availableFilters.indexOf('name'), 1);
        }
      }
      //If the user is an agent, he can't filter by reference agents
      if (u.roles[0] === USER_ROLE.AGENT || u.roles[0] === USER_ROLE.SUPERVISOR) {
        this.form.removeControl('reference');
        let idx = this.usedFilters.indexOf('reference');
        if (idx > -1) {
          this.usedFilters.splice(idx, 1);
        } else {
          this.availableFilters.splice(this.availableFilters.indexOf('reference'), 1);
        }
      } else {
        // We should get agents only if user role is not agent or supervisor
        this.agentsFacade.getAgents();
        this.agentsFacade.agents$.pipe(filter(d => d.length > 0), take(1)).subscribe(data2 => {
          let agents = data2;
          this.agents = agents;
          if (this.options.agent_id) {
            // else as always check if the form control is present. If not create it else update its value
            if (!this.form.get('reference')) {
              //Put the value from query OR the first value of agents OR null (will show no agents with null)
              this.form.addControl('reference', new UntypedFormControl(
                agents.find((el: Agent) => {
                  return el.id == this.options.agent_id;
                }) ||
                agents[0]
              ));
              this.usedFilters.push('reference');
              this.availableFilters.splice(this.availableFilters.indexOf('reference'), 1);
            } else {
              this.form.get('reference').setValue(
                agents.find((el: Agent) => {
                  return el.id == this.options.agent_id;
                })
                || agents[0]
              );
            }
          }
        });
      }
    });
  }

  public updateFilters(index: number, oldIndex: number) {
    let toRemove: string = this.usedFilters[oldIndex];
    let toAdd: string = this.availableFilters[index];
    this.usedFilters[oldIndex] = this.availableFilters[index];
    this.availableFilters.splice(index, 1);

    this.availableFilters.push(toRemove);
    this.availableFilters.sort();

    if (toRemove === 'creation') {
      this.form.removeControl('dates');
    } else {
      this.form.removeControl(toRemove);
    }

    switch (toAdd) {
      case 'status':
        this.form.addControl(toAdd, new UntypedFormControl(this.statuses[0]));
        break;
      case 'reference':
        this.form.addControl(toAdd, new UntypedFormControl(this.agents[0]));
        break;
      case 'creation':
        let startOfTheMonth = this.calendar.getToday();
        startOfTheMonth.day = 1;
        let today = this.calendar.getToday();
        this.form.addControl('dates', new UntypedFormControl({
          creation_date_from: startOfTheMonth,
          creation_date_to: today
        }));
        break;
      default:
        this.form.addControl(toAdd, new UntypedFormControl(''));
    }
  }

  public addFilter(index: number) {
    let filter: string = this.availableFilters[index];
    this.usedFilters.push(this.availableFilters[index]);
    this.availableFilters.splice(index, 1);

    switch (filter) {
      case 'status':
        this.form.addControl(filter, new UntypedFormControl(this.statuses[0]));
        break;
      case 'reference':
        this.form.addControl(filter, new UntypedFormControl(this.agents[0]));
        break;
      case 'creation':
        let startOfTheMonth = this.calendar.getToday();
        startOfTheMonth.day = 1;
        let today = this.calendar.getToday();
        this.form.addControl('dates', new UntypedFormControl({
          creation_date_from: startOfTheMonth,
          creation_date_to: today
        }));
        break;
      default:
        this.form.addControl(filter, new UntypedFormControl(''));
    }
  }

  public removeFilter(index: number) {
    let filter = this.usedFilters[index];
    this.availableFilters.push(this.usedFilters[index]);
    this.usedFilters.splice(index, 1);
    this.availableFilters.sort();
    if (filter === 'creation') {
      this.form.removeControl('dates');
    } else {
      this.form.removeControl(filter);
    }
  }

  public selectStatus(status: CustomerStatus) {
    if (this.form.get('status')) {
      this.form.get('status').setValue(status);
    } else {
      this.form.addControl('status', new UntypedFormControl(status));
    }
  }

  public resetFilters() {
    this.form = this.fb.group({
      name: new UntypedFormControl('')
    });
    this.usedFilters = ['name'];
    this.availableFilters = ['status', 'creation', 'reference'];
    if (this.currentUser.roles[0] === USER_ROLE.SUPERVISOR) {
      this.availableFilters.push('partner');
    }
  }

  public emitFilters() {
    if (this.form.valid) {
      let value = this.form.value;
      if (value.dates)
        value.dates = this.autoCompleteDatesIfOneMissing(value.dates);
      if (this.form.get('partner') && this.form.get('partner').value === undefined) {
        // TODO: refactor to not use document.getElementById
        let element: any = document.getElementById('select-partner-reset')
        element.value = '';
      }
      this.applyFilters.emit(value);
    }
  }

  //Parse the date from querystring format
  private parseDatepickerDate(dateString: string) {
    if (!dateString) return null;
    let d: DatePickerModel = {} as any;
    let date = dateString.split('-');
    d.year = +date[0];
    d.month = +date[1];
    d.day = +date[2];
    return d;
  }

  // This method will autocomplete dates if one is missing
  // This is done here because we catch here the apply filter event
  private autoCompleteDatesIfOneMissing(value: {
    creation_date_from: NgbDate,
    creation_date_to: NgbDate
  }) {
    if (!value.creation_date_to && value.creation_date_from) {
      this.form.get('dates').setValue(
        {
          creation_date_from: value.creation_date_from,
          creation_date_to: this.calendar.getToday()
        }
      );
      value.creation_date_to = this.calendar.getToday();
    }
    if (value.creation_date_to && !value.creation_date_from) {
      let startOfTheMonth = this.calendar.getToday();
      startOfTheMonth.day = 1;
      this.form.get('dates').setValue(
        {
          creation_date_from: startOfTheMonth,
          creation_date_to: value.creation_date_to
        }
      );
      value.creation_date_from = startOfTheMonth;
    }
    return value;
  }
}