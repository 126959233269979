import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Credentials } from "../../models";
import { State } from "../reducers";
import {
  selectError,
  selectLoading,
  selectIsAuthenticated,
  selectToken
} from "../selectors";

import { Login, Logout } from "../actions";

@Injectable({
  providedIn: "root"
})
export class AuthFacade {
  error$ = this.store.pipe(select(selectError));
  loading$ = this.store.pipe(select(selectLoading));
  isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
  token$ = this.store.pipe(select(selectToken));
  constructor(private store: Store<State>) { }

  login(credentials: Credentials): void {
    this.store.dispatch(new Login({ credentials }));
  }

  logout(message?: string): void {
    message ? this.store.dispatch(new Logout({ message: message })) : this.store.dispatch(new Logout());
  }
}
