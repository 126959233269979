<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" translate>agents.add-popup.title</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
      <i class="icon-small-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="content">
      <div class="vox-inline-message vox-inline-message_light-grey" *ngIf="error && (error$ | async)">
        <div><span translate>{{error}}</span></div>
        <i class="icon-small-close" (click)="error = null"></i>
      </div>
      <div *ngIf="loading$ | async">
        <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '3px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': '18px'}"></ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '3px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': '18px'}"></ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '3px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': '18px'}"></ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="{'height': '18px', 'margin-bottom': '3px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{'height': '56px', 'margin-bottom': '10px'}"></ngx-skeleton-loader>
      </div>
    </div>
    <vov-edit-agent-form [hidden]="loading$ | async" #form></vov-edit-agent-form>
  </div>
  <div class="modal-footer">
    <button type="button" class="vox-btn vox-btn-secondary-grey" (click)="close()" translate>
      agents.add-popup.cancel
    </button>
    <button type="button" class="vox-btn vox-btn-secondary" [disabled]="!form.valid || !form.dirty" (click)="addAgent()" translate>
      agents.add-popup.add
    </button>
  </div>
</div>
