// Core
import { Injectable } from "@angular/core";

// Environment
import { environment } from "src/environments/environment";

// Models
import { Partner } from "../models/onboarding.model";

@Injectable()
export class PartnerCreationService {
  constructor() {}

  sendPartner = (partner: Partner) => {
    return new Promise(function(resolve, reject) {
      var url = environment.partnerCreationUrl;
      let xhr = new XMLHttpRequest();
      xhr.responseType = "";
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (
            xhr.status === 200 &&
            JSON.parse(xhr.responseText).status === "success"
          ) {
            resolve(null);
          } else {
            if (
              xhr.status === 200 &&
              JSON.parse(xhr.responseText) !== "success"
            ) {
              reject(alert("Zapier error"));
            } else {
              reject(alert("Generic error. Conctact our staff"));
            }
          }
        }
      };

      xhr.open("POST", url, true);
      xhr.send(JSON.stringify({ partner }));
    });
  };
}
