import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export enum RouterActionTypes {
  Back = '[Router] Back',
  Forward = '[Router] Forward',
  Go = '[Router] Go'
}

export class Back implements Action {
  readonly type = RouterActionTypes.Back;
}

export class Forward implements Action {
  readonly type = RouterActionTypes.Forward;
}

export class Go implements Action {
  readonly type = RouterActionTypes.Go;
  constructor(
    readonly payload: {
      path: any[];
      queryParams?: object;
      extras?: NavigationExtras;
    }
  ) {}
}
export type Actions = Back | Forward | Go;
