import { ActionReducerMap } from "@ngrx/store";
import { State, reducer } from "./company-data.reducer";

export * from "./company-data.reducer";

export interface CompanyDataState {
  status: State;
}

export const companyDataReducer: ActionReducerMap<CompanyDataState> = {
  status: reducer
};
