import { AuthToken } from '../../models';
import { AuthActions, AuthActionTypes } from '../actions';

export interface State {
  error: any;
  loading: boolean;
  token: AuthToken | null;
}

export const initialState: State = {
  error: null,
  loading: false,
  token: null
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.Login: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }

    case AuthActionTypes.LoginFailure: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        error: null,
        loading: false,
        token: action.payload.token
      };
    }

    case AuthActionTypes.Logout:
    case AuthActionTypes.LoginReset: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getIsAuthenticated = (state: State) => {
  if (!state.token) {
    return false;
  } else {
    return true;
  }
};
export const getLoading = (state: State) => state.loading;
export const getToken = (state: State) => state.token;
