import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { I18nService } from '@app/core/i18n';

@Component({
  selector: 'vov-activate-user-form',
  templateUrl: './activate-user-form.component.html',
  styleUrls: ['./activate-user-form.component.scss']
})
export class AuthActivateUserFormComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  public form: UntypedFormGroup;
  public languages = environment.supportedLanguages;
  public language: string;
  public criteria: { [key: string]: string};

  constructor(
    private i18n: I18nService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.language = this.i18n.language;
    this.form = this.fb.group({
      password: new UntypedFormControl('', Validators.required),
      repeatPassword: new UntypedFormControl('', [Validators.required])
    }, { validators: [ this.passwordMatchValidator ] });
  }

  passwordMatchValidator(g: UntypedFormGroup) {
    const match = g.value.password  === g.value.repeatPassword;
    const length =  g.value.password < 8;
    return match ? null : { mismatch: 'activate-user.form.repeat-password.error'};
  }

  setLanguage(langName: string) {
    this.language = langName;
  }

  submit() {
    this.onSubmit.emit({
      password: this.form.value.password,
      language: this.language
    });
  }

}
