import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { companyDataReducer } from "./store/reducers";
import { CompanyDataFacade } from "./store/facade/company-data.facade";

@NgModule({
  imports: [StoreModule.forFeature("company-data", companyDataReducer)],
  providers: [CompanyDataFacade]
})
export class CompanyDataDomainModule {}
