import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { USER_ROLE } from 'src/app/domains/user/models';
import { AgentsFacade } from 'src/app/domains/agents/store/facade/agents.facade';
import { Agent } from 'src/app/domains/agents/models/agents.model';
import { ConfirmationPopupService } from '@app/shared/components/confirmation-popup/confirmation-popup.service';

const PAGE_SIZE = 20;

@Component({
  selector: 'vov-agents-table',
  templateUrl: './agents-table.component.html',
  styleUrls: ['./agents-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AgentsTableComponent implements OnInit {
  @Output() onEditAgent = new EventEmitter<Agent>();
  @Output() onDeleteAgent = new EventEmitter<string>();
  public agents$: Observable<Agent[]>;
  public loading$: Observable<boolean>;
  public searchControl: UntypedFormControl = new UntypedFormControl('');
  public ROLES = USER_ROLE;
  public pagination = {
    page: 0,
    empty: true,
    totalPages: 0
  };
  public sort: any = {};


  constructor(private agentsFacade: AgentsFacade, private confirm: ConfirmationPopupService) { }

  ngOnInit() {
    this.agents$ = this.agentsFacade.agents$;
    this.loading$ = this.agentsFacade.loadingAgents$;

    this.agents$
      .subscribe(
        (agents) => {
          this.pagination = {
            page: 0,
            empty: agents.length === 0,
            totalPages: Math.ceil(agents.length / PAGE_SIZE)
          }
        });
    this.agentsFacade.getAgents();
  }

  canBeAdmin(a: Agent) {
    return a.roles[0] === USER_ROLE.AGENT;
  }

  canBeAgent(a: Agent) {
    return a.roles[0] === USER_ROLE.ADMIN;
  }

  onCheckboxClick(event: MouseEvent, a: Agent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.canBeAdmin(a)) {
      this.makeAdmin(a);
      return;
    }
    if (this.canBeAgent(a)) {
      this.makeAgent(a);
    }
  }

  makeAdmin(a: Agent) {
    this.confirm.show('agents.confirm-agent-table.paragraph.promote', {
      classes: { cancel: 'agent-table-cancel', confirm: '' }, labels: {
        title: 'agents.confirm-agent-table.title',
        ok: 'agents.confirm-agent-table.confirm',
        cancel: 'agents.confirm-agent-table.cancel'
      }
    }).then((res: boolean) => {
      // When the modal is closed by pressing esc it will return 1
      // For this case 1 is still false. Only a returned value of true is accepted
      if (res === true) {
        this.onEditAgent.emit({
          ...a,
          roles: [USER_ROLE.ADMIN]
        });
      }
    })
  }

  makeAgent(a: Agent) {
    this.confirm.show('agents.confirm-agent-table.paragraph.demote', {
      classes: { cancel: 'agent-table-cancel', confirm: '' }, labels: {
        title: 'agents.confirm-agent-table.title',
        ok: 'agents.confirm-agent-table.confirm',
        cancel: 'agents.confirm-agent-table.cancel'
      }
    }).then((res: boolean) => {
      if (res === true) {
        this.onEditAgent.emit({
          ...a,
          roles: [USER_ROLE.AGENT]
        });
      }
    })
  }

  deleteAgent(id: string) {
    this.confirm.show('agents.confirm-agent-table.paragraph.delete', {
      classes: { cancel: 'agent-table-cancel', confirm: '' }, labels: {
        title: 'agents.confirm-agent-table.title',
        ok: 'agents.confirm-agent-table.confirm',
        cancel: 'agents.confirm-agent-table.cancel'
      }
    }).then((res: boolean) => {
      if (res === true) this.onDeleteAgent.emit(id);
    })
  }

  // sort and pagination
  get displayedAgents$(): Observable<Agent[]> {
    return this.agents$
      .pipe(
        map(agents => {
          let result = [...agents];
          // filtering
          if (this.searchControl.value && this.searchControl.value.trim().length !== 0) {
            result = agents.filter((a) => {
              const name = `${a.first_name}${a.last_name}`.toLowerCase();
              const email = a.email;
              const query = this.searchControl.value.toLowerCase();
              return name.includes(query) || email.includes(query);
            });
          }
          // pagination
          let paginated = [];
          this.pagination.totalPages = Math.ceil(result.length / PAGE_SIZE);
          let i = 0;
          while (i < this.pagination.totalPages) {
            paginated.push(result.slice(i * PAGE_SIZE, (i + 1) * PAGE_SIZE));
            i++;
          }

          // sorting
          if (this.sort) {
            if (this.sort.name) {
              paginated[this.pagination.page] = this.sort.name == 'asc' ?
                paginated[this.pagination.page].sort(this.nameComparatorFn) :
                paginated[this.pagination.page].sort(this.nameComparatorFn).reverse();
            }
            if (this.sort.email) {
              paginated[this.pagination.page] = this.sort.email == 'asc' ?
                paginated[this.pagination.page].sort(this.emailComparatorFn) :
                paginated[this.pagination.page].sort(this.emailComparatorFn).reverse();
            }
          }


          return paginated;
        })
      );
  }

  nameComparatorFn(a, b) {
    return `${a.first_name}${a.last_name}`
      .localeCompare(`${b.first_name}${b.last_name}`);
  }

  emailComparatorFn(a, b) {
    return (a.email).localeCompare(b.email);
  }

  get pages() {
    let i = 0;
    const pages = [];
    if (this.pagination.totalPages) {
      while (i < this.pagination.totalPages) {
        pages.push(i);
        i++;
      }
    }
    return pages;
  }

  goToPage(p) {
    if (this.pagination.page !== p) {
      this.pagination.page = p;
    }
  }
  nextPage() {
    this.pagination.page += 1;
  }
  prevPage() {
    this.pagination.page -= 1;
  }

  prevDisabled() {
    return this.pagination.page === 0
  }
  nextDisabled() {
    return this.pagination.page === this.pages[this.pages.length - 1]
  }

  toggleSort(key: string) {
    const currentValue = this.sort[key];
    if (currentValue) {
      this.sort[key] = currentValue === 'asc' ? 'desc' : undefined;
    } else {
      this.sort[key] = 'asc';
    }
  }

}
