import { Component, ViewChild, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentsFacade } from '@app/domains/agents/store/facade/agents.facade';
import { Agent } from '@app/domains/agents/models/agents.model';
import { USER_ROLE } from '@app/domains/user/models';
import { EditAgentFormComponent } from '@app/shared/components/edit-agent-form/edit-agent-form.component';

@Component({
  selector: 'vov-add-agent-popup',
  templateUrl: './add-agent-popup.component.html',
  styleUrls: ['./add-agent-popup.component.scss']
})
export class AddAgentPopupComponent implements OnInit {
  @ViewChild(EditAgentFormComponent) public form: EditAgentFormComponent;
  public loading$: Observable<boolean>;
  public success$: Observable<boolean>;
  public error$: Observable<any>;
  public error: string;
  public partnerId: string;

  constructor(
    private activeModal: NgbActiveModal,
    private agentsFacade: AgentsFacade) {}

  ngOnInit() {
    this.loading$ = this.agentsFacade.loadingAgent$;
    this.success$ = this.agentsFacade.successAgent$;
    this.error$ = this.agentsFacade.errorAgent$;
  }

  addAgent() {
    this.form.saveAgent(this.partnerId);

    this.success$.pipe(
      filter(s => s === true),
      take(1)
    ).subscribe(
      (s)=> {
        this.close();
      });
    this.error$.pipe(
      filter(s => s !== null),
      take(1)
    ).subscribe(
      (e)=> {
        this.error = 'agents.add-popup.error';
      });
  }

  close() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
