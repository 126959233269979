import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PartnerDataState, State } from "../reducers";

export const selectPartnerDataState = createFeatureSelector<PartnerDataState>(
  "partner-data"
);

export const selectPartnerDataStatus = createSelector(
  selectPartnerDataState,
  (state: PartnerDataState) => state.status
);

export const selectFullName = createSelector(
  selectPartnerDataStatus,
  (state: State) => state.full_name
);

export const selectEmail = createSelector(
  selectPartnerDataStatus,
  (state: State) => state.email
);

export const selectPhone = createSelector(
  selectPartnerDataStatus,
  (state: State) => state.phone
);
