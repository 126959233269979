<div class="header">
  <div class="header__language">
    <vox-language-picker class="language-picker" [language]="currentLang" [availableLanguages]="langs"
      (selected)="onChangeLanguage($event)" [side]='currentWidth' classes="text-black"></vox-language-picker>
  </div>
  <picture class="header__logo">
    <source media="(max-width: 991px)" srcset="/assets/img/logo/voxloud-logo-only.svg" />
    <img src="/assets/img/logo/voxloud-wordmark.svg" alt="Voxloud logo" width="150" height="30" />
  </picture>
  <div class="header__actions">
    <i class="icon-small-call"></i>
    <span translate>onboarding.help</span>
    <a href="tel:3906797631527">+39 06 97631527</a>
    <vox-language-picker class="language-picker" [language]="currentLang" [availableLanguages]="langs"
      [side]='currentWidth' (selected)="onChangeLanguage($event)" classes="text-white"></vox-language-picker>
  </div>
  <a class="header__mobile-phone" href="tel:3906797631527">
    <i class="icon-small-call"></i>
  </a>
</div>

<div class="main-container">
  <section class="main-container__left">
    <router-outlet></router-outlet>
  </section>

  <section class="main-container__right">
    <div class="product-container"
      [ngClass]="{'product-container--heighter': router.url == '/partner-contact/3', 'product-container--medium-height': router.url === '/partner-contact/4'}">
      <h2 class="vox-heading-2" translate>auth.right.title</h2>
      <div class="second-container" [ngClass]="{'second-container--no-height': router.url == '/partner-contact/4'}">
        <p class="text" translate>auth.right.description</p>
        <div class="btn-container">
          <button (click)="goToWebsite()" class="vox-btn" translate>auth.right.button</button>
        </div>
      </div>
    </div>
  </section>
</div>