// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Libs
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { VoxLanguagePickerModule } from "ng-voxloud";
import { NgxCaptchaModule } from 'ngx-captcha';

// Modules
import { AuthRoutingModule } from "./auth-routing.module";
import { AuthLayoutModule } from "./layout/auth-layout.module";
import { SharedModule } from "src/app/shared/shared.module";

// Components
import * as fromComponents from "./components";
import * as fromContainers from "./containers";

@NgModule({
  declarations: [
    ...fromComponents.components,
    ...fromContainers.containers
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    AuthLayoutModule,
    SharedModule,
    TranslateModule.forChild(),
    NgxCaptchaModule
  ]
})
export class AuthModule {}
