<div class="sidebar">
  <div class="menu">
    <div class="menu-item">
      <a (click)="closeMenu()" routerLink="./dashboard" routerLinkActive="menu-item__link--active"
        class="menu-item__link">
        <img class="menu-item__icon" src="assets/img/insert_chart_outlined.png" [alt]="'sidebar.dashboard' | translate"
          width="15px" height="15px" />
        <span class="menu-item__text vox-paragraph" translate>sidebar.dashboard</span>
      </a>
    </div>
    <div class="menu-item">
      <a href="https://app.partnerstack.com/" class="menu-item__link menu-item__partnerstack" target="_blank">
        <img class="menu-item__icon" src="assets/img/partnerstack-logo.svg" alt="PartnerStack logo" width="15px" height="15px" />
        <span class="menu-item__text vox-paragraph">Partner<b>Stack</b></span>
      </a>
    </div>
    <div class="menu-item" *ifUserRole="[ROLES.SUPERVISOR]">
      <a (click)="closeMenu()" class="menu-item__link" routerLink="./partners"
        routerLinkActive="menu-item__link--active">
        <img class="menu-item__icon" src="assets/img/user-group.png" [alt]="'sidebar.partners' | translate" width="15px"
          height="15px" />
        <span class="menu-item__text vox-paragraph" translate>sidebar.partners</span>
      </a>
    </div>
    <div class="menu-item" *ifUserRole="[ROLES.ADMIN, ROLES.AGENT]">
      <a (click)="closeMenu()" class="menu-item__link" routerLink="./agents" routerLinkActive="menu-item__link--active">
        <img class="menu-item__icon" src="assets/img/user-group.png" [alt]="'sidebar.agents' | translate"
          width="15px" />
        <span class="menu-item__text vox-paragraph" translate>sidebar.agents</span>
      </a>
    </div>
    <div class="menu-item" *ifUserRole="[ROLES.ADMIN, ROLES.AGENT, ROLES.SUPERVISOR]">
      <a (click)="closeMenu()" class="menu-item__link" routerLink="./customers"
        routerLinkActive="menu-item__link--active">
        <i class="icon-small-coins"></i>
        <span class="menu-item__text vox-paragraph" translate>sidebar.customers</span>
      </a>
    </div>
    <div class="menu-item">
      <a (click)="closeMenu()" class="menu-item__link" routerLink="./partner-hub"
        routerLinkActive="menu-item__link--active">
        <i class="icon-small-folder"></i>
        <span class="menu-item__text vox-paragraph" translate>sidebar.partner-hub</span>
      </a>
    </div>
    <ng-container *ifUserRole="[ROLES.AGENT]">
      <div class="menu-item" *ngIf="(showPrivateLink$ | async)">
        <a (click)="closeMenu()" class="menu-item__link" routerLink="./partner-doc"
          routerLinkActive="menu-item__link--active">
          <i class="icon-small-template"></i>
          <span class="menu-item__text vox-paragraph" translate>sidebar.partner-doc</span>
        </a>
      </div>
    </ng-container>
    <ng-container *ifUserRole="[ROLES.ADMIN]">
      <div class="menu-item">
        <a (click)="closeMenu()" class="menu-item__link" routerLink="./partner-doc"
          routerLinkActive="menu-item__link--active">
          <i class="icon-small-template"></i>
          <span class="menu-item__text vox-paragraph" translate>sidebar.partner-doc</span>
        </a>
      </div>
    </ng-container>
    <div class="menu-item" hidden>
      <a (click)="closeMenu()" class="menu-item__link" routerLink="./sales/trial-link"
        routerLinkActive="menu-item__link--active">
        <i class="icon-small-trial"></i>
        <span class="menu-item__text vox-paragraph" translate>sidebar.sales.trial</span>
      </a>
    </div>
    <div class="menu-item" hidden>
      <a (click)="closeMenu()" class="menu-item__link" routerLink="./sales/activation-link"
        routerLinkActive="menu-item__link--active">
        <i class="icon-small-new-account"></i>
        <span class="menu-item__text vox-paragraph" translate>sidebar.sales.activation</span>
      </a>
    </div>
    <div class="menu-item">
      <a href="http://help.voxloud.com" target="_blank" (click)="closeMenu()" class="menu-item__link">
        <img class="menu-item__icon" src="assets/img/help-circle.png" [alt]="'sidebar.faq' | translate" width="15px"
          height="15px" />
        <span class="menu-item__text vox-paragraph" translate>sidebar.faq</span>
      </a>
    </div>
    <div class="menu-item feedback">
      <a (click)="openFeedbackPopup(feedbackContent)" class="menu-item__link">
        <img class="menu-item__icon" src="assets/img/heart.svg" [alt]="'sidebar.feedback' | translate" width="15px" height="15px" />
        <span class="menu-item__text vox-paragraph" translate>sidebar.feedback</span>
      </a>
    </div>
    <div class="menu-item" *ifUserRole="[ROLES.ADMIN, ROLES.AGENT]">
      <a routerLink="./contact-us" routerLinkActive="menu-item__link--active" (click)="closeMenu()"
        class="menu-item__link">
        <i class="icon-small-support"></i>
        <span class="menu-item__text vox-paragraph" translate>sidebar.contact-us</span>
      </a>
    </div>
  </div>
  <div class="footer-box">
    <div class="copy-box">
      <span class="copy-box__img">&copy;</span>
      <p class="vox-small copy-box__text" translate>sidebar.copy</p>
    </div>
    <vox-language-picker class="copy-box__language-picker dropup" [language]="currentLang"
      [currentLanguage]="currentLang" [availableLanguages]="langs" (selected)="onChangeLanguage($event)"
      [classes]="'text-black'" side="left"></vox-language-picker>
  </div>
</div>
<ng-template #feedbackContent let-modal>
  <div class="modal-body feedback-body">
    <i (click)="modal.dismiss('Cross click')" class="icon-small-close"></i>
    <iframe style="border:0" width="100%" height="550px" [src]="iframeUrl"></iframe>
  </div>
</ng-template>
