// Libs
import { Action } from '@ngrx/store';

// Models
import { PartnersResponse, Partner, PartnersSearch } from '../../models/partners.model';

export enum PartnersActionTypes {
  GetPartners = '[Partners] Get Partners list',
  GetPartnersSuccess = '[Partners] Get Partners list success',
  GetPartnersError = '[Partners] Get Partners list error',
  DeletePartner = '[Partners] Delete Partner',
  DeletePartnerSuccess = '[Partners] Delete Partner success',
  DeletePartnerError = '[Partners] Delete Partner error',
  AddPartner = '[Partners] Add Partner',
  AddPartnerSuccess = '[Partners] Add Partner success',
  AddPartnerError = '[Partners] Add Partner error',
  UpdatePartner = '[Partners] Update Partner',
  UpdatePartnerSuccess = '[Partners] Update Partner success',
  UpdatePartnerError = '[Partners] Update Partner error',
  UpdatePartnerLogo = '[Partners] Update Partner Logo',
  UpdatePartnerLogoSuccess = '[Partners] Update Partner Logo success',
  UpdatePartnerLogoError = '[Partners] Update Partner Logo error',
  UpdatePartnerGoogleDriveLink = '[Partners] Update Partner Google Drive Link',
  UpdatePartnerGoogleDriveLinkSuccess = '[Partners] Update Partner Google Drive Link success',
  UpdatePartnerGoogleDriveLinkError = '[Partners] Update Partner Google Drive Link error',
}

export class GetPartners implements Action {
  readonly type = PartnersActionTypes.GetPartners;
  constructor(readonly payload: { options: PartnersSearch }) {}
}

export class GetPartnersSuccess implements Action {
  readonly type = PartnersActionTypes.GetPartnersSuccess;
  constructor(readonly payload: { partners: PartnersResponse }) {}
}

export class GetPartnersError implements Action {
  readonly type = PartnersActionTypes.GetPartnersError;
  constructor(readonly payload: { error: string }) {}
}

export class DeletePartner implements Action {
  readonly type = PartnersActionTypes.DeletePartner;
  constructor(readonly payload: { id: string }) {}
}

export class DeletePartnerSuccess implements Action {
  readonly type = PartnersActionTypes.DeletePartnerSuccess;
  constructor() {}
}

export class DeletePartnerError implements Action {
  readonly type = PartnersActionTypes.DeletePartnerError;
  constructor(readonly payload: { error: string }) {}
}

export class AddPartner implements Action {
  readonly type = PartnersActionTypes.AddPartner;
  constructor(readonly payload: { partner: Partner }) {}
}

export class AddPartnerSuccess implements Action {
  readonly type = PartnersActionTypes.AddPartnerSuccess;
  constructor(readonly payload: { partner: Partner }) {}
}

export class AddPartnerError implements Action {
  readonly type = PartnersActionTypes.AddPartnerError;
  constructor(readonly payload: { error: string }) {}
}

export class UpdatePartner implements Action {
  readonly type = PartnersActionTypes.UpdatePartner;
  constructor(readonly payload: { partner: Partner }) {}
}

export class UpdatePartnerSuccess implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerSuccess;
  constructor(readonly payload: { partner: Partner }) {}
}

export class UpdatePartnerError implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerError;
  constructor(readonly payload: { error: string }) {}
}

export class UpdatePartnerLogo implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerLogo;
  constructor(readonly payload: { data: Blob | null, id: string} ) {}
}

export class UpdatePartnerLogoSuccess implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerLogoSuccess;
  constructor(readonly payload: { partner: Partner }) {}
}

export class UpdatePartnerLogoError implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerLogoError;
  constructor(readonly payload: { error: string }) {}
}

export class UpdatePartnerGoogleDriveLink implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerGoogleDriveLink;
  constructor(readonly payload: {id: string, data: string}){}
}

export class UpdatePartnerGoogleDriveLinkSuccess implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerGoogleDriveLinkSuccess;
  constructor(readonly payload: {partner: Partner}){}
}

export class UpdatePartnerGoogleDriveLinkError implements Action {
  readonly type = PartnersActionTypes.UpdatePartnerGoogleDriveLinkError;
  constructor(readonly payload: { error: string }) {}
}

export type PartnersActions =
  GetPartners |
  GetPartnersSuccess |
  GetPartnersError |
  DeletePartner |
  DeletePartnerSuccess |
  DeletePartnerError |
  AddPartner |
  AddPartnerSuccess |
  AddPartnerError |
  UpdatePartner |
  UpdatePartnerSuccess |
  UpdatePartnerError |
  UpdatePartnerLogo |
  UpdatePartnerLogoSuccess |
  UpdatePartnerGoogleDriveLink |
  UpdatePartnerGoogleDriveLinkSuccess |
  UpdatePartnerGoogleDriveLinkError |
  UpdatePartnerLogoError;
