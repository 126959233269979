import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { User} from '@app/domains/user';
import { PartnersSearch, PartnersSearchFilters } from '@app/domains/partners';

@Component({
  selector: 'vov-filter-partners',
  templateUrl: './filter-partners.component.html',
  styleUrls: ['./filter-partners.component.scss']
})
export class FilterPartnersComponent implements OnInit {
  @Input() public availableFilters = ['name', 'email', 'partner_id'];
  public usedFilters = [];
  public currentUser: User;
  public options: PartnersSearch;

  @Output() applyFilters = new EventEmitter<PartnersSearchFilters>();

  // Set the form controller with starting filters.
  @Input() set startingFilters(options: PartnersSearch) {
    let has_parsed: boolean = false;
    this.options = options;

    Object.keys(options).forEach((k: string) => {
      switch (k) {
        case 'name':
          //set has parsed to true to check if an empty name filter has to be added
          //Same for all filters: If the form control doesn't already exists, create it with the value form params, then update filters lists
          has_parsed = true;
          if (!this.form.get('name')) {
            this.form.addControl('name', new UntypedFormControl(options['name']));
            this.usedFilters.push('name');
            this.availableFilters.splice(this.availableFilters.indexOf('name'), 1);
          } else {
            //else just update the value
            this.form.get('name').setValue(options['name']);
          }
          break;
        case 'email':
          has_parsed = true;
          if (!this.form.get('email')) {
            this.form.addControl('email', new UntypedFormControl(options['email']));
            this.usedFilters.push('email');
          } else {
            this.form.get('email').setValue(options['email']);
          }
          break;
        case 'partner_id':
          has_parsed = true;
          if (!this.form.get('partner_id')) {
            this.form.addControl('partner_id', new UntypedFormControl(options['partner_id']));
            this.usedFilters.push('partner_id');
          } else {
            this.form.get('partner_id').setValue(options['partner_id']);
          }
          break;
      }
    });

    // If the params does not contains any filter then add the name form control as defaul;
    if (!has_parsed) {
      this.form.addControl('name', new UntypedFormControl(''));
      this.usedFilters.push('name');
      this.availableFilters.splice(this.availableFilters.indexOf('name'), 1);
    }
  }

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({});
  }

  ngOnInit() {
    this.availableFilters.sort();
  }

  public updateFilters(index: number, oldIndex: number) {
    let toRemove: string = this.usedFilters[oldIndex];
    let toAdd: string = this.availableFilters[index];
    this.usedFilters[oldIndex] = this.availableFilters[index];
    this.availableFilters.splice(index, 1);

    this.availableFilters.push(toRemove);
    this.availableFilters.sort();

    this.form.removeControl(toRemove);

    switch (toAdd) {
      case 'email':
        this.form.addControl(toAdd, new UntypedFormControl(''));
        break;
      case 'partner_id':
        this.form.addControl(toAdd, new UntypedFormControl(''));
        break;
      default:
        this.form.addControl(toAdd, new UntypedFormControl(''));
    }
  }

  public addFilter(index: number) {
    let filter: string = this.availableFilters[index];
    this.usedFilters.push(this.availableFilters[index]);
    this.availableFilters.splice(index, 1);

    switch (filter) {
      case 'email':
        this.form.addControl(filter, new UntypedFormControl(''));
        break;
      case 'partner_id':
        this.form.addControl(filter, new UntypedFormControl(''));
        break;
      default:
        this.form.addControl(filter, new UntypedFormControl(''));
    }
  }

  public removeFilter(index: number) {
    let filter = this.usedFilters[index];
    this.availableFilters.push(this.usedFilters[index]);
    this.usedFilters.splice(index, 1);
    this.availableFilters.sort();
    this.form.removeControl(filter);
  }

  public resetFilters() {
    this.form = this.fb.group({
      name: new UntypedFormControl('')
    });
    this.usedFilters = ['name'];
    this.availableFilters = ['email', 'partner_id'];
  }

  public emitFilters() {
    if (this.form.valid) {
      let value = this.form.value;
      this.applyFilters.emit(value);
    }
  }
}