import { distinctUntilChanged, debounceTime, map, catchError } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';

import { USER_ROLE } from 'src/app/domains/user/models';
import { AgentsFacade } from 'src/app/domains/agents/store/facade/agents.facade';
import { Agent } from 'src/app/domains/agents/models/agents.model';
import { ConfirmationPopupService } from '@app/shared/components/confirmation-popup/confirmation-popup.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAdminPopupComponent } from '../add-admin-popup/add-admin-popup.component';
import { AgentsService } from '@app/domains/agents/services/agents.service';

const PAGE_SIZE = 20;

@Component({
  selector: 'vov-agents-table-modal',
  templateUrl: './agents-table-modal.component.html',
  styleUrls: ['./agents-table-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AgentsTableModalComponent implements OnInit {
  @Input() partnerId: string;
  public agents$: Observable<Agent[]>;
  public loading$: Observable<boolean>;
  public searchControl: UntypedFormControl = new UntypedFormControl('');
  public ROLES = USER_ROLE;
  public pagination = {
    page: 0,
    empty: true,
    totalPages: 0
  };
  public sort: any = {};
  public resentLoading = {}; // key = agent.email, value status of the icon


  constructor(private agentsFacade: AgentsFacade, private confirm: ConfirmationPopupService, private modalService: NgbModal, private agentsService: AgentsService, private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.agents$ = this.agentsFacade.agents$;
    this.loading$ = this.agentsFacade.loadingAgents$;

    this.agents$
      .subscribe(
        (agents) => {
          this.pagination = {
            page: 0,
            empty: agents.length === 0,
            totalPages: Math.ceil(agents.length / PAGE_SIZE)
          }
        });
    this.agentsFacade.getAgentsWithId(this.partnerId);
  }

  inviteAdmin() {
    const ref = this.modalService.open(AddAdminPopupComponent);
    ref.componentInstance.partnerId = this.partnerId;
    ref.result.then((result: any) => {
      if (result === 'added') {
        this.agentsFacade.getAgentsWithId(this.partnerId);
      }
    })
  }

  dismiss() {
    this.activeModal.close()
  }

  // Resend the email
  resendEmail(email: string) {
    this.agentsService.resendEmail(email).pipe(
      catchError(e => {
        this.resentLoading[email] = 'error';
        setTimeout(() => {
          delete this.resentLoading[email];
        }, 3000);
        return throwError(e);
      })
    ).subscribe(r => {
      this.resentLoading[email] = 'sent';
      setTimeout(() => {
        delete this.resentLoading[email];
      }, 3000);
    });
  }

  canBeAgent(a: Agent) {
    return a.roles[0] === USER_ROLE.ADMIN;
  }

  // sort and pagination
  get displayedAgents$(): Observable<Agent[]> {
    return this.agents$
      .pipe(
        map(agents => {
          let result = [...agents];
          // filtering
          if (this.searchControl.value && this.searchControl.value.trim().length !== 0) {
            result = agents.filter((a) => {
              const name = `${a.first_name}${a.last_name}`.toLowerCase();
              const email = a.email;
              const query = this.searchControl.value.toLowerCase();
              return name.includes(query) || email.includes(query);
            });
          }
          //Filter by role in any case
          result = result.filter(a => a.roles[0] === USER_ROLE.ADMIN);
          // pagination
          let paginated = [];
          this.pagination.totalPages = Math.ceil(result.length / PAGE_SIZE);
          let i = 0;
          while (i < this.pagination.totalPages) {
            paginated.push(result.slice(i * PAGE_SIZE, (i + 1) * PAGE_SIZE));
            i++;
          }

          // sorting
          if (this.sort) {
            if (this.sort.name) {
              paginated[this.pagination.page] = this.sort.name == 'asc' ?
                paginated[this.pagination.page].sort(this.nameComparatorFn) :
                paginated[this.pagination.page].sort(this.nameComparatorFn).reverse();
            }
            if (this.sort.email) {
              paginated[this.pagination.page] = this.sort.email == 'asc' ?
                paginated[this.pagination.page].sort(this.emailComparatorFn) :
                paginated[this.pagination.page].sort(this.emailComparatorFn).reverse();
            }
          }


          return paginated;
        })
      );
  }

  nameComparatorFn(a, b) {
    return `${a.first_name}${a.last_name}`
      .localeCompare(`${b.first_name}${b.last_name}`);
  }

  emailComparatorFn(a, b) {
    return (a.email).localeCompare(b.email);
  }

  get pages() {
    let i = 0;
    const pages = [];
    if (this.pagination.totalPages) {
      while (i < this.pagination.totalPages) {
        pages.push(i);
        i++;
      }
    }
    return pages;
  }

  goToPage(p) {
    if (this.pagination.page !== p) {
      this.pagination.page = p;
    }
  }
  nextPage() {
    this.pagination.page += 1;
  }
  prevPage() {
    this.pagination.page -= 1;
  }

  prevDisabled() {
    return this.pagination.page === 0
  }
  nextDisabled() {
    return this.pagination.page === this.pages[this.pages.length - 1]
  }

  toggleSort(key: string) {
    const currentValue = this.sort[key];
    if (currentValue) {
      this.sort[key] = currentValue === 'asc' ? 'desc' : undefined;
    } else {
      this.sort[key] = 'asc';
    }
  }

}
