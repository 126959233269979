import { Action } from "@ngrx/store";
import { Partner } from "../../models/onboarding.model";

export enum OnboardingActionTypes {
  SavePartnerInformations = "[Onboarding] Save Partner Informations",
  SendPartner = "[Onboarding] Send Partner",
  SendPartnerSuccess = "[Onboarding] Send Partner Success",
  SendPartnerFailure = "[Onboarding] Send Partner Failure"
}

export class SavePartnerInformations implements Action {
  readonly type = OnboardingActionTypes.SavePartnerInformations;
  constructor(readonly partnerInformations: Partner) {}
}

export class SendPartner implements Action {
  readonly type = OnboardingActionTypes.SendPartner;
  constructor(readonly payload: Partner) {}
}

export class SendPartnerSuccess implements Action {
  readonly type = OnboardingActionTypes.SendPartnerSuccess;
  constructor(readonly payload: Partner) {}
}

export class SendPartnerFailure implements Action {
  readonly type = OnboardingActionTypes.SendPartnerFailure;
  constructor(readonly payload: { error: any }) {}
}

export type OnboardingActions =
  | SavePartnerInformations
  | SendPartner
  | SendPartnerSuccess
  | SendPartnerFailure;
