// Models
import { Partner, PartnersResponse, PartnersSearch } from '../../models/partners.model';
// Store
import { PartnersActions, PartnersActionTypes } from '../actions';

export interface State {
  partners: PartnersResponse;
  partner: Partner,
  partnersOptions: PartnersSearch,
  loading: {
    partners: boolean,
    partner: boolean
  };
  error: {
    partners: any
    partner: any
  };
  success: {
    partners: boolean,
    partner: boolean
  };

}

export const initialState: State = {
  partners: {} as any,
  partner: null,
  partnersOptions: null,
  loading: {
    partners: false,
    partner: false
  },
  error: {
    partners: null,
    partner: null
  },
  success: {
    partners: false,
    partner: false
  }
};

export function reducer(state = initialState, action: PartnersActions): State {
  switch (action.type) {
  case PartnersActionTypes.GetPartners: {
    return {
      ...state,
      partnersOptions: action.payload.options,
      loading: { ...state.loading, partners: true },
      success: { ...state.success, partners: null }
    };
  }
  case PartnersActionTypes.GetPartnersSuccess: {
    return {
      ...state,
      partners: action.payload.partners,
      loading: { ...state.loading, partners: false },
      success: { ...state.success, partners: true }
    };
  }
  case PartnersActionTypes.GetPartnersError: {
    return {
      ...state,
      error : { ...state.error, partners: action.payload.error },
      loading: { ...state.loading, partners: false },
      success: { ...state.success, partners: false }
    };
  }

  case PartnersActionTypes.DeletePartner: {
    return {
      ...state,
      loading: { ...state.loading, partners: true },
      success: { ...state.success, partner: null }
    };
  }
  case PartnersActionTypes.DeletePartnerSuccess: {
    return {
      ...state,
      loading: { ...state.loading, partners: false },
      success: { ...state.success, partner: true }
    };
  }
  case PartnersActionTypes.DeletePartnerError: {
    return {
      ...state,
      error : { ...state.error, partner: action.payload.error },
      loading: { ...state.loading, partners: false },
            success: { ...state.success, partner: false }
    };
  }
  case PartnersActionTypes.AddPartner: {
    return {
      ...state,
      loading: { ...state.loading, partner: true },
      success: { ...state.success, partner: null }
    };
  }
  case PartnersActionTypes.AddPartnerSuccess: {
    return {
      ...state,
      partner: action.payload.partner,
      loading: { ...state.loading, partner: false },
      success: { ...state.success, partner: true }
    };
  }
  case PartnersActionTypes.AddPartnerError: {
    return {
      ...state,
      partner: null,
      error : { ...state.error, partner: action.payload.error },
      success: { ...state.success, partner: false },
      loading: { ...state.loading, partner: false }
    };
  }
  case PartnersActionTypes.UpdatePartner: {
    return {
      ...state,
      loading: { ...state.loading, partner: true },
      success: { ...state.success, partner: null }
    };
  }
  case PartnersActionTypes.UpdatePartnerSuccess: {
    return {
      ...state,
      partner: action.payload.partner,
      loading: { ...state.loading, partner: false },
      success: { ...state.success, partner: true }
    };
  }
  case PartnersActionTypes.UpdatePartnerError: {
    return {
      ...state,
      partner: null,
      error : { ...state.error, partner: action.payload.error },
      loading: { ...state.loading, partner: false },
      success: { ...state.success, partner: false },
    };
  }
  case PartnersActionTypes.UpdatePartnerLogo: {
    return {
      ...state,
      loading: { ...state.loading, partner: true },
      success: { ...state.success, partner: null },
    };
  }
  case PartnersActionTypes.UpdatePartnerLogoSuccess: {
    const src = action.payload.partner.visual_preferences.partner_logo
      ? `${action.payload.partner.visual_preferences.partner_logo}?t=${new Date().getTime()}`
      : null
    const visual_preferences = {
      onboarding_label: action.payload.partner.visual_preferences.onboarding_label,
      partner_logo: src
    }
    return {
      ...state,
      partner: {
        ...action.payload.partner,
        visual_preferences
      },
      loading: { ...state.loading, partner: false },
      success: { ...state.success, partner: true }
    };
  }
  case PartnersActionTypes.UpdatePartnerLogoError: {
    return {
      ...state,
      partner: null,
      error : { ...state.error, partner: action.payload.error },
      loading: { ...state.loading, partner: false },
      success: { ...state.success, partner: false },
    };
  }
  case PartnersActionTypes.UpdatePartnerLogo: {
    return {
      ...state,
      loading: { ...state.loading, partner: true }
    };
  }
  case PartnersActionTypes.UpdatePartnerLogoSuccess: {
    return {
      ...state,
      partner: action.payload.partner,
      loading: { ...state.loading, partner: false }
    };
  }
  case PartnersActionTypes.UpdatePartnerLogoError: {
    return {
      ...state,
      partner: null,
      error : { ...state.error, partner: action.payload.error },
      loading: { ...state.loading, partner: false }
    };
  }
  case PartnersActionTypes.UpdatePartnerGoogleDriveLink: {
    return {
      ...state,
      loading: { ...state.loading, partner: true },
      success: { ...state.success, partner: null }
    };
  }
  case PartnersActionTypes.UpdatePartnerGoogleDriveLinkSuccess: {
    return {
      ...state,
      partner: action.payload.partner,
      loading: { ...state.loading, partner: false },
      success: { ...state.success, partner: true }
    };
  }
  case PartnersActionTypes.UpdatePartnerGoogleDriveLinkError: {
    return {
      ...state,
      partner: null,
      error : { ...state.error, partner: action.payload.error },
      loading: { ...state.loading, partner: false }
    };
  }

  default:
    return state;
  }
}
