// Core
import { Component, OnInit } from "@angular/core";

// Libs
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "voxloud-cp-confirmation-popup",
  templateUrl: "./confirmation-popup.component.html",
  styleUrls: ["./confirmation-popup.component.scss"]
})
export class ConfirmationPopupComponent implements OnInit {
  labels: {
    title: string;
    ok: string;
    cancel: string;
  };
  classes: {
    confirm: string,
    cancel: string
  }
  message: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  close() {
    this.activeModal.close(false);
  }

  ok() {
    this.activeModal.close(false);
  }
}
