// Core
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Libs
import { TranslateModule } from "@ngx-translate/core";

// Modules
import { AgentsRoutingModule } from "./agents-routing.module";
import { SharedModule } from 'src/app/shared/shared.module';
// Components
import * as fromContainers from "./containers";
import * as fromComponents from "./components";
import { AddAgentPopupComponent } from './components/add-agent-popup/add-agent-popup.component';

@NgModule({
    declarations: [
        ...fromContainers.containers,
        ...fromComponents.components
    ],
    imports: [
        AgentsRoutingModule,
        SharedModule
    ]
})
export class AgentsModule { }
