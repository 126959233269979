import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { companyOtherDataReducer } from "./store/reducers";
import { CompanyOtherDataFacade } from "./store/facade/company-other-data.facade";

@NgModule({
  imports: [
    StoreModule.forFeature("company-other-data", companyOtherDataReducer)
  ],
  providers: [CompanyOtherDataFacade]
})
export class CompanyOtherDataDomainModule {}
