// Core
import { NgModule, Optional, SkipSelf } from "@angular/core";

// Libs
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from '@ngrx/effects';

// Store
import { partnersReducer } from './store';
import { PartnersEffects } from './store/effects/partners.effects';
// Services
import { PartnersService } from "./services/partners.service";

@NgModule({
  imports: [
    StoreModule.forFeature("partners", partnersReducer),
    EffectsModule.forFeature([PartnersEffects])
  ],
  providers: [PartnersService]
})
export class DomainPartnersModule {
  constructor(@Optional() @SkipSelf() parentModule: DomainPartnersModule) {
    if (parentModule) {
      throw new Error(
        "DomainPartnersModule is already loaded. Import only in AppModule"
      );
    }
  }
}
