// Core
import { NgModule, Optional, SkipSelf } from "@angular/core";

// Libs
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from '@ngrx/effects';

// Store
import { agentsReducer } from './store';
import { AgentsEffects } from './store/effects/agents.effects';
// Services
import { AgentsService } from "./services/agents.service";

@NgModule({
  imports: [
    StoreModule.forFeature("agents", agentsReducer),
    EffectsModule.forFeature([AgentsEffects])
  ],
  providers: [AgentsService]
})
export class DomainAgentsModule {
  constructor(@Optional() @SkipSelf() parentModule: DomainAgentsModule) {
    if (parentModule) {
      throw new Error(
        "DomainAgentsModule is already loaded. Import only in AppModule"
      );
    }
  }
}
