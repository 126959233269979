// Core
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';

import { CONFIG, Config } from '@app/core/config';
import { GlobalProperty, ResetPasswordRequest } from '../models/user.model';

// Environments
import { environment } from "src/environments/environment";

// Models
import { ChangePasswordRequest, User, UserActivateRequest, USER_ROLE } from "../models/user.model";


@Injectable()
export class UserService {
  private apiUsers = `${environment.apiEndpoint}/api/v1/users`;

  constructor(private http: HttpClient) { }

  getUser(email: string) {
    return this.http.get(`${this.apiUsers}/${email}`);
  }

  upgradeProfile(user: User, oldMail: string) {
    return this.http.put(
      `${this.apiUsers}/${oldMail}`,
      user
    );
  }

  changePassword(passwords: ChangePasswordRequest, email: string) {
    return this.http.post(
      `${this.apiUsers}/${email}/update-password`,
      passwords
    );
  }

  getUsers(role: USER_ROLE) {
    let query = '';
    if (role !== USER_ROLE.ALL) {
      query += `?role=${role}`;
    }
    return this.http.get(`${this.apiUsers}/${query}`);
  }

  forgottenPassword(email: string): Observable<any> {
    return this.http
      .post(`${this.apiUsers}/${email}/forgotten-password`, {})
  }

  resetPassword(data: ResetPasswordRequest): Observable<any> {
    return this.http
      .post(`${this.apiUsers}/reset-password`, data);
  }

  activateUser(data: UserActivateRequest) {
    return this.http.post(`${this.apiUsers}/activate`, data);
  }

  updateUserAvatar(data: Blob | null, email: string) {
    if (data === null) {
      return this.http.put(`${environment.apiEndpoint}/api/v1/users/${email}/avatar`, null);
    } else {
      const formData = new FormData();
      const extension = data.type.split('/')[1];
      const fileName = `avatar-${(10e10 * Math.random()).toFixed(9)}`;
      formData.append('file', data, `${fileName}.${extension}`);
      return this.http.put(`${environment.apiEndpoint}/api/v1/users/${email}/avatar`, formData);
    }
  }

  checkEmailExists(email: string) {
    return this.http.get(`${environment.apiEndpoint}/api/v1/users/${email}/exists`);
  }

  updateGlobalDriveLink(data: GlobalProperty) {
    return this.http.post(`${environment.apiEndpoint}/api/v1/global-properties`, data);
  }
}
