// Core
import { Injectable } from "@angular/core";

// Libs
import { select, Store } from "@ngrx/store";
import { take } from 'rxjs/operators'

//Models
import { GlobalProperty, User, USER_ROLE, UserActivateRequest } from "../../models/user.model";

// Store
import { State } from "../reducers";
import {
  getLanguage,
  getEmail,
  getFirstName,
  selectUser,
  selectUserError,
  selectUserLoading,
  selectUserSuccess,
  selectError,
  selectLoading,
  selectUsers,
  selectUsersError,
  selectUsersLoading,
  selectUsersSuccess,
  showPrivateLink,
  getGlobalDriveLink,
  getPrivateDriveLink
} from "../selectors";
import { LanguageSelect, GetUser, GetUsers, UpdateUserAvatar, ActivateUser, UpdateGlogalDriveLink } from "../actions";

@Injectable({
  providedIn: "root"
})
export class UserFacade {
  email$ = this.store.pipe(select(getEmail));
  firstName$ = this.store.pipe(select(getFirstName));
  language$ = this.store.pipe(select(getLanguage));

  user$ = this.store.pipe(select(selectUser));
  loadingUser$ = this.store.pipe(select(selectUserLoading));
  errorUser$ = this.store.pipe(select(selectUserError));
  successUser$ = this.store.pipe(select(selectUserSuccess));

  error$ = this.store.pipe(select(selectError));
  loading$ = this.store.pipe(select(selectLoading));

  users$ = this.store.pipe(select(selectUsers));
  loadingUsers$ = this.store.pipe(select(selectUsersLoading));
  errorUsers$ = this.store.pipe(select(selectUsersError));
  successUsers$ = this.store.pipe(select(selectUsersSuccess));
  showPrivateLink$ = this.store.pipe(select(showPrivateLink));
  getGlobalDriveLink$ = this.store.pipe(select(getGlobalDriveLink));
  getPrivateDriveLink$ = this.store.pipe(select(getPrivateDriveLink));

  constructor(private store: Store<State>) {}

  selectLanguage(language: string): void {
    this.store.dispatch(new LanguageSelect(language));
  }

  setUser(user: User) {
    this.store.dispatch(new GetUser(user));
  }

  getUsers(role: USER_ROLE) {
    this.store.dispatch(new GetUsers(role));
  }

  updateAvatar(data: Blob) {
    this.user$.pipe(take(1)).subscribe(u => {
      this.store.dispatch(new UpdateUserAvatar({ data, email: u.email }));
    })
  }

  activateUser(user: UserActivateRequest) {
    this.user$.pipe(take(1)).subscribe(u => {
      this.store.dispatch(new ActivateUser(user));
    })
  }

  updateGlobalDriveLink(data: GlobalProperty) {
    this.store.dispatch(new UpdateGlogalDriveLink(data));
  }
}
