// Core
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// Rxjs
import { take, switchMap } from 'rxjs/operators';

// Libs
import { Store } from '@ngrx/store';

// App
import { AuthToken } from '../models';
import { AuthState, selectToken } from '../store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AuthState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectToken).pipe(
      take(1),
      switchMap((token: AuthToken) => {
        if (token) {
          let clonedRequest;
          if(request.url.includes('api.voverc.com')){
            clonedRequest = request.clone({
              headers: request.headers.set(
                'Voxloud-Reselling-Jwt',
                `Bearer ${token.token}`
              )
            })
          }else {
            clonedRequest = request.clone({
              headers: request.headers.set(
                'Authorization',
                `Bearer ${token.token}`
              )
            });
        } 
          return next.handle(clonedRequest);
        }
        return next.handle(request);
      })
    );
  }
}
